import { css } from "@emotion/react";
import { useCallback, useState } from "react";
import FieldProps from "./FieldProps";
import FieldWrapper from "./components/FieldWrapper";
import { useFieldId, useFieldValidity } from "./utilities";
import Button from "@hpo/client/components/Button";

type SwitchFieldProps = FieldProps<boolean> & {
  trueLabel?: string;
  falseLabel?: string;
};

export default function SwitchField(props: SwitchFieldProps) {
  const {
    value,
    required = false,
    readonly = false,
    disabled = false,
    errorMessage,
    trueLabel = "Oui",
    falseLabel = "Non",
  } = props;

  const id = useFieldId(props.id);
  const validity = useFieldValidity(value, required, () => false, errorMessage);

  const containerCss = css({
    display: "flex",
    flexDirection: "column",
  });

  const [finalized, setFinalized] = useState(false);

  const onChange = useCallback(
    (v: boolean) => {
      if (!props.onChange) return;
      if (disabled) return;
      if (readonly) return;
      setFinalized(true);
      props.onChange(v);
    },
    [props.onChange, readonly, disabled],
  );

  return (
    <FieldWrapper {...props} id={id} validity={validity} finalized={finalized}>
      <div
        css={containerCss}
        onClick={() => {
          if (onChange) onChange(!value);
        }}
      >
        <Button
          label={value ? trueLabel : falseLabel}
          icon={value ? "checkbox-on" : "checkbox-off"}
          style="discreet"
          disabled={disabled || readonly}
        ></Button>
      </div>
    </FieldWrapper>
  );
}
