export type FileCategory =
  | "image"
  | "pdf"
  | "word"
  | "excel"
  | "archive"
  | "other";

export default function getFileCategory(file: File): FileCategory {
  return getMimeCategory(file.type);
}

export function getMimeCategory(mime: string): FileCategory {
  if (mime.startsWith("image/")) return "image";
  else if (mime.startsWith("application/pdf")) return "pdf";
  else if (mime === "application/zip") return "archive";
  else if (
    mime.includes("application/msword") ||
    mime.includes(
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    )
  )
    return "word";
  else if (
    mime.includes("application/vnd.ms-excel") ||
    mime.includes(
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    )
  )
    return "excel";
  else return "other";
}

export const labelPerFileCategory: Record<FileCategory, string> = {
  image: "Photo ou scan",
  pdf: "Document PDF",
  word: "Document Word",
  excel: "Tableur Excel",
  archive: "Ensemble de documents",
  other: "Document",
};
