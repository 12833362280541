import { Fragment, useCallback, useEffect, useMemo } from "react";
import { css } from "@emotion/react";
import { rgba } from "polished";
import { useLogger } from "../logger";
import T from "../components/text/Text";
import Icon from "../components/Icon";
import messageExceptionCaster from "../message-exception-caster.ts";
import Buttons from "../components/Buttons.tsx";
import Button from "../components/Button.tsx";
import Theme from "./Theme/client";

type ErrorViewProps = {
  error: unknown;
  onResolved?: () => void;
  noLog?: boolean;
};

export default function ErrorView(props: ErrorViewProps) {
  const { error, noLog, onResolved } = props;

  const logger = useLogger();

  const { message, resolution } = useMemo(
    () => messageExceptionCaster.cast(error),
    [error],
  );

  useEffect(() => {
    if (!error || noLog) return;
    logger.error(error);
  }, [error, noLog]);

  const containerCss = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: 20,
    padding: 20,
    background: rgba(Theme.error, 0.1),
    borderRadius: 5,
    border: `1px solid ${Theme.error}`,
  });

  const onClickResolve = useCallback(async () => {
    if (!resolution) return;
    await resolution.fn();
    if (onResolved) onResolved();
  }, [resolution, onResolved]);

  if (!message) return null;

  return (
    <Fragment>
      <div css={containerCss}>
        <Icon name="error" size={24} color={Theme.error} />
        <T align="center" color={Theme.error}>
          {message}
        </T>
      </div>
      {resolution ? (
        <Buttons>
          <Button label={resolution.invite} onClick={onClickResolve} />
        </Buttons>
      ) : null}
    </Fragment>
  );
}
