import { Fragment, useCallback, useState } from "react";
import { useChangeEffect } from "@ehynds/use-change-effect";
import { ProjectDraft } from "@hpo/client/models/Project";
import Sections from "@hpo/client/utilities/Sections";
import { getAttachementDescriptor } from "@hpo/client/utilities/project/Attachments";
import FilesField from "@hpo/client/utilities/fields/FilesField";
import T from "@hpo/client/components/text/Text";
import Spacer from "@hpo/client/utilities/Spacer";

type ProjectAttachmentsProps = {
  project: ProjectDraft;
  onChange?: (d: Partial<ProjectDraft>) => unknown;
};

export default function ProjectAttachments(props: ProjectAttachmentsProps) {
  const { project, onChange } = props;

  const locked = onChange === undefined;

  const [attachments, setAttachments] = useState(project.attachments);

  const setAttachementFiles = useCallback(
    (id: string, files: Array<string> | null) => {
      setAttachments((current) => {
        return current.map((c) =>
          c.id === id ? { ...c, files: files === null ? [] : files } : c,
        );
      });
    },
    [],
  );

  useChangeEffect(() => {
    if (!onChange) return;
    onChange({ attachments });
  }, [attachments, onChange]);

  return (
    <Fragment>
      <T style="section">Pièces jointes</T>
      <Spacer />
      <Sections>
        {project.attachments.map((a) => {
          const descriptor = getAttachementDescriptor(a.key);
          return (
            <Sections.Section id={a.key} title={descriptor.label} key={a.key}>
              <FilesField
                key={a.key}
                value={a.files}
                label={"Documents"}
                viewerHeight={300}
                onChange={(f) => setAttachementFiles(a.id, f)}
                readonly={locked}
              />
            </Sections.Section>
          );
        })}
      </Sections>
    </Fragment>
  );
}
