import { Fragment, useCallback, useMemo, useState } from "react";
import { useServerSdk } from "../../../RootNavigator/services/ServerSdk";
import ProjectInquiryStatus from "../inquiry/ProjectInquiryStatus";
import { Analysis } from "@hpo/client/models/Analysis";
import { IndicatorPayload } from "@hpo/client/models/Indicator";
import { ProjectInstructor } from "@hpo/client/models/Project";
import { IndicatorTypeLabels } from "@hpo/client/utilities/enums/IndicatorType";
import { useAssistance } from "@hpo/client/navigators/RootNavigator/services/Assistance";
import IndicatorOrigin, {
  IndicatorOriginLabels,
} from "@hpo/client/utilities/enums/IndicatorOrigin";
import Table from "@hpo/client/navigators/ConventionNavigator/PaymentScreen/Table";
import T from "@hpo/client/components/text/Text";
import Spacer from "@hpo/client/utilities/Spacer";
import Button from "@hpo/client/components/Button";
import SideBar from "@hpo/client/components/SideBar";
import IndicatorEditor from "@hpo/client/navigators/MyProjectsNavigator/components/IndicatorEditor";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import PromiseToast from "@hpo/client/utilities/PromiseToast";
import { canProjectHaveInquiry } from "@hpo/client/utilities/helpers/ProjectHelper";
import { getMarker } from "@hpo/client/models/Marker";
import { Tag } from "@hpo/client/models/Tag";
import IndicatorTagsEditor from "@hpo/client/navigators/MyProjectsNavigator/components/IndicatorTagsEditor";
import Buttons from "@hpo/client/components/Buttons";
import Units from "@hpo/client/utilities/Units";

type ProjectTabDescriptionProps = {
  project: ProjectInstructor;
  tags: Array<Tag>;
  onReload: () => unknown;
};

export default function ProjectTabIndicators(
  props: ProjectTabDescriptionProps,
) {
  const { project, onReload } = props;

  const serverSdk = useServerSdk();
  const assistance = useAssistance();
  assistance.useVisibilityDecision(true);

  const saveAnalysis = useCallback(
    async (resourceId: string, fields: Analysis) => {
      await serverSdk.saveAnalysis(resourceId, fields);
      await onReload();
    },
    [onReload],
  );

  const byBeneficiary = useMemo(
    () =>
      project.indicators.filter(
        (i) => i.origin === IndicatorOrigin.Beneficiary,
      ),
    [project],
  );

  const byInstructor = useMemo(
    () =>
      project.indicators.filter((i) => i.origin === IndicatorOrigin.Instructor),
    [project],
  );

  const [sideBar, setSideBar] = useState<{
    mode: "edition" | "tags";
    indicator: string | true;
  } | null>(null);

  const [onSaveIndicator, submission] = useSubmitCallback(
    async (id: string | null, payload: IndicatorPayload) => {
      if (id === null)
        await serverSdk.createIndicator(
          project.id,
          payload,
          project.indicators.length,
        );
      else await serverSdk.updateIndicator(project.id, id, payload);
      onReload();
      setSideBar(null);
    },
    [project.indicators.length, onReload],
  );

  const [onSaveIndicatorTags, submissionTags] = useSubmitCallback(
    async (id: string, tags: Array<Tag>) => {
      await serverSdk.updateIndicatorTags(
        project.id,
        id,
        tags.map((tag) => tag.id),
      );
      onReload();
      setSideBar(null);
    },
    [onReload],
  );

  const [onDeleteIndicator, deletion] = useSubmitCallback(
    async (id: string) => {
      await serverSdk.deleteIndicator(project.id, id);
      onReload();
      setSideBar(null);
    },
    [project.indicators.length, onReload],
  );

  return (
    <Fragment>
      <PromiseToast
        promise={submission.promise}
        message={"Indicateur pris en compte"}
      />
      <PromiseToast
        promise={submissionTags.promise}
        message={"Etiquettes de l'indicateur mises à jour"}
      />
      <PromiseToast
        promise={deletion.promise}
        message={"Indicateur supprimé"}
      />
      <T style="section">
        {IndicatorOriginLabels[IndicatorOrigin.Beneficiary]}
      </T>
      <Spacer />
      <Table>
        <thead>
          <tr>
            <th>Libellé et type</th>
            {project.periods.map((p) => (
              <th key={p}>
                <T style="minor">Objectif</T>
                <br />
                {p}
              </th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {byBeneficiary.map((i) => (
            <tr key={i.id}>
              <td>
                <T>{i.label}</T>
                <br />
                <T style="minor">{IndicatorTypeLabels[i.type]}</T>
              </td>
              {project.periods.map((p) => {
                const goal = getMarker(i.goals, p);
                if (!goal) return <td key={p}>-</td>;
                else
                  return <td key={p}>{Units[i.unit].display(goal.value)}</td>;
              })}
              <td>
                <Buttons>
                  <ProjectInquiryStatus
                    analysis={i.analysis}
                    onChange={(a) => saveAnalysis(i.id, a)}
                    readonly={!canProjectHaveInquiry(project)}
                  />
                  <Button
                    icon="tags"
                    onClick={() =>
                      setSideBar({ mode: "tags", indicator: i.id })
                    }
                    style="discreet"
                  />
                </Buttons>
                <SideBar
                  visible={
                    !!(
                      sideBar &&
                      sideBar.mode === "tags" &&
                      sideBar.indicator === i.id
                    )
                  }
                  onHide={() => setSideBar(null)}
                >
                  <IndicatorTagsEditor
                    allTags={props.tags}
                    indicatorTags={i.tags}
                    onTagsDone={(tags) => onSaveIndicatorTags(i.id, tags)}
                  />
                </SideBar>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Spacer />
      <T style="section">{IndicatorOriginLabels[IndicatorOrigin.Instructor]}</T>
      <Spacer />
      <Table>
        <thead>
          <tr>
            <th>Libellé et type</th>
            {project.periods.map((p) => (
              <th key={p}>
                <T style="minor">Objectif</T>
                <br />
                {p}
              </th>
            ))}
            <th>
              <Button
                icon="plus"
                onClick={() => setSideBar({ mode: "edition", indicator: true })}
                style="discreet"
              />
              <SideBar
                visible={
                  !!(
                    sideBar &&
                    sideBar.mode === "edition" &&
                    sideBar.indicator === true
                  )
                }
                onHide={() => setSideBar(null)}
              >
                <IndicatorEditor
                  indicator={null}
                  periods={project.periods}
                  onDone={(p) => onSaveIndicator(null, p)}
                  hideGoals
                />
              </SideBar>
            </th>
          </tr>
        </thead>
        <tbody>
          {byInstructor.map((i) => (
            <tr key={i.id}>
              <td>
                <T>{i.label}</T>
                <br />
                <T style="minor">{IndicatorTypeLabels[i.type]}</T>
              </td>
              {project.periods.map((p) => {
                const goal = getMarker(i.goals, p);
                if (!goal) return <td key={p}>-</td>;
                else return <td key={p}>{goal.value}</td>;
              })}
              <td>
                <Buttons>
                  <Button
                    icon="pencil"
                    onClick={() =>
                      setSideBar({ mode: "edition", indicator: i.id })
                    }
                    style="discreet"
                  />
                  <Button
                    icon="tags"
                    onClick={() =>
                      setSideBar({ mode: "tags", indicator: i.id })
                    }
                    style="discreet"
                  />
                </Buttons>
                <SideBar
                  visible={
                    !!(
                      sideBar &&
                      sideBar.mode === "edition" &&
                      sideBar.indicator === i.id
                    )
                  }
                  onHide={() => setSideBar(null)}
                >
                  <IndicatorEditor
                    indicator={i}
                    periods={project.periods}
                    onDone={(p) => onSaveIndicator(i.id, p)}
                    onDelete={() => onDeleteIndicator(i.id)}
                    hideGoals
                  />
                </SideBar>
                <SideBar
                  visible={
                    !!(
                      sideBar &&
                      sideBar.mode === "tags" &&
                      sideBar.indicator === i.id
                    )
                  }
                  onHide={() => setSideBar(null)}
                >
                  <IndicatorTagsEditor
                    allTags={props.tags}
                    indicatorTags={i.tags}
                    onTagsDone={(tags) => onSaveIndicatorTags(i.id, tags)}
                  />
                </SideBar>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Fragment>
  );
}
