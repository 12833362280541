import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { round } from "lodash";
import { evaluate } from "mathjs";
import Units, { UnitName } from "../Units";
import FieldProps from "./FieldProps";
import { useFieldId, useFieldValidity } from "./utilities";
import FieldWrapper from "./components/FieldWrapper";
import FieldInput from "./components/FieldInput";

type NumberFieldProps = FieldProps<number | null> & {
  min?: number;
  max?: number;
  unit?: UnitName;
  allowZero?: true;
};

export default function NumberField(props: NumberFieldProps) {
  const {
    onChange,
    required = false,
    errorMessage,
    unit = "other",
    min,
    max,
    disabled,
    readonly,
    allowZero,
    placeholder,
  } = props;

  const normalize = useCallback(
    (n: number | null) => {
      let output: number | null = n;
      if (output === 0 && !allowZero) output = null;
      if (output !== null) {
        if (typeof min === "number" && output < min) {
          output = min;
        } else if (typeof max === "number" && output > max) {
          output = max;
        }
      }
      return output;
    },
    [min, max, allowZero],
  );

  const value = useMemo(() => normalize(props.value), [props.value, normalize]);

  useEffect(() => {
    if (!onChange) return;
    if (value !== props.value) {
      onChange(value);
    }
  }, [onChange, value, props.value]);

  const id = useFieldId(props.id);

  const isActive = !disabled && !readonly;

  const validity = useFieldValidity(
    value,
    required,
    (v) => v === null || v === undefined,
    errorMessage,
  );

  const formatted = useMemo(() => {
    if (value === null) return "";
    else return Units[unit].display(value);
  }, [value, unit]);

  const number = useMemo(() => {
    if (value === null) return "";
    else return (value * Units[unit].factor).toString();
  }, [value, unit]);

  const [typed, setTyped] = useState<string | null>(null);

  const [focus, setFocus] = useState<boolean>(false);

  const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setTyped(event.target.value.replaceAll(/[^0-9. +/()*%-]/g, ""));
  }, []);

  const onInputBlur = useCallback(() => {
    setFocus(false);
    setTyped(null);
    setFinalized(true);
    if (!onChange) return;
    if (typed !== null) {
      let nb: number;
      try {
        nb = evaluate(typed);
      } catch (_) {
        nb = NaN;
        // Evaluation failed
      }
      if (isNaN(nb)) {
        onChange(null);
      } else {
        const realNb = round(
          nb * (1 / Units[unit].factor),
          Units[unit].decimals,
        );
        onChange(normalize(realNb));
      }
    }
  }, [typed, onChange, normalize, unit]);

  const [finalized, setFinalized] = useState(false);

  return (
    <FieldWrapper {...props} validity={validity} finalized={finalized}>
      <FieldInput
        id={id}
        placeholder={placeholder}
        value={
          onChange && focus && isActive
            ? typed === null
              ? number
              : typed
            : formatted
        }
        onFocus={() => setFocus(true)}
        disabled={disabled}
        onBlur={onInputBlur}
        onChange={onInputChange}
        readOnly={readonly}
      />
    </FieldWrapper>
  );
}
