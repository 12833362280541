import { useMemo } from "react";
import Destination, { MergeParams } from "./Destination";
import useLocation from "./useLocation";

export default function useRouteParam<
  Params extends Record<string, string> | void = void,
  ParentsParams extends Record<string, string> | void = void,
>(
  destination: Destination<Params, ParentsParams>,
  name: keyof MergeParams<Params, ParentsParams> & string,
): string {
  const location = useLocation();
  const params = useMemo(
    () => destination.extractParams(location),
    [destination, location],
  );
  return params[name] as string;
}
