import { PropsWithChildren, useRef } from "react";
import WithHelp from "./WithHelp";
import Icon, { IconName } from "@hpo/client/components/Icon";
import Theme from "@hpo/client/utilities/Theme/client";

type HelpIconProps = PropsWithChildren<{
  title?: string;
  icon?: IconName;
  color?: string;
}>;

export function HelpIcon(props: HelpIconProps) {
  const { color = Theme.color, icon = "help" } = props;
  const iconRef = useRef<HTMLElement>(null);
  return (
    <div css={{ display: "inline-block" }}>
      <WithHelp
        text={props.children}
        title={props.title}
        color={color}
        mouseoverRef={iconRef}
      />
      <Icon name={icon} color={color} ref={iconRef} />
    </div>
  );
}
