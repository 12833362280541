import { useServerSdk } from "../RootNavigator/services/ServerSdk";
import Button from "@hpo/client/components/Button";
import ContentLayout from "@hpo/client/components/layout/ContentLayout";
import List, { ListItem } from "@hpo/client/utilities/List";
import ProjectStatusBadge from "@hpo/client/utilities/ProjectStatusBadge";
import ProjectStatus from "@hpo/client/utilities/enums/ProjectStatus";
import { getProjectLabel } from "@hpo/client/utilities/helpers/ProjectHelper";
import {
  myDraftRoute,
  myProjectRoute,
  newDraftRoute,
} from "@hpo/client/utilities/routes";

export default function MyProjectsScreen() {
  const server = useServerSdk();
  const projects = server.suspender.await(server.getMyProjects());

  return (
    <ContentLayout
      title="Mes projets"
      actions={
        <Button
          to={newDraftRoute.getRootUrl()}
          label="Nouveau projet"
          icon="plus"
        ></Button>
      }
    >
      <List
        data={projects}
        renderItem={(project) => (
          <ListItem
            label={getProjectLabel(project)}
            help={project.organization.businessName}
            right={<ProjectStatusBadge status={project.status} />}
            to={
              project.status === ProjectStatus.Draft
                ? myDraftRoute.getRootUrl({ draftId: project.id })
                : myProjectRoute.getRootUrl({ projectId: project.id })
            }
          />
        )}
      />
    </ContentLayout>
  );
}
