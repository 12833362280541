import { ComponentType, Fragment, PropsWithChildren, useContext } from "react";
import { SwitchContext } from "./Switch";
import { useLocation } from ".";

type DefaultRouteProps = PropsWithChildren<{
  component: ComponentType;
}>;

export default function DefaultRoute(props: DefaultRouteProps) {
  const { component: Component } = props;
  useLocation();
  const switchContext = useContext(SwitchContext);
  if (switchContext === null) return <Fragment />;
  if (switchContext.rendered === true) return <Fragment />;
  switchContext.rendered = true;
  return <Component />;
}
