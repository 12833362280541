import { Fragment, useCallback, useMemo, useState } from "react";
import { nanoid } from "nanoid";
import dayjs from "dayjs";
import { useChangeEffect } from "@ehynds/use-change-effect";
import ProductSideBar from "../components/ProductSideBar";

import SalarySideBar from "../components/SalarySideBar";
import ServiceSideBar from "../components/ServiceSideBar";
import Spacer from "@hpo/client/utilities/Spacer";
import T from "@hpo/client/components/text/Text";
import { ProjectDraft } from "@hpo/client/models/Project";
import Intersperse from "@hpo/client/components/Intersperse";

import Units from "@hpo/client/utilities/Units";
import { distpatchExpenses } from "@hpo/client/utilities/helpers/ExpenseHelper";
import DataTable, { DataTableColumn } from "@hpo/client/components/DataTable";
import {
  ProductBeneficiary,
  ProductPayload,
  SalaryBeneficiary,
  SalaryPayload,
  ServiceBeneficiary,
  ServicePayload,
  computeProduct,
  computeSalary,
  computeService,
} from "@hpo/client/models/Expense";
import AnnexType from "@hpo/client/utilities/enums/AnnexType";

type ProjectExpensesProps = {
  project: ProjectDraft;
  onChange?: (d: Partial<ProjectDraft>) => unknown;
};

export default function ProjectExpenses(props: ProjectExpensesProps) {
  const { project, onChange } = props;

  const locked = onChange === undefined;
  const program = project.type;

  // Products

  const showProducts = useMemo(
    () => !!project.annexes.find((a) => a.type === AnnexType.A2Product),
    [project],
  );

  const [products, setProducts] = useState<Array<ProductBeneficiary>>(
    () => distpatchExpenses(project.expenses).product,
  );

  const insertProduct = useCallback(
    async (product: ProductPayload) => {
      if (locked) return;
      setProducts((products) => [
        ...products,
        {
          id: nanoid(),
          ...product,
          ...computeProduct(product, program),
          proposal: null,
        },
      ]);
    },
    [locked, program],
  );

  const updateProduct = useCallback(
    async (id: string, product: ProductPayload) => {
      if (locked) return;
      setProducts((products) =>
        products.map((i) =>
          i.id === id
            ? { ...i, ...product, ...computeProduct(product, program) }
            : i,
        ),
      );
    },
    [locked, program],
  );

  const deleteProduct = useCallback(
    async (product: ProductBeneficiary) => {
      if (locked) return;
      setProducts((products) => products.filter((p) => p.id !== product.id));
    },
    [locked],
  );

  // Services

  const showServices = useMemo(
    () => !!project.annexes.find((a) => a.type === AnnexType.A2Service),
    [project],
  );

  const [services, setServices] = useState<Array<ServiceBeneficiary>>(
    () => distpatchExpenses(project.expenses).service,
  );

  const insertService = useCallback(
    async (service: ServicePayload) => {
      if (locked) return;
      setServices((services) => [
        ...services,
        {
          id: nanoid(),
          ...service,
          ...computeService(service, project.type),
          proposal: null,
        },
      ]);
    },
    [locked],
  );

  const updateService = useCallback(
    async (id: string, service: ServicePayload) => {
      if (locked) return;
      setServices((services) =>
        services.map((i) =>
          i.id === id
            ? { ...i, ...service, ...computeService(service, project.type) }
            : i,
        ),
      );
    },
    [locked],
  );

  const deleteService = useCallback(
    async (service: ServiceBeneficiary) => {
      if (locked) return;
      setServices((services) => services.filter((p) => p.id !== service.id));
    },
    [locked],
  );

  // Salaries

  const showSalaries = useMemo(
    () => !!project.annexes.find((a) => a.type === AnnexType.A2Salary),
    [project],
  );

  const [salaries, setSalaries] = useState<Array<SalaryBeneficiary>>(
    () => distpatchExpenses(project.expenses).salary,
  );

  const insertSalary = useCallback(
    async (salary: SalaryPayload) => {
      if (locked) return;
      setSalaries((salaries) => [
        ...salaries,
        {
          id: nanoid(),
          ...salary,
          ...computeSalary(salary, project.type),
          proposal: null,
        },
      ]);
    },
    [locked],
  );

  const updateSalary = useCallback(
    async (id: string, salary: SalaryPayload) => {
      if (locked) return;
      setSalaries((salaries) =>
        salaries.map((i) =>
          i.id === id
            ? { ...i, ...salary, ...computeSalary(salary, project.type) }
            : i,
        ),
      );
    },
    [locked],
  );

  const deleteSalary = useCallback(
    async (salary: SalaryBeneficiary) => {
      if (locked) return;
      setSalaries((salaries) => salaries.filter((p) => p.id !== salary.id));
    },
    [locked],
  );

  const [open, setOpen] = useState<
    string | "new-product" | "new-service" | "new-salary" | null
  >(null);

  useChangeEffect(() => {
    if (!onChange) return;
    onChange({
      expenses: [...products, ...services, ...salaries],
    });
  }, [products, services, salaries]);

  return (
    <Intersperse between={() => <Spacer />}>
      {showProducts ? (
        <Fragment>
          <T style="section">Dépenses de produits</T>
          <Spacer />
          <DataTable
            columns={productsColumns}
            value={products}
            readonly={locked}
            actions={{
              onAddClick: () => setOpen("new-product"),
              onEditClick: (product: ProductBeneficiary) => setOpen(product.id),
              onDeleteClick: deleteProduct,
            }}
          />
          {products.map((product) => (
            <ProductSideBar
              key={product.id}
              project={project}
              product={product}
              visible={open === product.id}
              onDone={(p) => updateProduct(product.id, p)}
              onHide={() => setOpen(null)}
            />
          ))}
          <ProductSideBar
            project={project}
            product={null}
            visible={open === "new-product"}
            onDone={insertProduct}
            onHide={() => setOpen(null)}
          />
        </Fragment>
      ) : null}
      {showServices ? (
        <Fragment>
          <T style="section">Dépenses de prestations de services</T>
          <Spacer />
          <DataTable
            columns={servicesColumns}
            value={services}
            readonly={locked}
            actions={{
              onAddClick: () => setOpen("new-service"),
              onEditClick: (service: ServiceBeneficiary) => setOpen(service.id),
              onDeleteClick: deleteService,
            }}
          />
          {services.map((service) => (
            <ServiceSideBar
              key={service.id}
              service={service}
              visible={open === service.id}
              onDone={(p) => updateService(service.id, p)}
              onHide={() => setOpen(null)}
            />
          ))}
          <ServiceSideBar
            service={null}
            visible={open === "new-service"}
            onDone={insertService}
            onHide={() => setOpen(null)}
          />
        </Fragment>
      ) : null}
      {showSalaries ? (
        <Fragment>
          <T style="section">Dépenses de salaires</T>
          <Spacer />
          <DataTable
            columns={salariesColumns}
            value={salaries}
            readonly={locked}
            actions={{
              onAddClick: () => setOpen("new-salary"),
              onEditClick: (salary: SalaryBeneficiary) => setOpen(salary.id),
              onDeleteClick: deleteSalary,
            }}
          />
          {salaries.map((salary) => (
            <SalarySideBar
              key={salary.id}
              project={project}
              salary={salary}
              visible={open === salary.id}
              onDone={(p) => updateSalary(salary.id, p)}
              onHide={() => setOpen(null)}
            />
          ))}
          <SalarySideBar
            project={project}
            salary={null}
            visible={open === "new-salary"}
            onDone={insertSalary}
            onHide={() => setOpen(null)}
          />
        </Fragment>
      ) : null}

      <Fragment>
        <T style="section">Plan de financement</T>
        <Spacer />
        <DataTable
          columns={summaryColumns}
          value={[...products, ...services, ...salaries]}
          readonly={true}
        />
      </Fragment>
    </Intersperse>
  );
}

const productsColumns: Array<DataTableColumn<ProductBeneficiary>> = [
  {
    header: "Informations",
    body: (product: ProductBeneficiary) => {
      return (
        <Fragment>
          <T>{product.label}</T>
          <br />
          <T style="minor">
            Devis n°{product.quotationReference} du{" "}
            {dayjs(product.quotationDate).format("LL")} de {product.provider}
          </T>
          <br />
          <T style="minor">{product.quotationDetail}</T>
        </Fragment>
      );
    },
  },
  { field: "quantity", header: "Quantité" },
  {
    header: "Coût",
    body: (product: ProductBeneficiary) => {
      return (
        <Fragment>
          <T oneLine>
            {Units.euro.display(product.annualCost)}{" "}
            <T style="minor"> par an</T>{" "}
          </T>
          <T oneLine>
            {Units.euro.display(product.quotationTotalCost)}
            <T style="minor"> sur 3 ans</T>
          </T>
        </Fragment>
      );
    },
  },
  {
    header: "Elligibilité",
    body: (product: ProductBeneficiary) => {
      return (
        <Fragment>
          <T oneLine>
            {Units.euro.display(product.helpAmountPerYear)}{" "}
            <T style="minor"> par an</T>{" "}
          </T>
          <T oneLine>
            {Units.euro.display(product.helpAmountThreeYears)}
            <T style="minor"> sur 3 ans</T>
          </T>
        </Fragment>
      );
    },
  },
];

const servicesColumns: Array<DataTableColumn<ServiceBeneficiary>> = [
  {
    header: "Presataion de service",
    body: (product: ServiceBeneficiary) => {
      return (
        <Fragment>
          <T>{product.label}</T>
          <br />
          <T style="minor">
            Devis n°{product.quotationReference} du{" "}
            {dayjs(product.quotationDate).format("LL")} de {product.provider}
          </T>
          <br />
          <T style="minor">{product.quotationDetail}</T>
        </Fragment>
      );
    },
  },
  {
    header: "Coût",
    body: (product: ServiceBeneficiary) => {
      return (
        <Fragment>
          <T oneLine>
            {Units.euro.display(product.annualCost)}{" "}
            <T style="minor"> par an</T>{" "}
          </T>
          <T oneLine>
            {Units.euro.display(product.quotationTotalCost)}
            <T style="minor"> sur 3 ans</T>
          </T>
        </Fragment>
      );
    },
  },
  {
    header: "Elligibilité",
    body: (product: ServiceBeneficiary) => {
      return (
        <Fragment>
          <T oneLine>
            {Units.euro.display(product.helpAmountPerYear)}{" "}
            <T style="minor"> par an</T>{" "}
          </T>
          <T oneLine>
            {Units.euro.display(product.helpAmountThreeYears)}
            <T style="minor"> sur 3 ans</T>
          </T>
        </Fragment>
      );
    },
  },
];

const salariesColumns: Array<DataTableColumn<SalaryBeneficiary>> = [
  {
    header: "Poste",
    body: (product: SalaryBeneficiary) => {
      return (
        <Fragment>
          <T>{product.label}</T>
          <br />
          <T style="minor">
            Poste de {product.employeeName} en tant que{" "}
            {product.employeePosition.toLowerCase()} à{" "}
            {Units.percentage.display(product.affectation)}
          </T>
        </Fragment>
      );
    },
  },
  {
    header: "Base",
    body: (product: SalaryBeneficiary) => {
      return (
        <Fragment>
          <T oneLine>
            {Units.euro.display(product.annualCost)}{" "}
            <T style="minor"> par an</T>{" "}
          </T>
          {product.annualCostCeiling < product.annualCost ? (
            <T oneLine>
              plafonné à {Units.euro.display(product.annualCostCeiling)}
            </T>
          ) : null}
        </Fragment>
      );
    },
  },
  {
    header: "Coût",
    body: (product: SalaryBeneficiary) => {
      return (
        <Fragment>
          <T oneLine>
            {Units.euro.display(product.fullCostOneYear)}{" "}
            <T style="minor"> par an</T>{" "}
          </T>
          <T oneLine>
            {Units.euro.display(product.fullCostThreeYears)}
            <T style="minor"> sur 3 ans</T>
          </T>
        </Fragment>
      );
    },
  },
  {
    header: "Elligibilité",
    body: (product: SalaryBeneficiary) => {
      return (
        <Fragment>
          <T oneLine>
            {Units.euro.display(product.helpAmountPerYear)}{" "}
            <T style="minor"> par an</T>{" "}
          </T>
          <T oneLine>
            {Units.euro.display(product.helpAmountThreeYears)}
            <T style="minor"> sur 3 ans</T>
          </T>
        </Fragment>
      );
    },
  },
];

const summaryColumns: Array<
  DataTableColumn<ProductBeneficiary | ServiceBeneficiary | SalaryBeneficiary>
> = [
  {
    header: "Dépense",
    body: (
      product: ProductBeneficiary | ServiceBeneficiary | SalaryBeneficiary,
    ) => {
      return (
        <Fragment>
          <T>{product.label}</T>
        </Fragment>
      );
    },
  },
  {
    header: "Coût prévisionnel",
    footer: (l) =>
      Units.euro.display(
        l.reduce((acc, product) => acc + product.annualCost, 0),
      ),
    body: (
      product: ProductBeneficiary | ServiceBeneficiary | SalaryBeneficiary,
    ) => {
      return (
        <T oneLine>
          {Units.euro.display(product.annualCost)}
          <T style="minor"> /an</T>
        </T>
      );
    },
  },
  {
    header: "Subvention départementale HPO demandée",
    body: (
      product: ProductBeneficiary | ServiceBeneficiary | SalaryBeneficiary,
    ) => {
      return (
        <T oneLine>
          {Units.euro.display(product.helpAmountPerYear)}
          <T style="minor"> /an</T>
        </T>
      );
    },
  },
  {
    header: "Reste à charge au porteur de projet",
    body: (
      product: ProductBeneficiary | ServiceBeneficiary | SalaryBeneficiary,
    ) => {
      return (
        <T oneLine>
          {Units.euro.display(product.annualCost - product.helpAmountPerYear)}
          <T style="minor"> /an</T>
        </T>
      );
    },
  },
];
