import { useCallback, useEffect, useState } from "react";
import { IntervalBasedObserver } from "./IntervalBasedObserver";

const compareSearchParams = (a: URLSearchParams, b: URLSearchParams) =>
  a.toString() === b.toString();

const observer = new IntervalBasedObserver(
  () => new URLSearchParams(window.location.search),
  compareSearchParams,
);

export default function useSearchParam(name: string) {
  const [search, setSearch] = useState(observer.fn());

  useEffect(() => {
    const unsub = observer.onChange(setSearch);
    return unsub;
  }, []);

  const setParam = useCallback(
    (value: string | null) => {
      setSearchParam(name, value);
    },
    [name],
  );

  return [search.get(name), setParam] as const;
}

export function setSearchParam(name: string, value: string | null) {
  const current = new URLSearchParams(window.location.search);
  const wanted = new URLSearchParams(window.location.search);
  if (value === null) wanted.delete(name);
  else wanted.set(name, value);
  if (compareSearchParams(current, wanted)) return;
  if (wanted.size === 0)
    window.history.pushState({}, "", window.location.pathname);
  else window.history.pushState({}, "", "?" + wanted.toString());
}
