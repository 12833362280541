import { css } from "@emotion/react";
import { Fragment, ReactNode, useCallback, useMemo, useState } from "react";
import { renderToString } from "react-dom/server";
import { useServerSdk } from "../../RootNavigator/services/ServerSdk";
import WithHelp from "../../RootNavigator/assistance/Help/WithHelp";
import PaymentReport from "./PaymentReport";
import { PaymentViewProps } from ".";
import { PreviewView } from "@hpo/client/utilities/Preview";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import T from "@hpo/client/components/text/Text";
import Button from "@hpo/client/components/Button";
import Spacer from "@hpo/client/utilities/Spacer";
import {
  getPrintHash,
  getPrintHtml,
} from "@hpo/client/utilities/print/utilities";
import Theme from "@hpo/client/utilities/Theme/client";
import PrintPreviewModal from "@hpo/client/utilities/print/PrintPreviewModal";

export default function PaymentReportDisplay(props: PaymentViewProps) {
  const { payment, convention, onRefresh } = props;

  const server = useServerSdk();

  const hash = useMemo(() => {
    const body = renderToString(<PaymentReport {...props} signatures />);
    const html = getPrintHtml(location.origin, body, Theme);
    const hash = getPrintHash(html);
    return hash;
  }, [payment]);

  const [onCreateReport] = useSubmitCallback(async () => {
    await server.createPaymentExport(payment.id);
    onRefresh();
  }, [payment]);

  const [preview, setPreview] = useState<ReactNode | null>(() => (
    <PaymentReport
      convention={props.convention}
      payment={payment}
      signatures={false}
    />
  ));
  const [showPreview, setShowPreview] = useState<boolean>(false);

  const onPreviewReport = useCallback(() => {
    setPreview(
      <PaymentReport
        convention={props.convention}
        payment={payment}
        signatures={false}
      />,
    );
    setShowPreview(true);
  }, [payment, props.convention]);

  const containerCss = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  });

  const printPreviewNode = (
    <PrintPreviewModal
      visible={showPreview}
      onClose={() => setShowPreview(false)}
      width="21cm"
      margin="1cm"
    >
      {preview}
    </PrintPreviewModal>
  );

  if (!payment.report) {
    const showFuturPaymentsWarn =
      convention.amendments &&
      (payment.fragment === "deposit1" || payment.fragment === "deposit2");
    return (
      <Fragment>
        <WithHelp
          color={Theme.warning}
          title="Incidence des paiements futurs sur ce rapport"
          text="Avant de rédiger ce rapport d'instruction, assurez vous que les montants accordés et les avances du/des paiement(s) futur(s) de la période sont correctement saisis. En cas de modification des paiements futurs après la signature de ce rapport, ce rapport pourrait devenir caduque et devoir être signé de nouveau."
          inactive={!showFuturPaymentsWarn}
        />
        <div css={containerCss}>
          <Spacer />
          <T align="center">
            Le rapport d'instruction n'est pas encore rédigé. Vous pouvez le
            rédiger dès maintenant et pourrez le mettre à jour au cours de votre
            instruction, à chaque changement.
          </T>
          <Spacer />
          <Button
            onClick={onPreviewReport}
            label="Prévisualiser le rapport d'instruction"
          />
          {printPreviewNode}
          <Spacer />
          <Button
            onClick={onCreateReport}
            label="Rédiger le rapport d'instruction"
          />
        </div>
      </Fragment>
    );
  } else {
    let previewNode = (
      <div css={containerCss}>
        <PreviewView file={payment.report.url} size={600} />
      </div>
    );

    const canEditReport =
      !payment.instructionSignature &&
      !payment.validationSignature &&
      !payment.certificationSignature;

    const helpNode = canEditReport ? (
      <Fragment>
        <T>
          Des modifications dans le paiement ont lieu depuis la production de ce
          rapport d'instruction. Veillez à le mettre à jour avant de le signer.
        </T>
        <Spacer />
        <Button
          onClick={onCreateReport}
          label="Mettre à jour le rapport d'instruction"
        />
      </Fragment>
    ) : (
      <Fragment>
        <T>
          Des modifications dans le paiement ont lieu depuis la production de ce
          rapport d'instruction. Le rapport d'instruction doit être mis à jour
          et signé de nouveau.
        </T>
        <Spacer />
        <Button
          onClick={onPreviewReport}
          label="Prévisualiser le rapport d'instruction à jour"
        />
      </Fragment>
    );

    previewNode = (
      <Fragment>
        <WithHelp
          color={canEditReport ? Theme.warning : Theme.error}
          title="Rapport d'instruction expiré"
          text={helpNode}
          inactive={hash === payment.report.contentHash}
        />
        {previewNode}
        {printPreviewNode}
      </Fragment>
    );

    return <Fragment>{previewNode}</Fragment>;
  }
}
