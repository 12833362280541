import { Global, css } from "@emotion/react";
import { Fragment, ReactNode, useMemo } from "react";
import { createPortal } from "react-dom";
import { intersection } from "lodash";
import dayjs from "dayjs";
import logo from "./assets/logo.svg";
import { useWhoAmI } from "./services/WhoAmI";
import { useServerSdk } from "./services/ServerSdk";
import Config from "./services/Config";
import Button from "@hpo/client/components/Button";
import Spacer from "@hpo/client/utilities/Spacer";
import {
  getRoleLabel,
  getUserName,
  isUserAuthorized,
} from "@hpo/client/utilities/helpers/UserHelper";
import {
  conventionsRoute,
  homeRoute,
  myPaymentsRoute,
  myProjectsRoute,
  paymentsRoute,
  projectsRoute,
  managementRoute,
  yearlyReportRoute,
} from "@hpo/client/utilities/routes";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import Clickable, { ClickableProps } from "@hpo/client/components/Clickable";
import T from "@hpo/client/components/text/Text";
import Theme from "@hpo/client/utilities/Theme/client";
import Role from "@hpo/client/utilities/enums/Role";
import { useLocation } from "@hpo/client/utilities/Routing";
import Intersperse from "@hpo/client/components/Intersperse";
import Icon from "@hpo/client/components/Icon";

export default function Menu() {
  const token = useWhoAmI().use();
  const user = token ? token.getPayload() : null;
  const showMenu = user && isUserAuthorized(user);

  const server = useServerSdk();

  const el = useMemo(() => {
    const existing = document.getElementById("menu");
    if (existing) return existing;
    const el = document.createElement("menu");
    el.id = "menu";
    document.body.prepend(el);
    return el;
  }, []);

  const globalCss = css({
    body: {
      paddingLeft: "17vw",
    },
  });

  const containerCss = css({
    position: "fixed",
    left: 0,
    bottom: 0,
    top: 0,
    zIndex: 1000,
    width: "17vw",
    display: "flex",
    flexDirection: "column",
  });

  const imageCss = css({
    width: "40%",
    margin: "10%",
  });

  const menuWrapper = css({
    flex: 1,
    flexDirection: "column",
  });

  const menuCss = css({
    flex: "1",
    background: "white",
    boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.1)",
    opacity: 0.9,
    padding: 10,
  });

  const [onLogout, logout] = useSubmitCallback(async () => {
    await server.logout();
  }, []);

  let meNode: ReactNode = null;
  if (user) {
    meNode = (
      <div
        css={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <T>{getUserName(user)}</T>
        {user.roles.includes(Role.Benficiary) && user.organization && (
          <T>({user.organization.businessName})</T>
        )}
        {user.roles.map((r) => (
          <T style="minor" key={r} tag="div" opacity={0.7}>
            {getRoleLabel(r)}
          </T>
        ))}
        <Spacer />
        <Button
          onClick={onLogout}
          loading={logout.running}
          label="Se déconnecter"
        />
        <Spacer />
        <T style="minor" center>
          Dernière mise à jour le
          <br />
          {dayjs(Config.build_date).format("LLLL")}
        </T>
      </div>
    );
  }

  let menuNode: ReactNode = null;

  const hasBeneficiaryEntries = useMemo(
    () => user && intersection(user.roles, [Role.Benficiary]).length,
    [user],
  );

  const hasInstructionEntries = useMemo(
    () =>
      user &&
      intersection(user.roles, [
        Role.Instructor,
        Role.Level1,
        Role.Level2,
        Role.Executor,
      ]).length,
    [user],
  );

  const hasManagementEntries = useMemo(
    () => user && intersection(user.roles, [Role.Manager]).length,
    [user],
  );

  const hasReportEntries = useMemo(
    () =>
      user &&
      intersection(user.roles, [Role.Instructor, Role.Level1, Role.Level2])
        .length,
    [user],
  );

  if (showMenu) {
    const listStyle = css({
      listStyleType: "none",
      padding: 0,
      margin: 0,
    });

    menuNode = (
      <div css={menuCss}>
        <Spacer />
        <ul css={listStyle}>
          <Intersperse between={() => <Spacer />}>
            <MenuItemView label="Accueil" clickable={{ route: homeRoute }} />
            {hasBeneficiaryEntries ? (
              <Fragment>
                <MenuItemView
                  label="Mes projets"
                  clickable={{ route: myProjectsRoute }}
                />
                <MenuItemView
                  label="Mes paiements"
                  clickable={{ route: myPaymentsRoute }}
                />
              </Fragment>
            ) : null}
            <MenuItemView
              label="Engagements RGPD"
              clickable={{ href: "/Engagement RGPD.pdf", target: "_blank" }}
            />
            {hasInstructionEntries ? (
              <Fragment>
                <T style="bold">Instruction</T>
                <MenuItemView
                  label="Tous les projets"
                  clickable={{ route: projectsRoute }}
                />
                <MenuItemView
                  label="Toutes les conventions"
                  clickable={{ route: conventionsRoute }}
                />
                <MenuItemView
                  label="Paiements à instruire"
                  clickable={{ route: paymentsRoute }}
                />
              </Fragment>
            ) : null}

            {hasReportEntries ? (
              <Fragment>
                <T style="bold">Rapport annuel</T>
                <MenuItemView
                  label="Rapport annuel"
                  clickable={{ route: yearlyReportRoute }}
                />
              </Fragment>
            ) : null}
            {hasManagementEntries ? (
              <Fragment>
                <T style="bold">Plateforme</T>
                <MenuItemView
                  label="Gestion de la plateforme"
                  clickable={{ route: managementRoute }}
                />
              </Fragment>
            ) : null}
          </Intersperse>
        </ul>
        <Spacer />
      </div>
    );
  }

  return createPortal(
    <Fragment>
      <Global styles={globalCss} />
      <div css={containerCss}>
        <img src={logo} alt="Logo de la Réunion" css={imageCss} />
        <div css={menuWrapper}>{menuNode}</div>
        <Spacer size={2} />
        {meNode}
        <Spacer />
      </div>
    </Fragment>,
    el,
  );
}

type MenuItemViewProps = {
  label: string;
  clickable: ClickableProps;
};

function MenuItemView(props: MenuItemViewProps) {
  const { label, clickable } = props;

  const location = useLocation();
  const selected =
    "route" in clickable ? clickable.route.matchesRootUrl(location) : false;

  const itemCss = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    transition: "100ms",
    "&:hover": {
      transform: "translateX(5px)",
    },
  });

  return (
    <li>
      <Clickable {...clickable} css={itemCss}>
        <T color={selected ? Theme.color : undefined}>
          <Icon name={selected ? "dot" : "chevron"} rotate={90} size={10} />
          <Spacer size={3} horizontal />
          {label}
        </T>
      </Clickable>
    </li>
  );
}
