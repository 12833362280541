import { useCallback, useMemo, useState } from "react";
import dayjs from "dayjs";
import Calendar from "react-calendar";
import { css } from "@emotion/react";
import { rgba } from "polished";
import Theme from "../Theme/client";
import FieldProps from "./FieldProps";
import FieldWrapper from "./components/FieldWrapper";
import { useFieldId, useFieldValidity } from "./utilities";
import FieldInput from "./components/FieldInput";
import FieldDropdown from "./components/FieldDropdown";
import "react-calendar/dist/Calendar.css";
import Button from "@hpo/client/components/Button";
import { cssPerStyle } from "@hpo/client/components/text/Text";

type DateFieldProps = FieldProps<string | null>;

export default function DateField(props: DateFieldProps) {
  const {
    value,
    onChange,
    required = false,
    errorMessage,
    disabled,
    readonly,
    placeholder,
  } = props;

  const id = useFieldId(props.id);
  const validity = useFieldValidity(value, required, (v) => !v, errorMessage);

  const date = useMemo(
    () => (value ? dayjs(value, "YYYY-MM-DD").toDate() : null),
    [value],
  );

  const formatted = useMemo(
    () => (date ? dayjs(date).format("LL") : ""),
    [date],
  );

  const onClickOnDate = useCallback(
    (date: Date) => {
      if (onChange) onChange(dayjs(date).format("YYYY-MM-DD"));
      setDropdown(false);
    },
    [onChange],
  );

  const onClickOnClear = useCallback(() => {
    if (onChange) onChange(null);
    setDropdown(false);
  }, [onChange]);

  const isEditable = !disabled && !readonly;
  const [dropdown, setDropdown] = useState<boolean>(false);

  const wrapperCss = css({
    display: "flex",
  });

  const inputCss = css({
    flex: 1,
  });

  const calendarCss = css(cssPerStyle["body"], {
    padding: 6,
    ".react-calendar": {
      border: "none",
      width: "100%",
      fontFamily: "inherit",
      fontSize: "inherit",
      button: {
        fontFamily: "inherit",
        fontSize: "inherit",
      },
      ".react-calendar__tile": {
        borderRadius: 6,
        borderWidth: 1,
        borderColor: "transparent",
        borderStyle: "solid",
        "&:focus": {
          background: "inherit",
        },
        "&:hover": {
          background: rgba(Theme.color, 1),
          color: "white",
        },
      },
      ".react-calendar__tile--now": {
        borderColor: rgba(Theme.color, 0.7),
        borderStyle: "dashed",
        background: "none",
      },
      ".react-calendar__tile--active": {
        background: rgba(Theme.color, 0.7),
        "&:hover": {
          background: rgba(Theme.color, 1),
          color: "white",
        },
      },
      ".react-calendar__month-view__days__day--weekend": {
        color: rgba("black", 0.5),
      },
    },
  });

  const [finalized, setFinalized] = useState(false);

  return (
    <FieldWrapper {...props} id={id} validity={validity} finalized={finalized}>
      <div css={wrapperCss}>
        <div css={inputCss}>
          <FieldInput
            value={formatted}
            readOnly
            onFocus={() => setDropdown(true)}
            placeholder={placeholder}
          />
        </div>
        {date !== null && isEditable ? (
          <Button icon="close" style="discreet" onClick={onClickOnClear} />
        ) : null}
      </div>
      <FieldDropdown
        visible={isEditable && dropdown}
        onHide={() => {
          setDropdown(false);
          setFinalized(true);
        }}
        height="auto"
      >
        <div css={calendarCss}>
          <Calendar
            defaultValue={date}
            defaultView="month"
            locale="fr-FR"
            maxDetail="month"
            minDetail="year"
            onClickDay={onClickOnDate}
            value={date}
          />
        </div>
      </FieldDropdown>
    </FieldWrapper>
  );
}
