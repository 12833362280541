import messageExceptionCaster from "../message-exception-caster";
import MessageException from "./errors/MessageException";

type AnySuspendable<P, O = void> = {
  params: P;
};

export type SuspendableParams<S> =
  S extends AnySuspendable<infer P> ? P : never;

export type SuspendableResult<S> =
  S extends AnySuspendable<any, infer R> ? R : never;

export default class Suspender<Params> {
  private cache = new Map<string, SuspensableStatus>();
  constructor(
    private fn: (params: Params) => Promise<unknown>,
    private hashFn: (params: Params) => string,
  ) {}

  // Suspense

  getSuspendable<Result>(params: Params) {
    return {
      params,
    } as AnySuspendable<Params, Result>;
  }

  private buildHash<S extends AnySuspendable<Params>>(
    s: SuspendableParams<S>,
  ): string {
    return this.hashFn(s);
  }

  await<S extends AnySuspendable<Params>>(
    suspendable: S,
  ): SuspendableResult<S> {
    const hash = this.buildHash(suspendable.params);
    const cached = this.cache.get(hash);
    if (cached) {
      if (cached.state === "resolved") {
        return cached.value as SuspendableResult<S>;
      } else if (cached.state === "pending") {
        throw cached.promise;
      } else {
        throw cached.error;
      }
    } else {
      const promise = this.execute(suspendable, false);
      throw promise;
    }
  }

  execute<S extends AnySuspendable<Params>>(
    suspendable: S,
    silent: boolean,
  ): Promise<SuspendableResult<S>> {
    const hash = this.buildHash(suspendable.params);
    const promise = this.fn(suspendable.params).then(
      (result) => {
        this.cache.set(hash, { state: "resolved", value: result });
        return result as SuspendableResult<S>;
      },
      (error) => {
        const wrapped = new SuspendableError(error, () =>
          this.execute(suspendable, false),
        );
        this.cache.set(hash, { state: "rejected", error: wrapped });
        throw error;
      },
    );
    const noisy = !silent;
    if (noisy) {
      this.cache.set(hash, { state: "pending", promise });
    }
    return promise;
  }

  refresh<S extends AnySuspendable<Params>>(
    suspendable: S,
    silent: boolean = false,
  ) {
    // const hash = this.buildHash(suspendable.params);
    //this.cache.delete(hash);
    void this.execute(suspendable, silent);
  }

  cacheWith<S extends AnySuspendable<Params>>(
    suspendable: S,
    value: SuspendableResult<S>,
  ) {
    const hash = this.buildHash(suspendable.params);
    this.cache.set(hash, { state: "resolved", value });
  }
}

type SuspensableStatus =
  | { state: "pending"; promise: Promise<unknown> }
  | { state: "resolved"; value: unknown }
  | { state: "rejected"; error: unknown };

export class SuspendableError extends Error {
  constructor(
    cause: unknown,
    readonly retry: () => unknown,
  ) {
    super("SuspendableError", { cause });
  }
}

messageExceptionCaster.registerClass(SuspendableError, (e) => {
  return new MessageException(
    "Cette information n'est pas accessible pour le moment",
    null,
    { invite: "Réessayer", fn: () => e.retry() },
  );
});
