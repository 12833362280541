import { useState } from "react";
import Button from "@hpo/client/components/Button";
import SideBar from "@hpo/client/components/SideBar";
import TextTitleMinor from "@hpo/client/components/text/TextTitleMinor";
import ErrorToast from "@hpo/client/utilities/ErrorToast";
import Spacer from "@hpo/client/utilities/Spacer";
import NumberField from "@hpo/client/utilities/fields/NumberField";
import TextField from "@hpo/client/utilities/fields/TextField";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import { Action, ActionPayload } from "@hpo/client/models/Action";
import ActionType from "@hpo/client/utilities/enums/ActionType";
import MessageException from "@hpo/client/utilities/errors/MessageException";
import { ProjectDraft } from "@hpo/client/models/Project";

type ActionSideBarProps = {
  project: ProjectDraft;
  action: Action | null;
  actionType: ActionType;
  visible: boolean;
  onDone: (newAction: ActionPayload) => unknown;
  onHide: () => unknown;
};

export default function ActionSideBar(props: ActionSideBarProps) {
  const { action, actionType, visible, onDone, onHide } = props;

  const [label, setLabel] = useState<string | null>(action && action.label);

  const [goal, setGoal] = useState<string | null>(action && action.goal);

  const [cost, setCost] = useState<number | null>(action && action.cost);

  const [means, setMeans] = useState<string | null>(action && action.means);

  const [planning, setPlanning] = useState<string | null>(
    action && action.planning,
  );

  const [saveAction, savingStatus] = useSubmitCallback(async () => {
    if (!label || !goal || cost === null || !means || !planning) {
      throw new MessageException("Veuillez remplir tous les champs", null);
    }

    const payload: ActionPayload = {
      type: actionType,
      label,
      goal,
      cost,
      means,
      planning,
    };

    onDone(payload);
    onHide();
  }, [label, goal, cost, means, planning, action]);

  return (
    <SideBar
      visible={visible}
      onHide={onHide}
      header={
        <TextTitleMinor>
          {action
            ? action.label
            : actionType === "communication"
              ? "Nouvelle communication"
              : "Nouveau transfert"}
        </TextTitleMinor>
      }
    >
      <ErrorToast error={savingStatus.error} />
      <TextField
        required
        id="label"
        label="Intitulé"
        value={label}
        onChange={setLabel}
      />
      <Spacer />
      <TextField
        required
        id="goal"
        label="Objectif"
        value={goal}
        onChange={setGoal}
      />
      <Spacer />
      <NumberField
        id="cost"
        value={cost}
        onChange={setCost}
        label="Coût"
        unit="euro"
        allowZero
      />
      <Spacer />
      <TextField
        required
        id="means"
        label="Moyens"
        value={means}
        onChange={setMeans}
      />
      <Spacer />
      <TextField
        required
        id="planning"
        label="Planning"
        value={planning}
        onChange={setPlanning}
      />
      <Spacer scale={2} />
      <Button
        onClick={saveAction}
        label="Valider"
        loading={savingStatus.running}
      />
    </SideBar>
  );
}
