import { Fragment, ReactNode, useCallback, useMemo, useState } from "react";
import Await, { usePromiseState } from "../Await";
import getErrorMessage from "../errors/getErrorMessage.ts";
import List, { ListItem, ListItemProps } from "../List";
import smoothJoin from "../smoothJoin";
import { useFieldId, useFieldValidity } from "./utilities";
import FieldProps from "./FieldProps";
import FieldWrapper from "./components/FieldWrapper";
import FieldDropdown from "./components/FieldDropdown";
import FieldInput from "./components/FieldInput";
import FieldWithButtons from "./components/FieldWithButtons";
import Button from "@hpo/client/components/Button";

type MultiSelectFieldProps<D> = FieldProps<Array<D>> & {
  options: Array<D> | Promise<Array<D>>;
  renderOption: (item: D) => ListItemProps;
  keyExtractor: (d: D) => string;
};

export default function MultiSelectField<T>(props: MultiSelectFieldProps<T>) {
  const {
    value,
    onChange,
    required = false,
    errorMessage,
    keyExtractor,
    renderOption,
  } = props;

  const id = useFieldId(props.id);

  const optionsState = usePromiseState(props.options);

  const validity = useFieldValidity(
    value,
    required,
    (v) => v === undefined || v === null || v.length === 0,
    (v) => {
      if (optionsState.state === "pending") {
        return "Veuillez patienter...";
      } else if (optionsState.state === "rejected") {
        return getErrorMessage(optionsState.error);
      } else {
        const nonIncluded = v.find(
          (v) =>
            !optionsState.value.map((selected) =>
              keyExtractor(v).includes(keyExtractor(selected)),
            ),
        );
        if (nonIncluded !== undefined)
          return "Certaines des valeurs sélectionnées sont invalides";
      }
      if (errorMessage) return errorMessage(v);
      return null;
    },
  );

  const onToggle = useCallback(
    (v: T) => {
      if (onChange) {
        const isIncluded = value.reduce(
          (acc, elem) => keyExtractor(elem) === keyExtractor(v) || acc,
          false,
        );
        if (isIncluded) {
          const newValue = value.filter(
            (elem) => keyExtractor(elem) !== keyExtractor(v),
          );
          onChange(newValue);
        } else onChange([...value, v]);
      }
    },
    [onChange, value, keyExtractor],
  );

  const [dropdown, setDropdown] = useState<boolean>(false);

  const displayedValue = useMemo(() => {
    if (optionsState.state !== "resolved") return null;
    const selectedOptions = value.map((v) =>
      optionsState.value.find((o) => keyExtractor(o) === keyExtractor(v)),
    );
    const labels: Array<string> = [];
    selectedOptions.forEach((s) =>
      s ? labels.push(renderOption(s).label) : null,
    );
    return smoothJoin(labels);
  }, [optionsState, value]);

  let inputNode: ReactNode;
  if (optionsState.state === "pending") {
    inputNode = <FieldInput value="Chargement en cours..." readOnly />;
  } else if (optionsState.state === "rejected") {
    inputNode = (
      <FieldInput value={getErrorMessage(optionsState.error)} readOnly />
    );
  } else {
    inputNode = (
      <FieldWithButtons
        buttons={
          <Fragment>
            {value !== null && onChange ? (
              <Button
                icon={{ name: "close" }}
                style="discreet"
                onClick={() => onChange([])}
              />
            ) : null}
            <Button
              icon={{ name: "chevron", rotate: dropdown ? 0 : 180 }}
              style="discreet"
              onClick={() => setDropdown(!dropdown)}
            />
          </Fragment>
        }
      >
        <FieldInput
          value={displayedValue || ""}
          placeholder={props.placeholder}
          readOnly
          onFocus={() => setDropdown(true)}
        />
      </FieldWithButtons>
    );
  }

  const [finalized, setFinalized] = useState(false);

  return (
    <FieldWrapper {...props} id={id} validity={validity} finalized={finalized}>
      {inputNode}
      <FieldDropdown
        visible={dropdown}
        onHide={() => {
          setDropdown(false);
          setFinalized(true);
        }}
      >
        <Await promise={props.options}>
          {(options) => (
            <List
              data={options}
              renderItem={(i) => (
                <ListItem
                  {...renderOption(i)}
                  onClick={() => onToggle(i)}
                  icon={
                    value.map(keyExtractor).includes(keyExtractor(i))
                      ? "checkbox-on"
                      : "checkbox-off"
                  }
                />
              )}
            />
          )}
        </Await>
      </FieldDropdown>
    </FieldWrapper>
  );
}
