import { ReactNode, useMemo } from "react";
import { css } from "@emotion/react";
import { rgba } from "polished";
import { filesize } from "filesize";
import Button from "../components/Button";
import Await from "./Await";
import { FilePointer, toFilePointer } from "./FilePointer";
import { FileDescriptor, filePointerToFileDescriptor } from "./FileDescriptor";
import T from "@hpo/client/components/text/Text";
import Icon, { IconName } from "@hpo/client/components/Icon";

type FilesGalleryProps = {
  files: Array<string | FilePointer>;
  size: number;
};

export default function FilesGallery(props: FilesGalleryProps) {
  const { files, size = 300 } = props;

  const containerCss = css({
    display: "flex",
    overflow: "auto",
    height: size,
    gap: 10,
    padding: 10,
  });

  const descriptors = useMemo<Array<Promise<FileDescriptor>>>(() => {
    const pointers = files.map(toFilePointer);
    const descriptors = pointers.map(filePointerToFileDescriptor);
    return descriptors;
  }, [files]);

  return (
    <div css={containerCss}>
      {descriptors.map((f, i) => (
        <Await promise={f} key={i}>
          {(d) => <FileDescriptorView file={d} size={size - 20} />}
        </Await>
      ))}
    </div>
  );
}

function useFileDescriptor(
  input: string | FilePointer | FileDescriptor,
): Promise<FileDescriptor> {
  return useMemo(async () => {
    if (typeof input === "string") {
      return filePointerToFileDescriptor(toFilePointer(input));
    } else if ("type" in input) {
      return filePointerToFileDescriptor(input);
    } else {
      return input;
    }
  }, [input]);
}

type PreviewViewProps = {
  file: string | FilePointer | FileDescriptor;
  size: number;
};

export function PreviewView(props: PreviewViewProps) {
  const { file, size } = props;
  const descriptor = useFileDescriptor(file);

  return (
    <Await promise={descriptor}>
      {(d) => <FileDescriptorView file={d} size={size} />}
    </Await>
  );
}

type FileDescriptorPreviewProps = {
  file: FileDescriptor;
  size: number;
};

export function FileDescriptorView(props: FileDescriptorPreviewProps) {
  const { file, size } = props;

  let node: ReactNode;
  if (file.category === "image") {
    node = <PreviewImage {...props} />;
  } else if (file.category === "pdf") {
    node = <PreviewPdf {...props} />;
  } else if (file.category === "archive") {
    node = <PreviewOther {...props} icon="archive" />;
  } else if (file.category === "excel") {
    node = <PreviewOther {...props} icon="excel" />;
  } else if (file.category === "word") {
    node = <PreviewOther {...props} icon="word" />;
  } else {
    node = <PreviewOther {...props} />;
  }

  const containerCss = css({
    height: size,
    flexShrink: 0,
    position: "relative",
  });

  const linkCss = css({
    position: "absolute",
    bottom: 10,
    right: 10,
  });
  return (
    <div css={containerCss}>
      {node}
      <div css={linkCss}>
        <Button icon={"external-link"} href={file.url} />
      </div>
    </div>
  );
}

function PreviewImage(props: FileDescriptorPreviewProps) {
  const { file, size } = props;

  const containerCss = css({
    height: size,
  });
  const imageCss = css({
    height: "100%",
    maxWidth: size,
    objectFit: "contain",
    boxShadow: `0px 0px 10px ${rgba("black", 0.5)}`,
    borderRadius: 6,
    backgroundColor: "white",
  });
  return (
    <div css={containerCss}>
      <img src={file.url} alt="" css={imageCss} />
    </div>
  );
}

function PreviewPdf(props: FileDescriptorPreviewProps) {
  const { file, size } = props;

  const containerCss = css({
    height: size,
    width: (size * 21) / 29.7,
  });
  return (
    <div css={containerCss}>
      <object
        data={file.url}
        css={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: "top center",
          backgroundColor: "white",
          borderRadius: 6,
          boxShadow: `0px 0px 10px ${rgba("black", 0.5)}`,
        }}
      />
    </div>
  );
}

function PreviewOther(props: FileDescriptorPreviewProps & { icon?: IconName }) {
  const { file, size, icon = "file" } = props;

  const containerCss = css({
    height: size,
    width: (size * 2) / 3,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "white",
    borderRadius: 6,
    boxShadow: `0px 0px 10px ${rgba("black", 0.5)}`,
    padding: 10,
  });
  return (
    <div css={containerCss}>
      <span css={{ fontSize: (size * 1) / 3 }}>
        <Icon name={icon} color={rgba("black", 0.25)} />
      </span>
      <T align="center" style="minor" oneLine>
        {file.name}
      </T>
      <T align="justify" style="minor" color={rgba("black", 0.7)}>
        {filesize(file.bytes, { round: 0 })}
      </T>
    </div>
  );
}
