import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { useServerSdk } from "../RootNavigator/services/ServerSdk";
import Button from "@hpo/client/components/Button";
import ContentLayout from "@hpo/client/components/layout/ContentLayout";
import List, { ListItem } from "@hpo/client/utilities/List";
import {
  conventionCreationRoute,
  conventionRoute,
  getUrl,
} from "@hpo/client/utilities/routes";
import smoothJoin from "@hpo/client/utilities/smoothJoin";
import ProgramKey, {
  ProgramKeyValues,
} from "@hpo/client/utilities/enums/ProgramKey";
import T from "@hpo/client/components/text/Text";
import Programs from "@hpo/client/utilities/Programs";
import Intersperse from "@hpo/client/components/Intersperse";
import Spacer from "@hpo/client/utilities/Spacer";
import { OrganizationSummary } from "@hpo/client/models/Organization";
import DropDownField from "@hpo/client/utilities/fields/DropDownField";
import { ConventionSummary } from "@hpo/client/models/Convention";

export default function ConventionsScreen() {
  const server = useServerSdk();

  const conventions = server.suspender.await(server.getConventions());
  const organizations = server.suspender.await(server.getOrganizations());

  const [organization, setOrganization] = useState<OrganizationSummary | null>(
    null,
  );

  const filteredConventions = useMemo(() => {
    if (!organization) return conventions;
    return conventions.filter((c) =>
      c.organizations.some((o) => o.id === organization.id),
    );
  }, [conventions, organization]);

  const byProgram = useMemo(() => {
    return ProgramKeyValues.reduce(
      (acc, program) => {
        const programConventions = filteredConventions.filter(
          (c) => c.program === program,
        );
        if (programConventions.length > 0) {
          acc.push({ program, conventions: programConventions });
        }
        return acc;
      },
      [] as Array<{
        program: ProgramKey;
        conventions: Array<ConventionSummary>;
      }>,
    );
  }, [filteredConventions]);

  return (
    <ContentLayout
      title="Conventions"
      actions={[
        <Button
          key="new"
          label="Nouvelle convention"
          to={getUrl(conventionCreationRoute)}
          icon="plus"
        />,
      ]}
    >
      <DropDownField
        id="org"
        label="Filtrer par bénéficiaire"
        value={organization}
        options={organizations}
        keyExtractor={(o) => o.id}
        renderOption={(o) => ({ label: o.businessName })}
        renderNullOption={() => ({ label: "Tous les bénéficiaires" })}
        onChange={setOrganization}
      />
      <Spacer />
      <Intersperse between={() => <Spacer />}>
        {byProgram.map((p) => {
          return (
            <div>
              <T style="subtitle">{Programs[p.program].label}</T>
              <List
                data={p.conventions}
                renderItem={(c) => (
                  <ListItem
                    label={`Convention pour ${smoothJoin(c.organizations.map((a) => a.businessName))}`}
                    help={`N°${c.reference} en date du ${dayjs(c.date).format("LL")}`}
                    to={getUrl(conventionRoute, { conventionId: c.id })}
                  />
                )}
              />
            </div>
          );
        })}
      </Intersperse>
    </ContentLayout>
  );
}
