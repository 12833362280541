import { without } from "lodash";

export class IntervalBasedObserver<T> {
  private emitted!: T;
  private interval: NodeJS.Timeout | null = null;
  private listeners: Array<(t: T) => unknown> = [];

  constructor(
    readonly fn: () => T,
    private compareFn: (a: T, b: T) => boolean = (a, b) => a === b,
  ) {
    this.emitted = fn();
  }

  onChange(fn: (t: T) => unknown) {
    this.listeners.push(fn);
    this.syncInterval();
    return () => {
      this.listeners = without(this.listeners, fn);
      this.syncInterval();
    };
  }

  syncInterval() {
    if (this.listeners.length === 0 && this.interval !== null) {
      clearInterval(this.interval);
      this.interval = null;
    } else if (this.listeners.length > 0 && this.interval === null) {
      this.interval = setInterval(() => {
        const current = this.fn();
        if (!this.compareFn(current, this.emitted)) {
          this.emitted = current;
          this.listeners.forEach((l) => l(current));
        }
      }, 50);
    }
  }
}
