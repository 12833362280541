import { Fragment } from "react";
import { useServerSdk } from "../../RootNavigator/services/ServerSdk";
import { useAssistance } from "../../RootNavigator/services/Assistance";
import ProjectExpenses from "./ProjectExpenses";
import ProjectIndicators from "./ProjectIndicators";
import ProjectDescriptions from "./ProjectDescriptions";
import ProjectAttachments from "./ProjectAttachments";
import ProjectCommitments from "./ProjectCommitments";
import ProjectCompliances from "./ProjectCompliances";
import {
  ProjectContact,
  ProjectInfos,
  ProjectOrganization,
} from "./ProjectInfos";
import ProjectActions from "./ProjectActions";
import { myProjectRoute } from "@hpo/client/utilities/routes";
import useRouteParam from "@hpo/client/utilities/Routing/useRouteParam";
import Page from "@hpo/client/utilities/Page";
import PageHeader from "@hpo/client/utilities/PageHeader";
import Spacer from "@hpo/client/utilities/Spacer";
import Programs from "@hpo/client/utilities/Programs";
import Tabs from "@hpo/client/utilities/Tabs";
import ActionType from "@hpo/client/utilities/enums/ActionType";
import T from "@hpo/client/components/text/Text";
import TextField from "@hpo/client/utilities/fields/TextField";
import ProjectStatus from "@hpo/client/utilities/enums/ProjectStatus";
import FilesField from "@hpo/client/utilities/fields/FilesField";

export default function MyProjectScreen() {
  const server = useServerSdk();
  const projectId = useRouteParam(myProjectRoute, "projectId");
  const project = server.suspender.await(server.getMyProject(projectId));

  useAssistance().useVisibilityDecision(false);

  return (
    <Page>
      <PageHeader
        title={project.label}
        subtitle={Programs[project.type].label}
        titleOneLine={false}
      />
      <Spacer />
      <TextField textArea value={project.summary} readonly label="Résumé" />
      {project.report ? (
        <Fragment>
          <Spacer />
          <T style="section">Rapport d'instruction</T>
          <div>
            <FilesField value={[project.report]} viewerHeight={500} readonly />
          </div>
        </Fragment>
      ) : null}
      <Spacer />
      <ProjectIndicators
        project={project}
        showEvaluation
        showImpact={project.status === ProjectStatus.Done}
      ></ProjectIndicators>
      <Spacer />
      <Spacer scale={4} />
      <ProjectActions
        project={project}
        actionType={ActionType.Communication}
      ></ProjectActions>
      <Spacer />
      <ProjectActions
        project={project}
        actionType={ActionType.Transfer}
      ></ProjectActions>
      <Spacer />
      <Spacer scale={4} />
      <ProjectExpenses project={project}></ProjectExpenses>
      <Spacer />
      <Spacer scale={4} />
      <ProjectAttachments project={project}></ProjectAttachments>
      <Spacer scale={4} />
      <T style="section">Autres informations</T>
      <Spacer />
      <Tabs showSingleTab>
        <Tabs.Tab id="infos" title="Présentation">
          <ProjectInfos project={project}></ProjectInfos>
        </Tabs.Tab>
        <Tabs.Tab id="contact" title="Contact">
          <ProjectContact project={project}></ProjectContact>
        </Tabs.Tab>
        <Tabs.Tab id="structure" title="Structure">
          <ProjectOrganization project={project}></ProjectOrganization>
        </Tabs.Tab>

        <Tabs.Tab id="commitments" title="Engagements">
          <ProjectCommitments project={project}></ProjectCommitments>
        </Tabs.Tab>
        <Tabs.Tab id="compliances" title="Conformité">
          <ProjectCompliances project={project}></ProjectCompliances>
        </Tabs.Tab>
        <Tabs.Tab id="descriptions" title="Descriptions">
          <ProjectDescriptions project={project}></ProjectDescriptions>
        </Tabs.Tab>
      </Tabs>
    </Page>
  );
}
