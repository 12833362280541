import {
  Fragment,
  PropsWithChildren,
  createContext,
  createRef,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { css } from "@emotion/react";
import { rgba } from "polished";
import { createPortal } from "react-dom";
import { without } from "lodash";
import T from "../components/text/Text";
import Clickable from "../components/Clickable";
import Icon, { IconName } from "../components/Icon";
import R from "./R";
import Theme from "./Theme/client";

class TabsController {
  private r = new R();

  constructor(readonly showSingleTab: boolean) {}

  private tabs: Array<string> = [];
  private current: string | null = null;
  readonly ref = createRef<HTMLDivElement>();

  useCurrent() {
    return this.r.useSelector(() => this.current);
  }

  useIsOpened(id: string) {
    return this.r.useSelector(() => this.current === id, [id]);
  }

  select(id: string) {
    if (this.current === id) return;
    this.current = id;
    this.r.notify();
  }

  useTab(id: string) {
    useEffect(() => {
      this.tabs = [...this.tabs, id];
      this.makeSureTagIsSelected();
      return () => {
        this.tabs = without(this.tabs, id);
        this.makeSureTagIsSelected();
      };
    }, []);
  }

  useTabsCount() {
    return this.r.useSelector(() => this.tabs.length);
  }

  private makeSureTagIsSelected() {
    if (this.current) {
      if (this.tabs.includes(this.current)) {
        return;
      } else {
        this.current = this.tabs[0] || null;
      }
    } else {
      this.current = this.tabs[0] || null;
    }
    this.r.notify();
  }
}

const TabsContext = createContext<TabsController>(new TabsController(false));

type TabsProps = PropsWithChildren<{ showSingleTab?: boolean }>;

export default function Tabs(props: TabsProps) {
  const { showSingleTab = false } = props;
  const controller = useMemo(() => new TabsController(showSingleTab), []);

  const wrapperCss = css({});

  const barCss = css({
    display: "flex",
    borderRadius: 6,
    overflow: "hidden",
    paddingLeft: 10,
    paddingRight: 10,
    overflowX: "auto",
  });

  const contentCss = css({
    // background: Theme.background.loggedin,
    padding: 20,
    borderRadius: 6,
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: "white",
    position: "relative",
    ":before": {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      content: '""',
      zIndex: -1,
      backdropFilter: "blur(20px)",
    },
  });

  const numberOfTabs = controller.useTabsCount();
  const applyStyle = (numberOfTabs === 1 && showSingleTab) || numberOfTabs > 1;

  return (
    <TabsContext.Provider value={controller}>
      <div css={applyStyle ? wrapperCss : null}>
        <div css={applyStyle ? barCss : null}>{props.children}</div>
        <div css={applyStyle ? contentCss : null}>
          <div ref={controller.ref}></div>
        </div>
      </div>
    </TabsContext.Provider>
  );
}

type TabsTabProps = PropsWithChildren<{
  id: string;
  title: string;
  subtitle?: string;
  icon?: IconName;
}>;

function TabsTab(props: TabsTabProps) {
  const { id, title, subtitle, icon } = props;
  const controller = useContext(TabsContext);
  const isOpened = controller.useIsOpened(id);

  controller.useTab(id);
  const tabsCount = controller.useTabsCount();
  const applyStyle =
    (tabsCount === 1 && controller.showSingleTab) || tabsCount > 1;

  const containerCss = css({
    display: "flex",
    flexDirection: "column",
    background: isOpened ? "white" : Theme.background.loggedin,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: isOpened ? Theme.background.loggedin : "transparent",
    borderBottom: "none",
    marginLeft: 3,
    marginRight: 3,
    transition: "all 150ms",
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 6,
    paddingTop: 6,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    cursor: "pointer",
    "&:hover": {
      background: rgba("white", isOpened ? 1 : 0.5),
    },
  });

  const tabNode = (
    <Clickable css={containerCss} onClick={() => controller.select(id)}>
      <T align="center" oneLine>
        {title}
      </T>
      <T align="center" style="minor">
        {subtitle}
      </T>
      {icon && <Icon name={icon} />}
    </Clickable>
  );

  return (
    <Fragment>
      {applyStyle ? tabNode : null}
      {isOpened && controller.ref.current
        ? createPortal(props.children, controller.ref.current)
        : null}
    </Fragment>
  );
}

Tabs.Tab = TabsTab;
