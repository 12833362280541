import { Fragment } from "react";
import { useServerSdk } from "../RootNavigator/services/ServerSdk";
import UsersView from "./UsersView";
import OrganizationsView from "./OrganizationsView";
import ProgramsView from "./ProgramsView";
import TagsScreen from "./TagsScreen";
import useData from "@hpo/client/utilities/useData";
import Await from "@hpo/client/utilities/Await";
import ContentLayout from "@hpo/client/components/layout/ContentLayout";
import Tabs from "@hpo/client/utilities/Tabs";

export default function UsersAndBeneficiaryScreen() {
  const server = useServerSdk();
  const [users, usersData] = useData(async () => server.getUsers(), []);
  const organizations = server.suspender.await(server.getOrganizations());

  const reload = () => {
    usersData.reloadSilent();
    server.suspender.refresh(server.getOrganizations(), true);
  };

  return (
    <Fragment>
      <Await promise={users} hideReload>
        {(users) => (
          <ContentLayout title="Gestion de la plateforme">
            <Tabs showSingleTab>
              <Tabs.Tab id="users" title="Utilisateurs">
                <UsersView
                  users={users}
                  organizations={organizations}
                  onReload={reload}
                />
              </Tabs.Tab>
              <Tabs.Tab id="organizations" title="Bénéficiaires">
                <OrganizationsView
                  organizations={organizations}
                  onReload={reload}
                />
              </Tabs.Tab>
              <Tabs.Tab id="programs" title="Appels à projet">
                <ProgramsView />
              </Tabs.Tab>
              <Tabs.Tab id="tags" title="Etiquettes d'indicateurs">
                <TagsScreen />
              </Tabs.Tab>
            </Tabs>
          </ContentLayout>
        )}
      </Await>
    </Fragment>
  );
}
