import { Fragment, ReactNode, useCallback, useMemo, useState } from "react";
import { useChangeEffect } from "@ehynds/use-change-effect";
import { nanoid } from "nanoid";
import { uniq } from "lodash";
import Table from "../../ConventionNavigator/PaymentScreen/Table";
import IndicatorEditor from "../components/IndicatorEditor";
import WithHelp from "../../RootNavigator/assistance/Help/WithHelp";
import Spacer from "@hpo/client/utilities/Spacer";
import T from "@hpo/client/components/text/Text";
import { ProjectDraft } from "@hpo/client/models/Project";
import Intersperse from "@hpo/client/components/Intersperse";
import {
  BeneficiaryIndicator,
  IndicatorPayload,
} from "@hpo/client/models/Indicator";
import { IndicatorTypeLabels } from "@hpo/client/utilities/enums/IndicatorType";
import IndicatorOrigin from "@hpo/client/utilities/enums/IndicatorOrigin";
import Button from "@hpo/client/components/Button";
import SideBar from "@hpo/client/components/SideBar";
import Units from "@hpo/client/utilities/Units";
import Theme from "@hpo/client/utilities/Theme/client";
import Buttons from "@hpo/client/components/Buttons";
import Programs from "@hpo/client/utilities/Programs";
import Message from "@hpo/client/components/Message";
import Divider from "@hpo/client/components/Divider";

type ProjectIndicatorsProps = {
  project: ProjectDraft;
  onChange?: (d: Partial<ProjectDraft>) => unknown;
  showEvaluation?: boolean;
  showImpact?: boolean;
};

export default function ProjectIndicators(props: ProjectIndicatorsProps) {
  const {
    project,
    onChange,
    showEvaluation = false,
    showImpact = false,
  } = props;

  const locked = onChange === undefined;

  const [indicators, setIndicators] = useState<Array<BeneficiaryIndicator>>(
    project.indicators,
  );

  const evaluationIndicators = useMemo(
    () => indicators.filter((i) => i.origin === IndicatorOrigin.Beneficiary),
    [indicators],
  );

  const impactIndicators = useMemo(
    () =>
      locked
        ? indicators.filter((i) => i.origin === IndicatorOrigin.Instructor)
        : [],
    [indicators, locked],
  );

  const [newIndicatorId, setNewIndicatorId] = useState(nanoid);

  const onInsertIndicator = useCallback(
    (id: string, origin: IndicatorOrigin, p: IndicatorPayload) => {
      setIndicators((current) => [
        ...current,
        {
          id: id,
          type: p.type,
          origin: origin,
          unit: p.unit,
          goals: p.goals.map((g) => ({
            ...g,
            createdAt: new Date().toISOString(),
          })),
          actuals: [],
          label: p.label,
        },
      ]);
      setSelected(null);
      setNewIndicatorId(nanoid());
    },
    [],
  );

  const onUpdateIndicator = useCallback((id: string, p: IndicatorPayload) => {
    setIndicators((current) =>
      current.map((c) =>
        c.id === id
          ? {
              ...c,
              type: p.type,
              unit: p.unit,
              goals: p.goals.map((g) => ({
                ...g,
                createdAt: new Date().toISOString(),
              })),
              label: p.label,
            }
          : c,
      ),
    );
    setSelected(null);
  }, []);

  const onDeleteIndicator = useCallback((id: string) => {
    setIndicators((current) => current.filter((c) => c.id !== id));
  }, []);

  const [selected, setSelected] = useState<string | null>(null);

  useChangeEffect(() => {
    if (!onChange) return;
    onChange({ indicators });
  }, [indicators, onChange]);

  const requiredIndicatorTypes = useMemo(
    () => Programs[project.type].indicatorTypes,
    [project.type],
  );

  const hasAllTypes = useMemo(() => {
    const filled = uniq(indicators.map((i) => i.type))
      .sort()
      .join(" ");
    const toFill = requiredIndicatorTypes.sort().join(" ");

    // Because filled must have types not required in toFill
    return filled.includes(toFill);
  }, [indicators, requiredIndicatorTypes]);

  return (
    <Intersperse between={() => <Spacer />}>
      {showEvaluation ? (
        <div>
          <WithHelp
            text={
              <Fragment>
                <T>
                  Les indicateurs permettront de suivre l'évolution de votre
                  projet et d'en évaluer la performance.
                  <Spacer />
                  Ils se répartissent en 4 catégories :
                  <ul>
                    <li>Les indicateur de réalisation</li>
                    <li>Les indicateur de résultat</li>
                    <li>Les indicateur de communication</li>
                    <li>Les indicateur de transfert aux agriculteurs</li>
                  </ul>
                  {!hasAllTypes ? (
                    <Fragment>
                      <Spacer />
                      <Message color={Theme.warning}>
                        Vous devez renseigner au moins 1 indicateur pour ces{" "}
                        {requiredIndicatorTypes.length} catégories :
                        <ul>
                          {requiredIndicatorTypes.map((type) => (
                            <li key={type}>{IndicatorTypeLabels[type]}</li>
                          ))}
                        </ul>
                      </Message>
                    </Fragment>
                  ) : null}
                  <Spacer />
                  Lors des demandes de paiement, vous pourrez être ammené(e) à
                  indiquer votre réalisation, au regard des objectifs que vous
                  fixez ici.
                  <Spacer />
                  <Divider />
                  <Spacer />
                  Des indicateurs supplémentaires pourront être ajoutés par le
                  Département. Lors des demandes de paiement, vous pourrez
                  renseigner vos objectifs et réalisations sur ces indicateurs
                  supplémentaires.
                </T>
              </Fragment>
            }
          />
          <T style="section">Indicateurs d'évaluation</T>
          <Spacer />
          <Table>
            <thead>
              <tr>
                <th></th>
                {project.periods.map((p) => (
                  <th key={p}>En {p}</th>
                ))}
                {locked ? null : <th></th>}
              </tr>
            </thead>
            <tbody>
              {evaluationIndicators.map((i) => (
                <tr key={i.id}>
                  <td>
                    <T>{i.label}</T>
                    <br />
                    <T style="minor">{IndicatorTypeLabels[i.type]}</T>
                  </td>
                  {project.periods.map((p, periodI) => {
                    return (
                      <td key={p}>
                        <IndicatorMarkers
                          indicator={i}
                          period={p}
                          previous={
                            periodI > 0 ? project.periods[periodI - 1] : null
                          }
                        />
                      </td>
                    );
                  })}
                  {locked ? null : (
                    <td>
                      <Button
                        icon="pencil"
                        onClick={() => setSelected(i.id)}
                        style="discreet"
                      />
                      <SideBar
                        visible={selected === i.id}
                        onHide={() => setSelected(null)}
                      >
                        <IndicatorEditor
                          indicator={i}
                          onDone={(p) => onUpdateIndicator(i.id, p)}
                          onDelete={() => onDeleteIndicator(i.id)}
                          periods={project.periods}
                        />
                      </SideBar>
                    </td>
                  )}
                </tr>
              ))}
              {!locked ? (
                <tr>
                  <td colSpan={project.periods.length + 2}>
                    <Buttons>
                      <Button
                        icon="plus"
                        onClick={() => setSelected("new-evaluation")}
                        label="Ajouter un indicateur"
                      />
                    </Buttons>
                    <SideBar
                      visible={selected === "new-evaluation"}
                      onHide={() => setSelected(null)}
                    >
                      <IndicatorEditor
                        key={newIndicatorId}
                        indicator={null}
                        onDone={(p) =>
                          onInsertIndicator(
                            newIndicatorId,
                            IndicatorOrigin.Beneficiary,
                            p,
                          )
                        }
                        periods={project.periods}
                      />
                    </SideBar>
                  </td>
                </tr>
              ) : (
                <Fragment />
              )}
            </tbody>
          </Table>
        </div>
      ) : null}
      {showImpact ? (
        <div>
          <T style="section">Indicateurs d'impact</T>
          <Spacer />
          <Table>
            <thead>
              <tr>
                <th></th>
                {project.periods.map((p) => (
                  <th key={p}>En {p}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {impactIndicators.map((i) => (
                <tr key={i.id}>
                  <td>
                    <T>{i.label}</T>
                    <br />
                    <T style="minor">{IndicatorTypeLabels[i.type]}</T>
                  </td>
                  {project.periods.map((p, periodI) => {
                    return (
                      <td key={p}>
                        <IndicatorMarkers
                          indicator={i}
                          period={p}
                          previous={
                            periodI > 0 ? project.periods[periodI - 1] : null
                          }
                        />
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : null}
    </Intersperse>
  );
}

type IndicatorMarkersProps = {
  indicator: BeneficiaryIndicator;
  period: string;
  previous: string | null;
};

function IndicatorMarkers(props: IndicatorMarkersProps) {
  const { indicator, period, previous } = props;

  const goal = indicator.goals.find((g) => g.period === period);
  const actual = indicator.actuals.find((g) => g.period === period);
  const previousActual = indicator.actuals.find((g) => g.period === previous);
  const unit = Units[indicator.unit];

  let output: ReactNode = <Fragment />;

  if (goal !== undefined) {
    if (actual !== undefined) {
      output = (
        <T align="center">
          <T align="center">{unit.display(actual.value)}</T>
          <T align="center" style="minor">
            {" "}
            /{unit.display(goal.value)}
          </T>
        </T>
      );
      output = (
        <Fragment>
          {output}
          <Spacer size={4} />
          <Jauge done={actual.value} total={goal.value} />
        </Fragment>
      );
    } else {
      output = (
        <T align="center" style="minor">
          {unit.display(goal.value)}
        </T>
      );
    }
  } else {
    if (actual !== undefined) {
      output = <T align="center">{unit.display(actual.value)}</T>;
    }
  }

  if (
    actual !== undefined &&
    previousActual !== undefined &&
    previousActual.value !== 0
  ) {
    const progress =
      (actual.value - previousActual.value) / previousActual.value;
    output = (
      <Fragment>
        {output}
        <Spacer size={4} />
        <T
          align="center"
          style="minor"
          color={progress > 0 ? Theme.color : Theme.error}
        >
          {progress > 0 ? "▲" : progress < 0 ? "▼" : null}
          {Units.percentage.display(progress)}
        </T>
      </Fragment>
    );
  }

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {output}
    </div>
  );
}

type JaugeProps = { done: number; total: number };
function Jauge(props: JaugeProps) {
  const { done, total } = props;
  if (total === 0) return null;
  const ratio = Math.round((done / total) * 100);
  const color =
    ratio >= 100 ? Theme.color : ratio > 50 ? Theme.warning : Theme.error;
  return (
    <div
      css={{
        width: 100,
        height: 6,
        background: "white",
        borderRadius: 100,
        border: `1px solid ${color}`,
      }}
    >
      <div
        css={{
          width: ratio + "%",
          maxWidth: "100%",
          height: 4,
          background: color,
          borderRadius: 100,
        }}
      ></div>
    </div>
  );
}
