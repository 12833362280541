import { PropsWithChildren } from "react";
import { css } from "@emotion/react";
import Theme from "../utilities/Theme/client";
import Clickable, { ClickableProps } from "./Clickable";
import T from "./text/Text";

type CardProps = PropsWithChildren<{
  title?: string;
  padding?: number;
}> &
  ClickableProps;

export default function Card(props: CardProps) {
  const { title, padding = 10 } = props;
  const containerCss = css({
    width: "100%",
    background: "white",
    padding: padding,
    borderRadius: 6,
    border: `1px solid ${Theme.color}`,
  });
  return (
    <Clickable {...props}>
      <div css={containerCss}>
        {title ? <T>{title}</T> : null}
        {props.children}
      </div>
    </Clickable>
  );
}
