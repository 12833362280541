import {
  Infer,
  coerce,
  literal,
  mask,
  nullable,
  number,
  object,
  omit,
  string,
  union,
  unknown,
} from "superstruct";
import { round } from "lodash";
import ExpenseType from "../utilities/enums/ExpenseType";
import ProgramKey from "../utilities/enums/ProgramKey";
import { AnalysisSchema } from "./Analysis";

// Product

const ProductInstructorSchema = object({
  id: string(),
  type: literal(ExpenseType.Product),
  label: string(),
  provider: string(),
  quotationReference: string(),
  quotationDate: string(),
  quotationDetail: string(),
  quantity: number(),
  annualCost: number(),
  quotationTotalCost: number(),
  helpAmountPerYear: number(),
  helpAmountThreeYears: number(),
  proposal: nullable(number()),
  analysis: AnalysisSchema,
});

export type ProductInstructor = Infer<typeof ProductInstructorSchema>;

const ProductBeneficiarySchema = omit(ProductInstructorSchema, ["analysis"]);
export type ProductBeneficiary = Infer<typeof ProductBeneficiarySchema>;

const ProductPayloadSchema = object({
  type: literal(ExpenseType.Product),
  label: string(),
  provider: string(),
  quotationReference: string(),
  quotationDate: string(),
  quotationDetail: string(),
  quantity: number(),
  annualCost: number(),
});

export type ProductPayload = Infer<typeof ProductPayloadSchema>;

const ProductComputationsSchema = object({
  quotationTotalCost: number(),
  helpAmountPerYear: number(),
  helpAmountThreeYears: number(),
});

export type ProductComputations = Infer<typeof ProductComputationsSchema>;

export function computeProduct(
  p: ProductPayload,
  program: ProgramKey,
): ProductComputations {
  let helpAmountPerYear;
  if (program === "lutte_collective_agroeco") {
    helpAmountPerYear = Math.min(p.annualCost * 0.75, 150000);
  } else if (program === "systemes_qualite") {
    helpAmountPerYear = p.annualCost;
  } else {
    helpAmountPerYear = Math.min(p.annualCost * 0.5, 80000);
  }
  return {
    quotationTotalCost: round(p.annualCost * 3, 2),
    helpAmountPerYear: round(helpAmountPerYear, 2),
    helpAmountThreeYears: round(helpAmountPerYear * 3, 2),
  };
}

// Service

const ServiceInstructorSchema = object({
  id: string(),
  type: literal(ExpenseType.Service),
  label: string(),
  provider: string(),
  quotationReference: string(),
  quotationDate: string(),
  quotationDetail: string(),
  annualCost: number(),
  quotationTotalCost: number(),
  helpAmountPerYear: number(),
  helpAmountThreeYears: number(),
  proposal: nullable(number()),
  analysis: AnalysisSchema,
});

export type ServiceInstructor = Infer<typeof ServiceInstructorSchema>;

const ServiceBeneficiarySchema = omit(ServiceInstructorSchema, ["analysis"]);
export type ServiceBeneficiary = Infer<typeof ServiceBeneficiarySchema>;

export const ServicePayloadSchema = object({
  type: literal(ExpenseType.Service),
  label: string(),
  provider: string(),
  quotationReference: string(),
  quotationDate: string(),
  quotationDetail: string(),
  annualCost: number(),
});

export type ServicePayload = Infer<typeof ServicePayloadSchema>;

const ServiceComputationsSchema = object({
  quotationTotalCost: number(),
  helpAmountPerYear: number(),
  helpAmountThreeYears: number(),
});

export type ServiceComputations = Infer<typeof ServiceComputationsSchema>;

export function computeService(
  p: ServicePayload,
  program: ProgramKey,
): ServiceComputations {
  let helpAmountPerYear: number;
  if (program === "animation")
    helpAmountPerYear = Math.min(p.annualCost, 70000);
  else if (program === "animation_chambre")
    helpAmountPerYear = Math.min(p.annualCost, 100000);
  else if (program === "promotions_produits")
    helpAmountPerYear = Math.min(p.annualCost, 20000);
  else if (program === "lutte_collective_agroeco")
    helpAmountPerYear = Math.min(p.annualCost * 0.75, 150000);
  else if (program === "systemes_qualite") helpAmountPerYear = p.annualCost;
  else
    throw Error("Invalid project type for service expense. Got : " + program);
  return {
    quotationTotalCost: round(p.annualCost * 3, 2),
    helpAmountPerYear: round(helpAmountPerYear, 2),
    helpAmountThreeYears: round(helpAmountPerYear * 3, 2),
  };
}

// Salary

const SalaryInstructorSchema = object({
  id: string(),
  type: literal(ExpenseType.Salary),
  label: string(),
  annualCost: number(),
  employeeName: string(),
  employeePosition: string(),
  affectation: number(),
  fullCostOneYear: number(),
  fullCostThreeYears: number(),
  annualCostCeiling: number(),
  helpAmountPerYear: number(),
  helpAmountThreeYears: number(),
  proposal: nullable(number()),
  analysis: AnalysisSchema,
});

export type SalaryInstructor = Infer<typeof SalaryInstructorSchema>;

const SalaryBeneficiarySchema = omit(SalaryInstructorSchema, ["analysis"]);
export type SalaryBeneficiary = Infer<typeof SalaryBeneficiarySchema>;

const SalaryPayloadSchema = object({
  type: literal(ExpenseType.Salary),
  label: string(),
  annualCost: number(),
  employeeName: string(),
  employeePosition: string(),
  affectation: number(),
});

export type SalaryPayload = Infer<typeof SalaryPayloadSchema>;

const SalaryComputationsSchema = object({
  fullCostOneYear: number(),
  fullCostThreeYears: number(),
  annualCostCeiling: number(),
  helpAmountPerYear: number(),
  helpAmountThreeYears: number(),
});

export type SalaryComputations = Infer<typeof SalaryComputationsSchema>;

export function computeSalary(
  p: SalaryPayload,
  program: ProgramKey,
): SalaryComputations {
  if (program === "animation" || program === "animation_chambre") {
    const fullCostOneYear = p.annualCost * p.affectation * 1.4;
    const fullCostThreeYears = fullCostOneYear * 3;
    const annualCostCeiling = Math.min(p.annualCost, 70000);
    const eligibleHelpOneYear = annualCostCeiling * p.affectation * 1.4;
    const eligibleHelpThreeYears = eligibleHelpOneYear * 3;
    return {
      fullCostOneYear: round(fullCostOneYear, 2),
      fullCostThreeYears: round(fullCostThreeYears, 2),
      annualCostCeiling: round(annualCostCeiling, 2),
      helpAmountPerYear: round(eligibleHelpOneYear, 2),
      helpAmountThreeYears: round(eligibleHelpThreeYears, 2),
    };
  } else if (program === "echanges_connaissances") {
    const fullCostOneYear = p.annualCost * p.affectation;
    const fullCostThreeYears = fullCostOneYear * 3;
    const annualCostCeiling = Math.min(p.annualCost, 60000);
    const helpAmountPerYear = annualCostCeiling * p.affectation;
    const helpAmountThreeYears = helpAmountPerYear * 3;
    return {
      fullCostOneYear: round(fullCostOneYear, 2),
      fullCostThreeYears: round(fullCostThreeYears, 2),
      annualCostCeiling: round(annualCostCeiling, 2),
      helpAmountPerYear: round(helpAmountPerYear, 2),
      helpAmountThreeYears: round(helpAmountThreeYears, 2),
    };
  } else {
    throw Error("Invalid project type for salary expense. Got : " + program);
  }
}

// Expense

export const ExpenseInstructorSchema = coerce(
  union([
    ServiceInstructorSchema,
    SalaryInstructorSchema,
    ProductInstructorSchema,
  ]),
  unknown(),
  (v: unknown) => {
    try {
      return mask(v, ProductInstructorSchema);
    } catch (_err) {
      try {
        return mask(v, ServiceInstructorSchema);
      } catch (_err) {
        return mask(v, SalaryInstructorSchema);
      }
    }
  },
);

export type ExpenseInstructor = Infer<typeof ExpenseInstructorSchema>;

export const ExpenseBeneficiarySchema = coerce(
  union([
    ServiceBeneficiarySchema,
    SalaryBeneficiarySchema,
    ProductBeneficiarySchema,
  ]),
  unknown(),
  (v: unknown) => {
    try {
      return mask(v, ProductBeneficiarySchema);
    } catch (_err) {
      try {
        return mask(v, ServiceBeneficiarySchema);
      } catch (_err) {
        return mask(v, SalaryBeneficiarySchema);
      }
    }
  },
);

export type ExpenseBeneficiary = Infer<typeof ExpenseBeneficiarySchema>;

export const ExpensePayloadSchema = coerce(
  union([ServicePayloadSchema, SalaryPayloadSchema, ProductPayloadSchema]),
  unknown(),
  (v: unknown) => {
    try {
      return mask(v, ProductPayloadSchema);
    } catch (_err) {
      try {
        return mask(v, ServicePayloadSchema);
      } catch (_err) {
        return mask(v, SalaryPayloadSchema);
      }
    }
  },
);

export type ExpensePayload = Infer<typeof ExpensePayloadSchema>;
