import ConventionScreen from "../ConventionNavigator/ConventionScreen";
import ConventionPaymentScreen from "./PaymentScreen";
import { Route, Switch } from "@hpo/client/utilities/Routing";
import {
  conventionPaymentRoute,
  conventionRoute,
} from "@hpo/client/utilities/routes";

export default function ConventionNavigator() {
  return (
    <Switch>
      <Route destination={conventionRoute} exact component={ConventionScreen} />
      <Route
        destination={conventionPaymentRoute}
        component={ConventionPaymentScreen}
      />
    </Switch>
  );
}
