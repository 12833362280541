import MyPaymentsNavigator from "../MyPaymentsNavigator";
import ProjectNavigator from "../ProjectNavigator";
import ManagementNavigator from "../ManagementNavigator";
import ConventionsNavigator from "../ConventionsNavigator";
import PaymentsNavigator from "../PaymentsNavigator";
import MyProjectsNavigator from "../MyProjectsNavigator";
import YearlyReportNavigator from "../YearlyReportNavigator";
import FourOFour from "../RootNavigator/FourOFour";
import WelcomeScreen from "./WelcomeScreen";
import { Route, Switch } from "@hpo/client/utilities/Routing";
import {
  conventionsRoute,
  homeRoute,
  myPaymentsRoute,
  myProjectsRoute,
  paymentsRoute,
  projectsRoute,
  managementRoute,
  errorRoute,
  yearlyReportRoute,
} from "@hpo/client/utilities/routes";
import messageExceptionCaster from "@hpo/client/message-exception-caster";
import MessageException from "@hpo/client/utilities/errors/MessageException";
import DefaultRoute from "@hpo/client/utilities/Routing/DefaultRoute";

export default function LoggedInNavigator() {
  return (
    <Switch>
      <Route destination={homeRoute} exact component={WelcomeScreen} />
      <Route destination={projectsRoute} component={ProjectNavigator} />
      <Route destination={conventionsRoute} component={ConventionsNavigator} />
      <Route destination={myProjectsRoute} component={MyProjectsNavigator} />
      <Route destination={myPaymentsRoute} component={MyPaymentsNavigator} />
      <Route destination={paymentsRoute} component={PaymentsNavigator} />
      <Route destination={managementRoute} component={ManagementNavigator} />
      <Route
        destination={yearlyReportRoute}
        component={YearlyReportNavigator}
      />
      <Route destination={errorRoute} component={TestErrorView} />
      <DefaultRoute component={FourOFour} />
    </Switch>
  );
}

function TestErrorView() {
  throw new TestError();
  return null;
}

class TestError extends Error {}

messageExceptionCaster.registerClass(
  TestError,
  () => new MessageException("Ceci est une erreur de test", null),
);
