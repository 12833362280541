import ConventionNavigator from "../ConventionNavigator";
import ConventionsScreen from "./ConventionsScreen";
import NewConventionScreen from "./NewConventionScreen";
import { Route, Switch } from "@hpo/client/utilities/Routing";
import {
  conventionCreationRoute,
  conventionRoute,
  conventionsRoute,
} from "@hpo/client/utilities/routes";

export default function ConventionsNavigator() {
  return (
    <Switch>
      <Route
        destination={conventionsRoute}
        exact
        component={ConventionsScreen}
      />
      <Route
        destination={conventionCreationRoute}
        component={NewConventionScreen}
      />
      <Route destination={conventionRoute} component={ConventionNavigator} />
    </Switch>
  );
}
