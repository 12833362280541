import {
  Fragment,
  PropsWithChildren,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { css } from "@emotion/react";
import { difference, findIndex, uniq } from "lodash";
import Button from "../components/Button";
import { IconName } from "../components/Icon";
import Buttons from "../components/Buttons";
import R from "./R";
import { useSubmitCallback } from "./useSubmitCallback";
import ErrorToast from "./ErrorToast";
import List, { ListItem } from "./List";
import Theme from "./Theme/client";
import Badge from "./Badge";
import useSearchParam, { setSearchParam } from "./Routing/useSearchParam";
import Spacer from "@hpo/client/utilities/Spacer";

type Stepper2Props = PropsWithChildren<{
  intro?: ReactNode;
  onEnd?: () => unknown;
}>;

class Stepper2Controller {
  private r = new R();

  private current: string | null = null;
  private ids: Array<string> = [];
  private stepsDone: Array<string> = [];
  private steps: Record<string, Stepper2StepProps> = {};
  private searchParam: string = "etape";

  useStep(props: Stepper2StepProps) {
    useEffect(() => {
      if (!this.ids.includes(props.id)) {
        this.ids.push(props.id);
      }
      this.steps[props.id] = props;
      const validate = props.validate;
      if (validate !== undefined) {
        try {
          validate(false);
          if (!this.stepsDone.includes(props.id)) {
            this.stepsDone.push(props.id);
          }
        } catch {
          // Do nothing
        }
      }
      this.r.notify();
      return () => {
        delete this.steps[props.id];
        this.r.notify();
      };
    }, [props.id, props.title, props.validate]);
  }

  private buildSteps() {
    const output: Array<Stepper2StepProps> = [];
    this.ids.forEach((id) => {
      const step = this.steps[id];
      if (step) output.push(step);
    });
    return output;
  }

  useSteps() {
    return this.r.useSelector(() => {
      return this.buildSteps();
    });
  }

  useCurrentKey() {
    const [string] = useSearchParam(this.searchParam);
    return string;
  }

  useCurrent() {
    const key = this.useCurrentKey();
    return this.r.useSelector(() => {
      if (!key) return null;
      return this.steps[key] || null;
    }, [key]);
  }

  usePrevious() {
    const current = this.useCurrentKey();
    return this.r.useSelector(() => {
      const steps = this.buildSteps();
      const index = findIndex(steps, (s) => s.id === current);
      if (index <= 0) return null;
      return steps[index - 1];
    }, [current]);
  }

  useNext() {
    const current = this.useCurrentKey();
    return this.r.useSelector(() => {
      const steps = this.buildSteps();
      const index = findIndex(steps, (s) => s.id === current);
      if (index <= -1) return null;
      if (!steps[index + 1]) return null;
      return steps[index + 1];
    }, [current]);
  }

  useIsComplete() {
    return this.r.useSelector(() => {
      return difference(this.ids, this.stepsDone).length === 0;
    }, []);
  }

  setCurrent(key: string | null) {
    setSearchParam(this.searchParam, key);
    this.checkDoneSteps();
    this.r.notify();
  }

  markAsDone(key: string) {
    this.stepsDone = uniq([...this.stepsDone, key]);
    this.r.notify();
  }

  checkDoneSteps() {
    this.stepsDone = this.stepsDone.filter((s) => {
      if (!(s in this.steps)) return false;
      const step = this.steps[s];
      return this.isStepSubmittable(step);
    });
  }

  isStepSubmittable(props: Stepper2StepProps) {
    const validate = props.validate;
    if (validate === undefined) return true;
    try {
      validate(false);
      return true;
    } catch {
      return false;
    }
  }

  getStepState(key: string) {
    const stepsDone = this.stepsDone;
    const stepsLeft = difference(this.ids, stepsDone);
    const openedSteps: Array<string> = [];
    if (stepsLeft[0]) {
      const nextStep = stepsLeft[0];
      openedSteps.push(nextStep);
      const concurrentOfNextStep = Object.values(this.steps)
        .filter(
          (s) =>
            nextStep && s.concurentWith && s.concurentWith.includes(nextStep),
        )
        .map((s) => s.id);
      openedSteps.push(...concurrentOfNextStep);
    }

    if (stepsDone.includes(key)) return "done";
    else if (openedSteps.includes(key)) return "to-be-done-now";
    else return "to-be-done-later";
  }
}

const Stepper2Context = createContext<Stepper2Controller>(
  new Stepper2Controller(),
);

export default function Stepper2(props: Stepper2Props) {
  const controller = useMemo(() => new Stepper2Controller(), []);
  const steps = controller.useSteps();
  const current = controller.useCurrent();
  const next = controller.useNext();

  const onEndRef = useRef<(() => unknown) | undefined>(undefined);
  onEndRef.current = props.onEnd;

  const onBack = () => controller.setCurrent(null);

  const [onNext, nexting] = useSubmitCallback(async () => {
    if (current && current.validate) {
      await current.validate(true);
    }
    if (current) {
      controller.markAsDone(current.id);
    }
    controller.setCurrent(null);
  }, [current, next, props.onEnd]);

  const showFinalSubmitButton = controller.useIsComplete() && props.onEnd;

  return (
    <Stepper2Context.Provider value={controller}>
      <ErrorToast error={nexting.error} />
      {!current ? (
        <Fragment>
          {props.intro}
          <Stepper2Menu
            steps={steps}
            onSelect={(s) => controller.setCurrent(s.id)}
          />
          {showFinalSubmitButton ? (
            <Fragment>
              <Spacer />
              <Buttons>
                <Button label="Terminer" onClick={props.onEnd} />
              </Buttons>
            </Fragment>
          ) : null}
        </Fragment>
      ) : null}
      {props.children}
      <Spacer />
      {current ? (
        <Fragment>
          <Spacer />
          <Stepper2Buttons onBack={onBack} onSubmit={onNext} />
        </Fragment>
      ) : null}
    </Stepper2Context.Provider>
  );
}

type Stepper2StepProps = PropsWithChildren<{
  id: string;
  title: string;
  details?: string;
  concurentWith?: Array<string>;
  validate: (dueToUserSubmission: boolean) => unknown;
}>;

function Stepper2Step(props: Stepper2StepProps) {
  const controller = useContext(Stepper2Context);
  controller.useStep(props);
  const current = controller.useCurrent();
  const isHidden = !current || props.id !== current.id;
  return (
    <div css={{ display: isHidden ? "none" : "block" }}>{props.children}</div>
  );
}

Stepper2.Step = Stepper2Step;

type Stepper2MenuProps = PropsWithChildren<{
  steps: Array<Stepper2StepProps>;
  onSelect: (step: Stepper2StepProps) => unknown;
}>;

function Stepper2Menu(props: Stepper2MenuProps) {
  const { steps } = props;
  const controller = useContext(Stepper2Context);
  return (
    <List
      data={props.steps}
      renderItem={(s, i) => {
        const state = controller.getStepState(s.id);

        let icon: IconName;
        let iconColor: string;
        let rightNode: ReactNode = null;

        if (state === "done") {
          icon = "checkbox-on";
          iconColor = Theme.color;
          rightNode = (
            <Fragment>
              <Badge>Etape terminée</Badge>
              <Spacer horizontal />
              <Button icon={{ name: "chevron", rotate: 90 }} style="discreet" />
            </Fragment>
          );
        } else if (state === "to-be-done-now") {
          icon = "checkbox-off";
          iconColor = Theme.text.normal;
          rightNode = <Button label="Commencer" />;
        } else {
          icon = "dots-v";
          iconColor = Theme.text.fade;
          rightNode = <Badge color={"lightgrey"}>Etape à venir</Badge>;
        }

        return (
          <ListItem
            intro={`Etape ${i + 1}/${steps.length}`}
            disabled={state === "to-be-done-later"}
            help={state === "to-be-done-now" ? s.details : undefined}
            icon={{
              name: icon,
              color: iconColor,
              size: 20,
            }}
            label={s.title}
            onClick={
              state !== "to-be-done-later" ? () => props.onSelect(s) : undefined
            }
            right={rightNode}
          />
        );
      }}
    />
  );
}

type Stepper2ButtonsProps = {
  onBack?: () => unknown;
  onSubmit?: () => unknown;
};

function Stepper2Buttons(props: Stepper2ButtonsProps) {
  const containerCss = css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  });

  return (
    <div css={containerCss}>
      <Button
        label="Retour"
        style="discreet"
        onClick={props.onBack}
        disabled={!props.onBack}
      />
      <Button
        label={"Valider et continuer"}
        onClick={props.onSubmit}
        disabled={!props.onSubmit}
      />
    </div>
  );
}

export const during = (n: number) =>
  new Promise<void>((res) => setTimeout(res, n));
