import { Fragment, useRef, useState } from "react";
import { sum } from "lodash";
import { useServerSdk } from "../../RootNavigator/services/ServerSdk";
import WithHelp from "../../RootNavigator/assistance/Help/WithHelp";
import { PaymentViewProps } from ".";
import T from "@hpo/client/components/text/Text";
import ErrorToast from "@hpo/client/utilities/ErrorToast";
import DateField from "@hpo/client/utilities/fields/DateField";
import NumberField from "@hpo/client/utilities/fields/NumberField";
import Toasting from "@hpo/client/components/Toasting";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import useRouteParam from "@hpo/client/utilities/Routing/useRouteParam";
import {
  conventionPaymentRoute,
  conventionRoute,
} from "@hpo/client/utilities/routes";
import Dialog from "@hpo/client/components/Dialog";
import Button from "@hpo/client/components/Button";
import Spacer from "@hpo/client/utilities/Spacer";
import SideBar from "@hpo/client/components/SideBar";
import FieldsForm from "@hpo/client/utilities/fields/FieldsForm";
import MessageException from "@hpo/client/utilities/errors/MessageException";
import Intersperse from "@hpo/client/components/Intersperse";
import { getProjectLabel } from "@hpo/client/utilities/helpers/ProjectHelper";
import Units from "@hpo/client/utilities/Units";
import Divider from "@hpo/client/components/Divider";
import { PaymentFragmentRates } from "@hpo/client/utilities/enums/PaymentFragment";
import InstructorPaymentStatus, {
  isRequested,
} from "@hpo/client/utilities/enums/InstructorPaymentStatus";
import PromiseToast from "@hpo/client/utilities/PromiseToast";
import Theme from "@hpo/client/utilities/Theme/client";
import navigate from "@hpo/client/utilities/Routing/navigate";
import { ClickableForwaredRef } from "@hpo/client/components/Clickable";

export default function PaymentSettings(props: PaymentViewProps) {
  const { payment, onRefresh } = props;
  const server = useServerSdk();
  const conventionId = useRouteParam(conventionPaymentRoute, "conventionId");

  // Granted amounts

  const [grantedAmountsMenu, setGrantedAmountsMenu] = useState(false);

  const [grantedAmounts, setGrantedAmounts] = useState<
    Record<string, number | null>
  >(() =>
    Object.fromEntries(
      payment.achievements.map((a) => [a.id, a.grantedAmount]),
    ),
  );

  const [onChangeGrantedAmounts, grantedAmountsChange] =
    useSubmitCallback(async () => {
      if (Object.values(grantedAmounts).includes(null))
        throw new MessageException(
          "Saisissez un montant pour tous les projets",
          null,
        );
      await Promise.all(
        Object.entries(grantedAmounts).map(([a, v]) =>
          server.updateAchievement(a, { grantedAmount: v as number }),
        ),
      );
      Toasting.success("OK", "Montant modifié");
      onRefresh();
      setGrantedAmountsMenu(false);
    }, [grantedAmounts]);

  // Committee date
  const [committeeDateMenu, setCommitteeDateMenu] = useState(false);

  const [committeeDate, setCommitteeDate] = useState<string | null>(
    payment.committeeDate,
  );

  const [onChangeCommitteeDate, committeeDateChange] =
    useSubmitCallback(async () => {
      if (!committeeDate)
        throw new MessageException("Saisissez une date", null);
      await server.updatePayment(payment.id, { committeeDate });
      Toasting.success(
        "OK",
        "La date de comité technique de suivi a été modifiée",
      );
      onRefresh();
      setCommitteeDateMenu(false);
    }, [committeeDate]);

  // Advance

  const [advanceMenu, setAdvanceMenu] = useState(false);

  const [advance, setAdvance] = useState<number | null>(payment.maximalAdvance);

  const [onChangeAdvance, advanceChange] = useSubmitCallback(async () => {
    await server.updatePayment(payment.id, { maximalAdvance: advance });
    Toasting.success("OK", "Le montant de l'avance a été modifié");
    onRefresh();
    setAdvanceMenu(false);
  }, [advance]);

  // Unsign

  const [onUnsign, unsinging] = useSubmitCallback(async () => {
    const confirm = await Dialog.confirm({
      message:
        "Le rapport d'instruction et toutes ses signatures seront supprimés.",
    });
    if (!confirm) return;
    await server.deletePaymentReport(payment.id, null);
    await onRefresh();
  }, [payment]);

  // Redeclare

  const [onRedeclare, redeclaring] = useSubmitCallback(async () => {
    const confirm = await Dialog.confirm({
      message:
        "Le rapport d'instruction et toutes ses signatures seront supprimés.",
    });
    if (!confirm) return;
    await server.deletePaymentInstruction(payment.id);
    await onRefresh();
  }, [payment]);

  // Deletion

  const [onDelete] = useSubmitCallback(async () => {
    const confirm = await Dialog.confirm({
      message: "Ce paiement sera supprimé ce manière définitive",
    });
    if (!confirm) return;
    await server.deletePayment(payment.id);
    navigate(conventionRoute.getRootUrl({ conventionId }), {
      replace: true,
    });
    Toasting.success("OK", "Paiement supprimé");
  }, [payment]);

  const changeAdvanceRef = useRef<ClickableForwaredRef>(null);

  return (
    <Fragment>
      <T style="subtitle">Opérations avancées</T>
      <Spacer />
      <Button
        label="Changer le montant accordé"
        onClick={() => setGrantedAmountsMenu(!committeeDateMenu)}
        disabled={isRequested(payment.status)}
      />
      <SideBar
        visible={grantedAmountsMenu}
        onHide={() => setGrantedAmountsMenu(false)}
      >
        <FieldsForm onSubmit={onChangeGrantedAmounts}>
          <Intersperse between={() => <Spacer />}>
            {payment.achievements.map((a) => (
              <NumberField
                key={a.id}
                label={`Pour le projet "${getProjectLabel(a.funding.project)}"`}
                value={grantedAmounts[a.id]}
                onChange={(v) =>
                  setGrantedAmounts((c) => ({ ...c, [a.id]: v }))
                }
                unit="euro"
              />
            ))}
          </Intersperse>
          <Spacer />
          <Divider />
          <Spacer />
          <T>
            Total :{" "}
            {Object.values(grantedAmounts).includes(null)
              ? "-"
              : Units.euro.display(sum(Object.values(grantedAmounts)))}
          </T>
          <Spacer />
          <T style="minor" display="block">
            Théoriquement : {Units.euro.display(payment.theoreticalAmount)} soit{" "}
            {Units.percentage.display(PaymentFragmentRates[payment.fragment])}{" "}
            du montant annuel accordé dans la convention.
          </T>
          <Spacer />
          <Button
            submit
            label="Valider"
            loading={committeeDateChange.running}
          />

          <ErrorToast error={committeeDateChange.error} />
        </FieldsForm>
        <ErrorToast error={grantedAmountsChange.error} />
      </SideBar>

      <Spacer />
      <Button
        label="Changer la date du comité technique de suivi"
        onClick={() => setCommitteeDateMenu(!committeeDateMenu)}
      />
      <SideBar
        visible={committeeDateMenu}
        onHide={() => setCommitteeDateMenu(false)}
      >
        <FieldsForm onSubmit={onChangeCommitteeDate}>
          <DateField
            value={committeeDate}
            onChange={setCommitteeDate}
            label="Date du comité technique de suivi"
          />
          <Spacer />
          <Button
            submit
            label="Valider"
            loading={committeeDateChange.running}
          />
          <ErrorToast error={committeeDateChange.error} />
        </FieldsForm>
      </SideBar>

      <Spacer />
      <WithHelp
        title="Fonctionnalité indisponible"
        text="La plateforme ne supporte pas les avances pour les conventions s'adressant à plusieurs bénéficiares."
        inactive={payment.canHaveAdvance}
        color={Theme.warning}
        mouseoverRef={changeAdvanceRef}
      />
      <Button
        label="Changer le montant de l'avance disponible"
        onClick={() => setAdvanceMenu(!advanceMenu)}
        disabled={payment.status === "paid" || !payment.canHaveAdvance}
        ref={changeAdvanceRef}
      />
      <SideBar visible={advanceMenu} onHide={() => setAdvanceMenu(false)}>
        <FieldsForm onSubmit={onChangeAdvance}>
          <T style="subtitle">Avance disponible</T>
          <Spacer />
          <T>
            Cette section permet de déclarer les montants qui ont versés au
            bénéficiare avant la convention.
          </T>
          <Spacer />
          <T style="bold">
            Le montant saisi ici sera retranché du montant définitif (validé
            niv. 2) juste avant le paiement.
          </T>
          <Spacer />
          <T>
            Dans le cas où l'avance disponible serait plus imporante que le
            montant validé niveau 2, un mail vous sera envoyé pour vous en
            informer. Vous pourrez alors reporter l'exécedent en tant qu'avance
            disponible sur la paiement suivant.
          </T>
          <Spacer />
          <NumberField
            value={advance}
            onChange={setAdvance}
            label="Montant de l'avance disponible"
            placeholder="Aucune avance versée"
            unit="euro"
          />
          <Spacer />
          <Button submit label="Valider" loading={advanceChange.running} />
          <ErrorToast error={advanceChange.error} />
        </FieldsForm>
        <ErrorToast error={advanceChange.error} />
      </SideBar>

      <Spacer />

      <Button
        label="Revenir à l'instruction"
        style="danger"
        onClick={onUnsign}
        disabled={!payment.instructionSignature}
      />
      <PromiseToast
        promise={unsinging.promise}
        message={"Le paiement est ouvert à l'instruction"}
      />

      <Spacer />
      <Button
        label="Revenir à la déclaration par le bénéficiaire"
        style="danger"
        onClick={onRedeclare}
        disabled={payment.status !== InstructorPaymentStatus.UnderInstruction}
      />
      <PromiseToast
        promise={redeclaring.promise}
        message={
          "Le paiement est de nouveau en phase de déclaration pour le bénéficiaire"
        }
      />

      <Spacer />

      <Button
        label="Supprimer ce paiement"
        style="danger"
        onClick={onDelete}
        disabled={
          !(
            payment.status === "configurable" ||
            payment.status === "waiting-for-documents"
          )
        }
      />
    </Fragment>
  );
}
