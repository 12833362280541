import { useAssistance } from "../RootNavigator/services/Assistance";
import ProjectScreen from "./ProjectScreen";
import ProjectsScreen from "./ProjectsScreen";
import { Route, Switch } from "@hpo/client/utilities/Routing";
import { projectRoute, projectsRoute } from "@hpo/client/utilities/routes";

export default function ProjectNavigator() {
  const assistance = useAssistance();
  assistance.useVisibilityDecision(false);

  return (
    <Switch>
      <Route destination={projectsRoute} exact component={ProjectsScreen} />
      <Route destination={projectRoute} component={ProjectScreen} />
    </Switch>
  );
}
