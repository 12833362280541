import { useCallback, useEffect, useRef, useState } from "react";
import { useServerSdk } from "../../RootNavigator/services/ServerSdk";
import { useAssistance } from "../../RootNavigator/services/Assistance";
import {
  ProjectContact,
  ProjectInfos,
  ProjectOrganization,
} from "../MyProjectScreen/ProjectInfos";
import WithHelp from "../../RootNavigator/assistance/Help/WithHelp";
import ProjectDescriptions from "../MyProjectScreen/ProjectDescriptions";
import ProjectCompliances from "../MyProjectScreen/ProjectCompliances";
import ProjectCommitments from "../MyProjectScreen/ProjectCommitments";
import ProjectAttachments from "../MyProjectScreen/ProjectAttachments";
import ProjectExpenses from "../MyProjectScreen/ProjectExpenses";
import ProjectIndicators from "../MyProjectScreen/ProjectIndicators";
import ProjectActions from "../MyProjectScreen/ProjectActions";
import { useProjectDrafting } from "../utilities";
import ContentLayout from "@hpo/client/components/layout/ContentLayout";
import { ProjectDraft } from "@hpo/client/models/Project";
import {
  assertActions,
  assertAttachments,
  assertCommitments,
  assertCompliances,
  assertDescriptions,
  assertExpenses,
  assertIndicators,
  assertProjectContact,
  assertProjectPresentation,
} from "@hpo/client/utilities/ProjectValidity";
import useRouteParam from "@hpo/client/utilities/Routing/useRouteParam";
import { getProjectLabel } from "@hpo/client/utilities/helpers/ProjectHelper";
import { myDraftRoute, myProjectRoute } from "@hpo/client/utilities/routes";
import ActionType from "@hpo/client/utilities/enums/ActionType";
import Stepper2 from "@hpo/client/utilities/Stepper2";
import Spacer from "@hpo/client/utilities/Spacer";
import Card from "@hpo/client/components/Card";
import T from "@hpo/client/components/text/Text";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import navigate from "@hpo/client/utilities/Routing/navigate";

export default function ProjectDraftScreen() {
  const server = useServerSdk();

  const draftId = useRouteParam(myDraftRoute, "draftId");
  const draft = server.suspender.await(server.getMyDraft(draftId));

  return <ProjectView project={draft} onRefresh={() => {}} />;
}

type ProjectViewProps = {
  project: ProjectDraft;
  onRefresh: () => unknown;
};

export type MyProjectBaseProps = {
  project: ProjectDraft;
  onRefresh: () => unknown;
};

function ProjectView(props: ProjectViewProps) {
  const { project } = props;

  const server = useServerSdk();
  const assistance = useAssistance();
  assistance.useChatWithInstructor();
  assistance.useVisibilityDecision(true);

  const drafting = useProjectDrafting();

  const [draft, setDraft] = useState<ProjectDraft>(project);

  const onChangeProject = useCallback((p: Partial<ProjectDraft>) => {
    setDraft((c) => ({ ...c, ...p }));
  }, []);

  const saveTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (saveTimeout.current !== null) clearTimeout(saveTimeout.current);
    saveTimeout.current = setTimeout(() => {
      void drafting.saveDraft(draft.id, draft);
    }, 500);
  }, [draft]);

  const [onEnd] = useSubmitCallback(async () => {
    const id = await server.createMyProject(draft);
    navigate(myProjectRoute.getRootUrl({ projectId: id }), { replace: true });
  }, [draft]);

  return (
    <ContentLayout title={getProjectLabel(project)} titleOneLine={false}>
      <Stepper2
        onEnd={onEnd}
        intro={
          <WithHelp text="Complétez les étapes suivantes pour soumettre votre projet." />
        }
      >
        <Stepper2.Step
          id="structure-et-referent"
          title="Structure et référent"
          concurentWith={introSteps}
          validate={() => assertProjectContact(draft)}
        >
          <WithHelp text="Vérifiez que les informations sur votre structure sont exactes. En cas d'erreur, contactez le service instructeur et expliquez les corrections à apporter." />
          <ProjectOrganization project={draft} />
          <Spacer scale={4} />
          <WithHelp text="Vous serez la personne référénte de ce dossier HPO. Renseignez vos numéros de téléphone." />
          <Card padding={20}>
            <ProjectContact project={draft} onChange={onChangeProject} />
          </Card>
        </Stepper2.Step>
        <Stepper2.Step
          id="presentation"
          title="Présentation du projet"
          concurentWith={introSteps}
          validate={() => {
            assertProjectPresentation(draft);
            assertDescriptions(draft);
          }}
        >
          <Card padding={20}>
            <ProjectInfos project={draft} onChange={onChangeProject} />
          </Card>
          <Spacer scale={2} />
          <Card padding={20}>
            <ProjectDescriptions project={draft} onChange={onChangeProject} />
          </Card>
        </Stepper2.Step>
        <Stepper2.Step
          id="conformite-et-engagements"
          title="Conformité et engagements"
          concurentWith={introSteps}
          validate={() => {
            assertCommitments(draft);
            assertCompliances(draft);
          }}
        >
          <WithHelp
            text="Lisez attentivement les propositions d'engagements et de conformité
            suivantes. Vous devrez les accepter pour pouvoir soumettre votre
            projet."
          />
          <T style="section">Conditions préalables à votre demande</T>
          <Spacer />
          <ProjectCompliances project={draft} onChange={onChangeProject} />
          <Spacer scale={2} />
          <T style="section">Engagements</T>
          <Spacer />
          <ProjectCommitments project={draft} onChange={onChangeProject} />
        </Stepper2.Step>
        <Stepper2.Step
          concurentWith={otherSteps}
          id="documents"
          title="Justificatifs"
          validate={() => assertAttachments(draft)}
        >
          <ProjectAttachments project={draft} onChange={onChangeProject} />
        </Stepper2.Step>
        <Stepper2.Step
          concurentWith={otherSteps}
          id="indicateurs"
          title="Indicateurs"
          validate={() => assertIndicators(draft)}
        >
          <ProjectIndicators
            project={draft}
            onChange={onChangeProject}
            showEvaluation
          />
        </Stepper2.Step>
        <Stepper2.Step
          concurentWith={otherSteps}
          id="depenses"
          title="Dépenses"
          validate={() => assertExpenses(draft)}
        >
          <ProjectExpenses project={draft} onChange={onChangeProject} />
        </Stepper2.Step>
        <Stepper2.Step
          concurentWith={otherSteps}
          id="actions"
          title="Plan de communication et plan de transfert"
          validate={() => {
            assertActions(draft, ActionType.Communication);
            assertActions(draft, ActionType.Transfer);
          }}
        >
          <ProjectActions
            project={draft}
            actionType={ActionType.Communication}
            onChange={onChangeProject}
          />
          <Spacer />
          <ProjectActions
            project={draft}
            actionType={ActionType.Transfer}
            onChange={onChangeProject}
          />
        </Stepper2.Step>
      </Stepper2>
    </ContentLayout>
  );
}

const introSteps = [
  "structure-et-referent",
  "presentation",
  "conformite-et-engagements",
];

const otherSteps = ["documents", "indicateurs", "depenses", "actions"];
