import { useAssistance } from "../RootNavigator/services/Assistance";
import MyDraftScreen from "./MyDraftScreen";
import MyProjectScreen from "./MyProjectScreen";
import MyProjectsScreen from "./MyProjectsScreen";
import NewDraftScreen from "./NewDraftScreen";
import { Route, Switch } from "@hpo/client/utilities/Routing";
import {
  myDraftRoute,
  myProjectRoute,
  myProjectsRoute,
  newDraftRoute,
} from "@hpo/client/utilities/routes";

export default function MyProjectsNavigator() {
  const assistance = useAssistance();
  assistance.useVisibilityDecision(true);
  assistance.useChatWithInstructor();

  return (
    <Switch>
      <Route destination={myProjectsRoute} exact component={MyProjectsScreen} />
      <Route destination={newDraftRoute} component={NewDraftScreen} />
      <Route destination={myDraftRoute} component={MyDraftScreen} />
      <Route destination={myProjectRoute} component={MyProjectScreen} />
    </Switch>
  );
}
