import { css } from "@emotion/react";
import { ForwardedRef, forwardRef, PropsWithChildren } from "react";
import Spacer from "../utilities/Spacer";
import Intersperse from "./Intersperse";

type ButtonsProps = PropsWithChildren<{ expand?: boolean }>;
function Buttons(props: ButtonsProps, ref: ForwardedRef<HTMLDivElement>) {
  const { expand = false } = props;
  const containerCss = css({
    padding: 6,
    display: "flex",
    justifyContent: "center",
  });
  return (
    <div css={containerCss} ref={ref}>
      <Intersperse between={() => <Spacer horizontal grow={expand} />}>
        {props.children}
      </Intersperse>
    </div>
  );
}

export default forwardRef<HTMLDivElement, ButtonsProps>(Buttons);
