import { useCallback, useState } from "react";
import { useChangeEffect } from "@ehynds/use-change-effect";
import { ProjectDraft } from "@hpo/client/models/Project";
import List, { ListItem } from "@hpo/client/utilities/List";
import SwitchField from "@hpo/client/utilities/fields/SwitchField";
import { ComplianceBeneficiary } from "@hpo/client/models/Compliance";

type ProjectCompliancesProps = {
  project: ProjectDraft;
  onChange?: (d: Partial<ProjectDraft>) => unknown;
};

export default function ProjectCompliances(props: ProjectCompliancesProps) {
  const { project, onChange } = props;

  const locked = onChange === undefined;

  function mustBeOn(value: boolean) {
    if (value === false) return "Vous devez respecter cette condition";
    return null;
  }

  const [compliances, setCompliances] = useState<Array<ComplianceBeneficiary>>(
    project.compliances,
  );

  const setComplianceAcceptedAt = useCallback(
    (id: string, value: boolean | null) => {
      setCompliances((current) => {
        return current.map((c) => {
          if (c.id === id)
            return { ...c, acceptedAt: value ? new Date() : null };
          else return c;
        });
      });
    },
    [],
  );

  useChangeEffect(() => {
    if (!onChange) return;
    onChange({ compliances });
  }, [compliances, onChange]);

  return (
    <List
      data={compliances}
      renderItem={(c) => (
        <ListItem
          label={c.label}
          right={
            <div css={{ width: 150, flexShrink: 0 }}>
              <SwitchField
                label="J'atteste"
                required
                value={!!c.acceptedAt}
                onChange={(value) => setComplianceAcceptedAt(c.id, value)}
                errorMessage={mustBeOn}
                readonly={locked}
              />
            </div>
          }
        />
      )}
    />
  );
}
