import {
  PropsWithChildren,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { without } from "lodash";
import { css } from "@emotion/react";
import { rgba } from "polished";
import T from "../components/text/Text";
import Icon from "../components/Icon";
import Intersperse from "../components/Intersperse";
import Clickable from "../components/Clickable";
import Divider from "../components/Divider";
import R from "./R";
import Theme from "./Theme/client";
import Spacer from "./Spacer";

class SectionsController {
  private r = new R();

  constructor(private max: number) {}

  private openeds: Array<string> = [];

  useIsOpened(id: string) {
    return this.r.useSelector(() => this.openeds.includes(id));
  }

  useHasSomethingOpened() {
    return this.r.useSelector(() => this.openeds.length > 0);
  }

  open(id: string, opened: boolean) {
    if (!opened) {
      if (this.openeds.includes(id)) {
        this.openeds = without(this.openeds, id).slice(0, this.max);
        this.r.notify();
      }
    } else {
      if (!this.openeds.includes(id)) {
        this.openeds = [id, ...this.openeds].slice(0, this.max);
        this.r.notify();
      }
    }
  }
}

const SectionsContext = createContext<SectionsController>(
  new SectionsController(0),
);

type SectionsProps = PropsWithChildren<{ max?: number }>;

export default function Sections(props: SectionsProps) {
  const { max = 1 } = props;
  const controller = useMemo(() => new SectionsController(max), [max]);

  const containerCss = css({
    borderRadius: 6,
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: "white",
    position: "relative",
    ":before": {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      content: '""',
      zIndex: -1,
      backdropFilter: "blur(20px)",
    },
  });

  return (
    <div css={containerCss}>
      <SectionsContext.Provider value={controller}>
        {props.children}
      </SectionsContext.Provider>
    </div>
  );
}

type SectionsSectionProps = PropsWithChildren<{
  id: string;
  title: string;
  subtitle?: string;
  opened?: boolean;
  right?: ReactNode;
}>;

function SectionsSection(props: SectionsSectionProps) {
  const { id, opened, right } = props;
  const controller = useContext(SectionsContext);

  const isOpened = controller.useIsOpened(id);
  const hasSomethingOpened = controller.useHasSomethingOpened();

  useEffect(() => {
    if (opened) controller.open(id, true);
  }, []);

  const [height, setHeight] = useState<number | null>(null);

  const wrapperCss = css({
    opacity: hasSomethingOpened ? (isOpened ? 1 : 0.5) : 1,
    transition: "all 300ms",
    backgroundColor: isOpened ? "white" : "transparent",
  });

  const containerCss = css({
    height: isOpened ? (height === null ? "auto" : height) : 0,
    backgroundColor: isOpened ? "white" : "transparent",
    transition: "all 300ms",
    opacity: isOpened ? 1 : 0,
    pointerEvents: isOpened ? "auto" : "none",
    paddingLeft: 10,
    paddingRight: 10,
  });

  const measureCss = css({});

  const measureRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const el = measureRef.current;
    if (el) {
      const obs = new ResizeObserver((entries) => {
        const entry = entries[0];
        setHeight(entry.contentRect.height);
      });
      obs.observe(el);
      return () => obs.unobserve(el);
    }
  }, [id]);

  return (
    <div css={wrapperCss}>
      <Divider color={isOpened ? Theme.color : "transparent"} />
      <SectionHeader
        {...props}
        openable={!!props.children}
        opened={isOpened}
        onOpenedChange={(o) => controller.open(id, o)}
        right={right}
      />
      <div css={containerCss}>
        <div css={measureCss} ref={measureRef}>
          {props.children}
          <Spacer />
        </div>
      </div>
      <Divider color={isOpened ? Theme.color : "transparent"} />
    </div>
  );
}

type SectionHeaderProps = {
  openable: boolean;
  title: string;
  subtitle?: string;
  opened: boolean;
  onOpenedChange: (opened: boolean) => unknown;
  right?: ReactNode;
};

function SectionHeader(props: SectionHeaderProps) {
  const { openable, title, subtitle, opened, onOpenedChange, right } = props;

  const containerCss = css({
    display: "flex",
    alignItems: "center",
    padding: 10,
    cursor: "pointer",
  });

  const textCss = css({
    flex: 1,
  });

  const opacity = openable ? 1 : 0;

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <Clickable
        css={containerCss}
        onClick={openable ? () => onOpenedChange(!opened) : undefined}
      >
        <Intersperse between={() => <Spacer scale={0.5} horizontal />} append>
          <Icon
            name="chevron"
            rotate={opened ? 180 : 90}
            color={rgba(opened ? Theme.color : "black", opacity)}
          />
          <div css={textCss}>
            <T>{title}</T>
            {subtitle ? (
              <T style="minor" color={rgba("black", 0.5)}>
                {subtitle}
              </T>
            ) : null}
          </div>
          <div onClick={(e) => e.stopPropagation()}>{right}</div>
        </Intersperse>
      </Clickable>
    </div>
  );
}

Sections.Section = SectionsSection;
