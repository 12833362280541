import PaymentsScreen from "./PaymentsScreen";
import { Route, Switch } from "@hpo/client/utilities/Routing";
import { paymentsRoute } from "@hpo/client/utilities/routes";

export default function PaymentsNavigator() {
  return (
    <Switch>
      <Route destination={paymentsRoute} exact component={PaymentsScreen} />
    </Switch>
  );
}
