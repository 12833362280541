type NavigateOptions = {
  replace?: boolean;
};

export default function navigate(url: string, options: NavigateOptions = {}) {
  if (options.replace) {
    history.replaceState(null, "", url);
  } else {
    history.pushState(null, "", url);
  }
}
