import { ForwardedRef, forwardRef, PropsWithChildren } from "react";
import { css } from "@emotion/react";
import Destination from "../utilities/Routing/Destination";
import { ButtonProps } from "./Button";

type OnClickProps = {
  onClick?: (e: React.MouseEvent) => unknown;
  onDoubleClick?: (e: React.MouseEvent) => unknown;
};

type TargetProps = (
  | { submit: true }
  | { href: string; target?: string }
  | { to: string }
  | { route: Destination }
  | OnClickProps
) &
  OnClickProps & {
    disabled?: boolean;
    onClicked?: (output: unknown) => unknown;
  };

export type ClickableProps = PropsWithChildren<
  TargetProps & {
    className?: string;
    disabled?: boolean;
  }
>;

export type ClickableForwaredRef =
  | HTMLButtonElement
  | HTMLAnchorElement
  | HTMLSpanElement;

function Clickable(
  props: ClickableProps,
  ref: ForwardedRef<ClickableForwaredRef>,
) {
  const { onClick, className, children, disabled, onDoubleClick } = props;

  const containerCss = css({
    display: "inline-block",
    textDecoration: "unset",
    border: "unset",
    background: "unset",
    textAlign: "unset",
    color: "inherit",
    padding: 0,
    fontFamily: "unset",
    fontSize: "unset",
  });

  if ("href" in props && props.href) {
    return (
      <a
        css={containerCss}
        className={className}
        href={props.href}
        target="_blank"
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        ref={ref as ForwardedRef<HTMLAnchorElement>}
      >
        {children}
      </a>
    );
  } else if ("to" in props && props.to) {
    return (
      <a
        css={containerCss}
        className={className + " internal"}
        href={props.to}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        data-internal
        ref={ref as ForwardedRef<HTMLAnchorElement>}
      >
        {children}
      </a>
    );
  } else if ("route" in props && props.route) {
    return (
      <a
        css={containerCss}
        className={className + " internal"}
        href={props.route.getRootUrl()}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        data-internal
        ref={ref as ForwardedRef<HTMLAnchorElement>}
      >
        {children}
      </a>
    );
  } else if ("submit" in props && props.submit) {
    return (
      <button
        css={containerCss}
        type="submit"
        disabled={disabled}
        className={className}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        ref={ref as ForwardedRef<HTMLButtonElement>}
      >
        {children}
      </button>
    );
  } else if (onClick || onDoubleClick) {
    return (
      <button
        type="button"
        css={containerCss}
        disabled={disabled}
        className={className}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        ref={ref as ForwardedRef<HTMLButtonElement>}
      >
        {children}
      </button>
    );
  } else {
    return (
      <span className={className} ref={ref as ForwardedRef<HTMLSpanElement>}>
        {children}
      </span>
    );
  }
}

export function isClickableReactive(props: ClickableProps) {
  return !isDisabled(props);
}

export function isDisabled(props: ClickableProps) {
  if (props.disabled === true) return true;
  if ("submit" in props && props.submit) return false;
  else if ("onClick" in props && props.onClick) return false;
  else if ("href" in props && props.href.length > 0) return false;
  else if ("to" in props && props.to.length > 0) return false;
  return true;
}

export default forwardRef<ClickableForwaredRef, ButtonProps>(Clickable);
