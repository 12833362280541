import { Fragment, useCallback, useState } from "react";
import { nanoid } from "nanoid";
import { useChangeEffect } from "@ehynds/use-change-effect";
import ActionSideBar from "../components/ActionSideBar";
import Spacer from "@hpo/client/utilities/Spacer";
import { ProjectDraft } from "@hpo/client/models/Project";
import ActionType from "@hpo/client/utilities/enums/ActionType";
import DataTable, { DataTableColumn } from "@hpo/client/components/DataTable";
import { Action, ActionPayload } from "@hpo/client/models/Action";
import Units from "@hpo/client/utilities/Units";
import T from "@hpo/client/components/text/Text";

type ProjectActionsProps = {
  project: ProjectDraft;
  actionType: ActionType;
  onChange?: (d: Partial<ProjectDraft>) => unknown;
};

export default function ProjectActions(props: ProjectActionsProps) {
  const { project, onChange, actionType } = props;

  const locked = onChange === undefined;

  const [open, setOpen] = useState<string | "new" | null>(null);

  const [actions, setActions] = useState(() => {
    return project.actions.filter((action) => action.type === actionType);
  });

  const insertAction = useCallback(async (action: ActionPayload) => {
    setActions((current) => [...current, { id: nanoid(), ...action }]);
  }, []);

  const updateAction = useCallback(
    async (id: string, action: ActionPayload) => {
      setActions((current) =>
        current.map((c) => (c.id === id ? { id, ...action } : c)),
      );
    },
    [],
  );

  const deleteAction = useCallback(async (action: Action) => {
    setActions((current) => current.filter((c) => c.id !== action.id));
  }, []);

  useChangeEffect(() => {
    if (!onChange) return;
    const otherActions = project.actions.filter(
      (action) => action.type !== actionType,
    );
    onChange({ actions: [...otherActions, ...actions] });
  }, [actions]);

  return (
    <Fragment>
      <T style="section">
        Plan de {actionType === "communication" ? "communication" : "transfert"}
      </T>
      <Spacer />
      <DataTable
        readonly={locked}
        columns={columns}
        value={actions}
        actions={{
          onAddClick: () => setOpen("new"),
          onEditClick: (action: Action) => setOpen(action.id),
          onDeleteClick: deleteAction,
        }}
      />
      {actions.map((action) => (
        <ActionSideBar
          key={action.id}
          project={project}
          action={action}
          actionType={actionType}
          visible={open === action.id}
          onDone={(p) => updateAction(action.id, p)}
          onHide={() => setOpen(null)}
        />
      ))}
      <ActionSideBar
        project={project}
        action={null}
        actionType={actionType}
        visible={open === "new"}
        onDone={insertAction}
        onHide={() => setOpen(null)}
      />
    </Fragment>
  );
}

const columns: Array<DataTableColumn<Action>> = [
  { field: "label", header: "Libellé" },
  { field: "goal", header: "Objectif" },
  {
    header: "Coût",
    body: (action: Action) => {
      return Units.euro.display(action.cost);
    },
  },
  { field: "means", header: "Moyens" },
  { field: "planning", header: "Planning" },
];
