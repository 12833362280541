import { Fragment, useCallback, useState } from "react";
import { useServerSdk } from "../../../RootNavigator/services/ServerSdk";
import ProjectAnnexHeader from "../ProjectAnnexHeader";
import ProjectInquiryStatus from "../inquiry/ProjectInquiryStatus";
import Button from "@hpo/client/components/Button";
import DataTable, { DataTableColumn } from "@hpo/client/components/DataTable";
import Toasting from "@hpo/client/components/Toasting";
import { Analysis } from "@hpo/client/models/Analysis";
import { Annex, ProjectInstructor } from "@hpo/client/models/Project";
import Spacer from "@hpo/client/utilities/Spacer";
import Units from "@hpo/client/utilities/Units";
import NumberField from "@hpo/client/utilities/fields/NumberField";
import { canProjectHaveInquiry } from "@hpo/client/utilities/helpers/ProjectHelper";

type ProjectTabFundingProps = {
  project: ProjectInstructor;
  annex: Annex;
  onReload: () => unknown;
};

type FundingData = {
  id: string;
  actionLabel: string;
  expenseLabel: string;
  annualCost: number;
  elligibleHelp: number;
  askedSubsidy: number;
  remainingCharges: number;
  proposal: number | null;
  analysis: Analysis;
};

export default function ProjectTabFunding(props: ProjectTabFundingProps) {
  const { project, annex, onReload } = props;

  const serverSdk = useServerSdk();

  const readOnly = !canProjectHaveInquiry(project);

  const [amountsProposed, setAmountProposed] = useState<
    Record<string, number | null>
  >(() => {
    const amountsProposed: Record<string, number | null> = {};
    project.proposals.forEach((expense) => {
      amountsProposed[expense.id] = expense.proposal;
    });

    return amountsProposed;
  });

  const onAmountProposedChange = (value: number | null, expense: string) => {
    const correctValue = value === null ? null : value < 0 ? 0 : value;

    setAmountProposed((amountsProposed) => {
      const newAmountsProposed = structuredClone(amountsProposed);
      newAmountsProposed[expense] = correctValue;
      return newAmountsProposed;
    });
  };

  const saveAnalysis = useCallback(
    async (resourceId: string, fields: Analysis) => {
      await serverSdk.saveAnalysis(resourceId, fields);
      await onReload();
    },
    [onReload],
  );

  const columns: DataTableColumn<FundingData>[] = [
    {
      field: "actionLabel",
      header: "Intitulé",
    },
    {
      field: "expenseLabel",
      header: "Libellés des dépenses",
    },
    {
      header: "Cout total prévisionnel / an",
      body: (e) => {
        return Units.euro.display(e.annualCost);
      },
    },
    {
      header: "Subvention départementale HPO demandée /an",
      body: (e) => {
        return Units.euro.display(e.askedSubsidy);
      },
    },
    {
      header: "Reste à charge au porteur de projet /an",
      body: (e) => {
        return Units.euro.display(e.remainingCharges);
      },
    },
    {
      header: "Subvention proposée par le service instructeur",
      body: (e) => {
        return (
          <NumberField
            required
            allowZero
            value={amountsProposed[e.id]}
            onChange={(value) => onAmountProposedChange(value, e.id)}
            min={0}
            unit="euro"
          />
        );
      },
    },
    {
      body: (e) => (
        <ProjectInquiryStatus
          analysis={e.analysis}
          onChange={(f) => saveAnalysis(e.id, f)}
          readonly={readOnly}
        />
      ),
    },
  ];

  const submitAmountsProposed = async () => {
    try {
      const promises = Object.entries(amountsProposed).map(([id, amount]) => {
        const expense = project.expenses.find((e) => e.id === id);

        if (!expense) {
          throw new Error("Impossible de trouver la dépense associée");
        }

        return serverSdk.updateExpenseAmountProposed(id, expense.type, amount);
      });

      await Promise.all(promises);

      Toasting.success("Succès", "Les montants ont bien été enregistrés");

      await onReload();
    } catch (error) {
      Toasting.fromError(error);
    }
  };

  return (
    <Fragment>
      <ProjectAnnexHeader
        title="Plan de financement"
        annex={annex}
        onReload={onReload}
        project={project}
      />
      <Spacer />
      <DataTable value={project.proposals} columns={columns} />
      <Spacer size={4} />
      <Button
        label="Valider les montants proposés"
        onClick={submitAmountsProposed}
        disabled={readOnly}
      />
    </Fragment>
  );
}
