import { ComponentType, Fragment, PropsWithChildren, useContext } from "react";
import CatchError from "../CatchError";
import Destination from "./Destination";
import { SwitchContext } from "./Switch";
import useLocation from "./useLocation";

type RouteProps<
  Params extends Record<string, string> | void,
  ParentsParams extends Record<string, string> | void,
> = PropsWithChildren<{
  destination: Destination<Params, ParentsParams>;
  component: ComponentType;
  exact?: boolean;
}>;

export default function Route<
  Params extends Record<string, string> | void,
  ParentsParams extends Record<string, string> | void,
>(props: RouteProps<Params, ParentsParams>) {
  const { destination, component: Component, exact = false } = props;
  const location = useLocation();
  const switchContext = useContext(SwitchContext);
  const match = exact
    ? destination.matchesRootUrl(location)
    : destination.isParentForRoorUrl(location);
  if (!match) return <Fragment />;
  if (switchContext !== null) {
    if (switchContext.rendered === true) return;
    switchContext.rendered = true;
  }
  return (
    <CatchError>
      <Component />
    </CatchError>
  );
}
