import { useState } from "react";
import { useWhoAmI } from "../RootNavigator/services/WhoAmI";
import { useServerSdk } from "../RootNavigator/services/ServerSdk";
import Button from "@hpo/client/components/Button";
import ContentLayout from "@hpo/client/components/layout/ContentLayout";
import { OrganizationSummary } from "@hpo/client/models/Organization";
import { UserAccess } from "@hpo/client/models/User";
import ErrorToast from "@hpo/client/utilities/ErrorToast";
import Spacer from "@hpo/client/utilities/Spacer";
import MessageException from "@hpo/client/utilities/errors/MessageException";
import FieldsForm from "@hpo/client/utilities/fields/FieldsForm";
import TextField from "@hpo/client/utilities/fields/TextField";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import ErrorView from "@hpo/client/utilities/ErrorView";
import useData from "@hpo/client/utilities/useData";
import { ProgramAvailability } from "@hpo/client/models/Program";
import Role from "@hpo/client/utilities/enums/Role";
import Buttons from "@hpo/client/components/Buttons";
import RadioField from "@hpo/client/utilities/fields/RadioField";
import navigate from "@hpo/client/utilities/Routing/navigate";
import { myDraftRoute } from "@hpo/client/utilities/routes";

export default function NewProjectScreen() {
  const whoami = useWhoAmI();
  const user = whoami.useUser();

  if (!user.roles.includes(Role.Benficiary))
    return (
      <ErrorView
        error={
          new MessageException(
            "Vous devez être bénéficiaire pour créer un projet",
            null,
          )
        }
      />
    );

  if (!user.organization)
    return (
      <ErrorView
        error={
          new MessageException(
            "Vous devez créer un bénéficiaire pour créer un projet.",
            null,
          )
        }
      />
    );

  return <NewProjectScreenReady user={user} organization={user.organization} />;
}

type NewProjectScreenReadyProps = {
  user: UserAccess;
  organization: OrganizationSummary;
};

function NewProjectScreenReady(props: NewProjectScreenReadyProps) {
  const { organization } = props;

  const server = useServerSdk();
  // const drafting = useProjectDrafting();

  const [type, setType] = useState<ProgramAvailability | null>(null);
  const [label, setLabel] = useState<string | null>(null);

  const [onSubmit, submission] = useSubmitCallback(async () => {
    if (!label)
      throw new MessageException("Veuillez définir un libellé au projet", null);
    if (!type)
      throw new MessageException("Sélectionnez un type de projet", null);

    const id = await server.createMyDraft({ type: type.key, label });

    navigate(myDraftRoute.getRootUrl({ draftId: id }), { replace: true });
  }, [label, type, organization]);

  const [acceptedProjectTypes] = useData(() => server.getVisiblePrograms(), []);

  return (
    <ContentLayout title="Nouveau projet">
      <ErrorToast error={submission.error} />
      <FieldsForm onSubmit={onSubmit}>
        <RadioField
          id="type"
          label="Appel à projets"
          value={type}
          options={acceptedProjectTypes}
          renderOption={(type) => ({ label: type.label })}
          keyExtractor={(d) => d.key}
          onChange={setType}
        />
        <Spacer />
        <TextField
          required
          value={label}
          label="Libellé du projet"
          onChange={setLabel}
          maxLength={70}
          help="70 caractères maximum"
        />
        <Spacer />
        <Buttons>
          <Button label="Créer le projet" submit loading={submission.running} />
        </Buttons>
        <Spacer />
      </FieldsForm>
    </ContentLayout>
  );
}
