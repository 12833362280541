import { create } from "superstruct";
import { useMemo } from "react";
import { useServerSdk } from "../RootNavigator/services/ServerSdk";
import { ProjectDraft, ProjectDraftSchema } from "@hpo/client/models/Project";

export function isProjectRough(input: unknown): input is ProjectDraft {
  try {
    create(input, ProjectDraftSchema);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

// Drafting

type DraftingData<D> = {
  read(id: string): Promise<D>;
  write(id: string, data: D): Promise<void>;
};

class DraftDoesNotExist extends Error {}

export function useDrafting<Input>(
  checkFn: (i: unknown) => i is Input,
  data: DraftingData<Input>,
) {
  function assertDraftInValid(input: unknown): asserts input is Input {
    if (!checkFn(input)) {
      throw new InvalidDraft();
    }
  }

  async function createDraft(id: string, draft: Input) {
    try {
      assertDraftInValid(draft);
      await data.read(id);
      throw new DraftAlreadyExist();
    } catch (e) {
      if (e instanceof DraftDoesNotExist) {
        await data.write(id, draft);
      }
    }
  }

  async function saveDraft(id: string, draft: Input) {
    assertDraftInValid(draft);
    await data.write(id, draft);
  }

  return { createDraft, saveDraft };
}

class InvalidDraft extends Error {}
class DraftAlreadyExist extends Error {}

// Draft storage

export function useProjectDrafting() {
  const server = useServerSdk();

  const data = useMemo<DraftingData<ProjectDraft>>(() => {
    return {
      read: (id: string) =>
        server.suspender.execute(server.getMyDraft(id), false),
      write: (id: string, data: ProjectDraft) => server.updateMyDraft(id, data),
    };
  }, []);

  const drafting = useDrafting(isProjectRough, data);
  return drafting;
}

// LS storage

export const localStorageDraftingData: DraftingData<unknown> = {
  read: async (id: string) => {
    const data = localStorage.getItem(id);
    if (data === null) throw new DraftDoesNotExist();
    return JSON.parse(data);
  },
  write: async (id: string, data: unknown) => {
    localStorage.setItem(id, JSON.stringify(data));
  },
};
