type Caster<Input, Output, Context> = {
  is: (err: unknown) => boolean;
  cast: (err: Input, context: Context) => Output;
};

/**
 * ErrorCaster allow to cast anything to a given error type
 */
export default class ErrorCaster<Output, Context = void> {
  private casters: Array<Caster<any, Output, Context>> = [];

  constructor(
    private isAlreadyOutputCheck: (err: unknown) => boolean,
    private def: (e: unknown, context: Context) => Output,
  ) {}

  register<Input>(
    is: (err: unknown) => err is Input,
    cast: (err: Input, context: Context) => Output,
  ): void;
  register(
    is: (err: unknown) => boolean,
    cast: (err: unknown, context: Context) => Output,
  ): void;
  register<Input = unknown>(
    is: (err: unknown) => err is Input,
    cast: (err: Input, context: Context) => Output,
  ) {
    this.casters.push({ is, cast });
  }

  registerClass<T>(c: Class<T>, cast: (err: T, context: Context) => Output) {
    this.casters.push({ is: (err: unknown) => err instanceof c, cast });
  }

  cast(err: unknown, context: Context): Output {
    return this.tryToCast(err, context) || this.def(err, context);
  }

  tryToCast(err: unknown, context: Context): Output | null {
    // Search in stack
    const stack = this.getErrorsStack(err);
    const inStack = stack.find((e) => this.isAlreadyOutputCheck(e));
    if (inStack !== undefined) return inStack as Output;
    // Cast
    const casted = stack.reduce<Output | null>((acc, e) => {
      if (acc !== null) return acc;
      return this.applyCasters(e, context);
    }, null);
    return casted;
  }

  private applyCasters(err: unknown, context: Context): Output | null {
    const match = this.casters.find((c) => c.is(err));
    if (match) return match.cast(err, context);
    return null;
  }

  getErrorsStack(error: unknown, previous: Array<Error> = []): Array<unknown> {
    const output: Array<Error> = [...previous];
    if (error instanceof Error) {
      output.push(error);
      if (error.cause) return this.getErrorsStack(error.cause, output);
      else return output;
    } else {
      return output;
    }
  }
}

type Class<T> = new (...args: any[]) => T;
