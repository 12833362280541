import React, { ReactNode, useCallback, useMemo, useState } from "react";
import Await, { usePromiseState } from "../Await";
import List, { ListItem, ListItemProps } from "../List";
import FieldProps from "./FieldProps";
import FieldWrapper from "./components/FieldWrapper";
import { useFieldId, useFieldValidity } from "./utilities";
import FieldDropdown from "./components/FieldDropdown";
import FieldInput from "./components/FieldInput";
import messageExceptionCaster from "@hpo/client/message-exception-caster";

type DropDownFieldProps<D> = FieldProps<D | null> & {
  options: Array<D> | Promise<Array<D>>;
  renderOption: (item: D) => ListItemProps;
  renderNullOption?: () => ListItemProps;
  keyExtractor: (d: D) => string;
};

export default function RadioField<D>(props: DropDownFieldProps<D>) {
  const {
    value,
    onChange,
    required = false,
    errorMessage,
    renderOption,
    keyExtractor,
    renderNullOption,
    disabled,
    readonly,
  } = props;

  const id = useFieldId(props.id);

  const optionsState = usePromiseState(props.options);

  const isActive = !disabled && !readonly;

  const validity = useFieldValidity(
    value,
    required,
    (v) => v === undefined || v === null,
    (v) => {
      if (optionsState.state === "pending") {
        return "Veuillez patienter...";
      } else if (optionsState.state === "rejected") {
        return messageExceptionCaster.cast(optionsState.error).message;
      } else {
        const options = optionsState.value;
        if (v && !options.map((o) => keyExtractor(o)).includes(keyExtractor(v)))
          return "Sélectionnez une autre valeur";
      }
      if (errorMessage) return errorMessage(v);
      return null;
    },
  );

  const onSelect = useCallback(
    (v: D | null) => {
      if (onChange) onChange(v);
      setFinalized(true);
      setDropdown(false);
    },
    [onChange],
  );

  const placeholder = useMemo(() => {
    return renderNullOption ? renderNullOption().label : undefined;
  }, [renderNullOption]);

  const [dropdown, setDropdown] = useState<boolean>(false);

  const [finalized, setFinalized] = useState(false);

  let inputNode: ReactNode;
  if (optionsState.state === "pending") {
    inputNode = <FieldInput value="Chargement en cours..." readOnly />;
  } else if (optionsState.state === "rejected") {
    inputNode = (
      <FieldInput
        value={messageExceptionCaster.cast(optionsState.error).message}
        readOnly
      />
    );
  } else {
    if (isActive) {
      inputNode = (
        <List
          noFrame
          data={optionsState.value}
          renderItem={(i) => {
            const isSelected =
              value !== null ? keyExtractor(value) === keyExtractor(i) : false;
            return (
              <ListItem
                {...renderOption(i)}
                icon={isSelected ? "radio-on" : "radio-off"}
                onClick={() => (isSelected ? onSelect(null) : onSelect(i))}
              />
            );
          }}
        />
      );
    } else {
      inputNode = (
        <FieldInput
          value={value !== null ? renderOption(value).label : ""}
          placeholder={placeholder}
          readOnly={readonly}
          disabled={disabled}
        />
      );
    }
  }

  return (
    <FieldWrapper {...props} id={id} validity={validity} finalized={finalized}>
      {inputNode}
      <FieldDropdown visible={dropdown} onHide={() => setDropdown(false)}>
        <Await promise={props.options}>
          {(options) => {
            return (
              <List
                data={renderNullOption ? [...options, null] : options}
                renderItem={(i) => {
                  if (i === null) {
                    if (renderNullOption) {
                      return (
                        <ListItem
                          {...renderNullOption()}
                          onClick={() => onSelect(null)}
                        />
                      );
                    }
                  } else {
                    const itemProps = renderOption(i);
                    return (
                      <ListItem {...itemProps} onClick={() => onSelect(i)} />
                    );
                  }
                }}
              />
            );
          }}
        </Await>
      </FieldDropdown>
    </FieldWrapper>
  );
}
