import { css } from "@emotion/react";
import { flatten } from "lodash";
import { PropsWithChildren } from "react";
import { rgba } from "polished";
import Theme from "@hpo/client/utilities/Theme/client";

export type HelpZoneProps = PropsWithChildren<{
  color?: string;
}>;

export default function HelpZone(props: HelpZoneProps) {
  const defaultColor = Theme.color;
  const color = props.color || defaultColor;

  const containerCss = css({
    display: "flex",
    paddingRight: 20,
    textAlign: "left",
    color: "black",
    borderRadius: 10,
    overflow: "hidden",
  });

  const lineCss = css({
    width: 16,
    flexShrink: 0,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    alignItems: "flex-end",
    position: "relative",
    zIndex: 1,
  });

  const borderCss = css({
    width: 4,
    background: color,
    position: "absolute",
    top: 30,
    right: 0,
    bottom: 0,
  });

  const contentCss = css({
    flex: 1,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10 + 4,
    paddingRight: 10,
    display: "flex",
    flexDirection: "column",
    backdropFilter: "blur(10px)",
    marginLeft: -4,
    background: rgba("white", 0.6),
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    overflow: "hidden",
  });

  return (
    <div css={containerCss}>
      <div css={lineCss}>
        <svg width="16px" height="40px" viewBox="0 0 16 40">
          <polyline
            id="Path"
            fill="none"
            stroke={color}
            strokeWidth="4"
            points={flatten([
              [14, 0],
              [14, 14],
              [10, 22],
              [14, 30],
              [14, 40],
            ]).join(" ")}
          ></polyline>
        </svg>
        <div css={borderCss} />
      </div>
      <div css={contentCss}>{props.children}</div>
    </div>
  );
}
