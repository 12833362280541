import { useCallback, useEffect, useState } from "react";
import { css } from "@emotion/react";
import FieldProps from "./FieldProps";
import FieldWrapper from "./components/FieldWrapper";
import { useFieldId, useFieldValidity } from "./utilities";
import FieldInput from "./components/FieldInput";
import { Address } from "@hpo/client/models/Address";
import Divider from "@hpo/client/components/Divider";

type AddressFieldProps = FieldProps<Address | null>;

export default function AddressField(props: AddressFieldProps) {
  const {
    value,
    onChange,
    disabled = false,
    readonly = false,
    required = false,
    errorMessage,
  } = props;

  const id = useFieldId(props.id);
  const validity = useFieldValidity(
    value,
    required,
    () =>
      value === null ||
      value === undefined ||
      !value.town ||
      !value.street ||
      !value.postalCode,
    errorMessage,
  );

  const onStreetChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (onChange) {
        const newValue = value || { street: "", postalCode: "", town: "" };
        newValue.street = e.target.value;
        onChange(newValue);
      }
    },
    [value, onChange],
  );

  const onPostalCodeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (onChange) {
        const newValue = value || { street: "", postalCode: "", town: "" };
        newValue.postalCode = e.target.value;
        onChange(newValue);
      }
    },
    [value, onChange],
  );

  const onTownChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (onChange) {
        const newValue = value || { street: "", postalCode: "", town: "" };
        newValue.town = e.target.value;
        onChange(newValue);
      }
    },
    [value, onChange],
  );

  const wrapperCss = css({
    display: "flex",
    flexDirection: "column",
  });

  const townAndPostalCss = css({
    display: "flex",
  });

  const postalCss = css({
    width: 100,
  });

  const townCss = css({
    flex: 1,
  });

  const [addressBlurred, setAddressBlurred] = useState(false);
  const [codeBlurred, setCodeBlurred] = useState(false);
  const [townBlurred, setTownBlurred] = useState(false);

  const [finalized, setFinalized] = useState(false);

  useEffect(() => {
    if (addressBlurred && codeBlurred && townBlurred) {
      setFinalized(true);
    }
  }, [addressBlurred, codeBlurred, townBlurred]);

  return (
    <FieldWrapper {...props} id={id} validity={validity} finalized={finalized}>
      <div css={wrapperCss}>
        <FieldInput
          type={"text"}
          value={value ? value.street : ""}
          onChange={onStreetChange}
          placeholder={"Rue et numéro de rue"}
          disabled={disabled}
          readOnly={readonly}
          onBlur={() => setAddressBlurred(true)}
        />
        <Divider />
        <div css={townAndPostalCss}>
          <div css={postalCss}>
            <FieldInput
              type={"text"}
              value={value ? value.postalCode : ""}
              onChange={onPostalCodeChange}
              placeholder={"Code p."}
              disabled={disabled}
              readOnly={readonly}
              onBlur={() => setCodeBlurred(true)}
            />
          </div>
          <Divider vertical />
          <div css={townCss}>
            <FieldInput
              type={"text"}
              value={value ? value.town : ""}
              onChange={onTownChange}
              placeholder={"Ville"}
              disabled={disabled}
              readOnly={readonly}
              onBlur={() => setTownBlurred(true)}
            />
          </div>
        </div>
      </div>
    </FieldWrapper>
  );
}
