import { isObject } from "lodash";

export default class Destination<
  Params extends Record<string, string> | void = void,
  ParentsParams extends Record<string, string> | void = void,
> {
  private parts: Array<string> = [];

  constructor(
    private path: string,
    private parent?: Destination<
      Record<string, string> | void,
      Record<string, string> | void
    >,
  ) {
    this.parts = split(path);
  }

  // Build URL

  getUrl(params: Params): string {
    return stringify(this.getPath(), isObject(params) ? params : {});
  }

  getRootUrl(params: MergeParams<Params, ParentsParams>): string {
    return stringify(this.getRootPath(), isObject(params) ? params : {});
  }

  // Path

  getPath() {
    return this.path;
  }

  getRootPath(): string {
    if (this.parent) return this.parent.getRootPath() + this.getPath();
    return this.path;
  }

  getPathParts(): Array<string> {
    return this.parts;
  }

  getRootPathParts(): Array<string> {
    return [...(this.parent ? this.parent.getPathParts() : []), ...this.parts];
  }

  // Matching

  matchesRootUrl(url: string) {
    return doesPathMatchUrl(this.getRootPath(), url);
  }

  extractParams(url: string): MergeParams<Params, ParentsParams> {
    return parse(this.getRootPath(), url) as MergeParams<Params, ParentsParams>;
  }

  isParentForRoorUrl(url: string) {
    if (this.getRootPath() === "/") return true;
    const urlParts = split(url);
    const found = urlParts.find((p, i) => {
      const partialUrl = `/${urlParts.slice(0, i + 1).join("/")}`;
      return doesPathMatchUrl(this.getRootPath(), partialUrl);
    });
    return found !== undefined;
  }

  child<ChildParams extends Record<string, string> | void = void>(
    path: string,
  ) {
    return new Destination<ChildParams, MergeParams<Params, ParentsParams>>(
      path,
      this,
    );
  }

  // Parts
}

function doesPathMatchUrl(path: string, url: string) {
  try {
    parse(path, url);
    return true;
  } catch (e) {
    if (e instanceof UrlDoesNotMatchPath) return false;
    throw e;
  }
}

function split(input: string) {
  const match = /(\/[A-z0-9-_]?)+/.exec(input);
  if (match === null) throw new Error(`Inavlid input : ${input}`);
  return input.split("/").slice(1);
}

function stringify(path: string, params: Record<string, string> = {}) {
  const parts = split(path);
  const urlParts = parts.map((p) => {
    if (p.startsWith(":")) {
      const paramName = p.slice(1);
      if (!(paramName in params)) throw new Error(`Missing param ${paramName}`);
      return params[paramName];
    } else {
      return p;
    }
  });
  return urlParts.map((p) => `/${p}`).join("");
}

function parse(path: string, url: string) {
  const pathParts = split(path);
  const urlParts = split(url);
  if (pathParts.length !== urlParts.length) {
    throw new UrlDoesNotMatchPath();
  }
  const params = pathParts.reduce(
    (acc, part, i) => {
      const urlPart = urlParts[i];
      if (part.startsWith(":")) {
        const paramName = part.slice(1);
        return { ...acc, [paramName]: urlPart };
      } else if (part !== urlPart) {
        throw new UrlDoesNotMatchPath();
      } else {
        return acc;
      }
    },
    {} as Record<string, string>,
  );
  return params;
}

export type DestinationParams<R extends Destination<any, any>> =
  R extends Destination<infer Params, infer ParentsParams>
    ? MergeParams<Params, ParentsParams>
    : unknown;

export type MergeParams<
  Params extends Record<string, string> | void = void,
  ParentsParams extends Record<string, string> | void = void,
> =
  Params extends Record<string, string>
    ? ParentsParams extends Record<string, string>
      ? Params & ParentsParams
      : Params
    : ParentsParams;

export class UrlDoesNotMatchPath extends Error {}
