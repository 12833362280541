import React, { Fragment, useEffect } from "react";
import Toasting from "../components/Toasting";
import getErrorMessage from "./errors/getErrorMessage.ts";

type ErrorToastProps = {
  error: unknown;
};

export default function ErrorToast(props: ErrorToastProps) {
  const { error } = props;
  useEffect(() => {
    if (!error) return;
    console.error("Erreur : ", error, getErrorMessage(error));
    Toasting.fromError(error);
  }, [error]);
  return <Fragment />;
}
