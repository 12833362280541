import { createContext, PropsWithChildren, useMemo } from "react";
import { useLocation } from ".";

export default function Switch(props: PropsWithChildren) {
  const { children } = props;
  useLocation();
  const ref = useMemo<{ rendered: boolean }>(() => ({ rendered: false }), []);
  ref.rendered = false;
  return (
    <SwitchContext.Provider value={ref}>{children}</SwitchContext.Provider>
  );
}

export const SwitchContext = createContext<{ rendered: boolean } | null>(null);
