import dayjs from "dayjs";
import { sumBy } from "lodash";
import { Fragment, useCallback, useState } from "react";
import WithHelp from "../../RootNavigator/assistance/Help/WithHelp";
import { PaymentFormProps } from ".";
import T from "@hpo/client/components/text/Text";

import Columns from "@hpo/client/utilities/Columns";

import Spacer from "@hpo/client/utilities/Spacer";
import Stepper from "@hpo/client/utilities/Stepper2";
import TextField from "@hpo/client/utilities/fields/TextField";
import NumberField from "@hpo/client/utilities/fields/NumberField";
import DateField from "@hpo/client/utilities/fields/DateField";
import AddressField from "@hpo/client/utilities/fields/AddressField";
import Tabs from "@hpo/client/utilities/Tabs";
import Units from "@hpo/client/utilities/Units";
import MessageException from "@hpo/client/utilities/errors/MessageException";

export default function SummaryStep(props: PaymentFormProps) {
  const { payment } = props;
  const amountOnYear = sumBy(payment.convention.fundings, (f) => f.amount);
  const [submitted, setSubmitted] = useState(false);

  const onValidateStep = useCallback(
    (userSubmitted: boolean) => {
      if (userSubmitted) {
        setSubmitted(true);
      } else {
        if (!submitted)
          throw new MessageException(
            "Relisez et validez les informations",
            null,
          );
      }
    },
    [submitted],
  );

  return (
    <Stepper.Step
      id="summary"
      title="Vérifiez les informations relatives à la convention et à la subvention"
      details="Vérifiez le numero de la convention, le montant de la subvention et les autres informations liées au paiement."
      validate={onValidateStep}
    >
      <T style="subtitle">Convention</T>
      <Spacer />
      <Columns>
        <TextField
          readonly
          label="Convention"
          value={`Convention n°${payment.convention.reference} en date du ${dayjs(payment.convention.date).format("LL")}`}
        />
        <NumberField
          readonly
          value={amountOnYear}
          unit="euro"
          label="Montant annuel accordé"
        />
      </Columns>
      <Spacer />
      <T style="subtitle">Paiement</T>
      <Spacer />
      {payment.stockAmount > 0 ? (
        <Columns columns={3}>
          <NumberField
            readonly
            label="Montant accordé"
            unit="euro"
            value={payment.grantedAmount}
          />
          <NumberField
            readonly
            label="Report"
            unit="euro"
            value={payment.stockAmount}
          />
          <NumberField
            readonly
            label="Montant disponible"
            unit="euro"
            value={payment.availableAmount}
            placeholder={
              payment.maximalAdvance !== null
                ? `Dont ${Units.euro.display(payment.maximalAdvance)} payés par avance`
                : undefined
            }
          />
        </Columns>
      ) : (
        <NumberField
          readonly
          label="Montant disponible"
          unit="euro"
          value={payment.availableAmount}
        />
      )}
      <WithHelp
        title="Date du comité technique de suivi"
        text={
          <Fragment>
            <Spacer />
            <T>
              Lors de l’instruction de votre demande de paiement, nous prendrons
              contact avec vous pour caler les dates du comité technique de
              suivi.
            </T>
          </Fragment>
        }
      />
      <DateField
        readonly
        label="Date du comité technique de suivi"
        value={payment.committeeDate}
        placeholder="Inconnue"
      />
      <Spacer />
      <T style="subtitle">Bénéficiaire</T>
      <Spacer />
      <Tabs>
        {payment.convention.organizations.map((o) => (
          <Tabs.Tab key={o.id} id={o.id} title={o.businessName}>
            <Columns>
              <TextField readonly label="Statut juridique" value={o.type} />
              <TextField readonly label="Numéro de SIREN" value={o.legalId} />
              <TextField
                readonly
                label="Raison sociale"
                value={o.businessName}
              />
            </Columns>
            <Spacer />
            <Columns>
              <TextField
                readonly
                label="Représentant légal"
                value={o.legalRepresentativeName}
              />
              <TextField
                readonly
                label="Fonction"
                value={o.legalRepresentativePosition}
              />
            </Columns>
            <Spacer />
            <AddressField readonly label="Adresse" value={o.address} />
            <Spacer />
          </Tabs.Tab>
        ))}
      </Tabs>
    </Stepper.Step>
  );
}
