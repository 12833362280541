import { CSSProperties, Fragment, ReactNode } from "react";
import Table from "../navigators/ConventionNavigator/PaymentScreen/Table";
import Button from "./Button";
import Buttons from "./Buttons";

type PickReactNode<T extends Record<string, unknown>> = {
  [K in keyof T]: T[K] extends string | number | boolean ? K : never;
};

export type DataTableColumn<T extends Record<string, unknown>> = {
  header?: string;
  footer?: (lines: Array<T>) => ReactNode;
  style?: CSSProperties;
} & (
  | { field: keyof PickReactNode<T>; body?: never }
  | { body: (a: T) => ReactNode; field?: never }
);

export type DataTableActions<T> = {
  onAddClick?: () => unknown;
  onEditClick?: (e: T) => unknown;
  onDeleteClick?: (e: T) => unknown;
  editLoading?: boolean;
  deleteLoading?: boolean;
  editDisabled?: boolean;
  deleteDisabled?: boolean;
};

export type DataTableProps<T extends Record<string, unknown>> = {
  value: Array<T>;
  columns: DataTableColumn<T>[];
  readonly?: boolean;
  actions?: DataTableActions<T>;
};

export default function DataTable<T extends Record<string, unknown>>(
  props: DataTableProps<T>,
) {
  const { columns, value, readonly = false, actions } = props;

  const showActionsColumns =
    !readonly && actions && (actions.onEditClick || actions.onDeleteClick);

  const showFooterLine = columns.some((c) => c.footer);

  return (
    <Table>
      <thead>
        <tr>
          {columns.map((c, i) => (
            <th key={i}>{c.header}</th>
          ))}
          {showActionsColumns ? <th>Actions</th> : null}
        </tr>
      </thead>
      <tbody>
        {value.map((v, i) => {
          return (
            <tr key={i}>
              {columns.map((c, j) => {
                const field = "field" in c && c.field ? c.field : null;
                const body = "body" in c && c.body ? c.body : null;
                if (field) {
                  return (
                    <td key={j}>
                      <Fragment>{v[field] as ReactNode}</Fragment>
                    </td>
                  );
                } else if (body) {
                  return <td key={j}>{body(v)}</td>;
                } else {
                  return null;
                }
              })}
              {showActionsColumns ? (
                <td>
                  <DataTableActions data={v} actions={actions} />
                </td>
              ) : null}
            </tr>
          );
        })}
        {showActionsColumns && actions && actions.onAddClick ? (
          <tr>
            <td colSpan={columns.length + 1}>
              <Buttons>
                <Button key="add" icon="plus" onClick={actions.onAddClick} />
              </Buttons>
            </td>
          </tr>
        ) : (
          <Fragment />
        )}
      </tbody>
      {showFooterLine ? (
        <tfoot>
          <tr>
            {columns.map((c, i) => (
              <th key={i}>{c.footer ? c.footer(value) : null}</th>
            ))}
            {showActionsColumns ? <th></th> : null}
          </tr>
        </tfoot>
      ) : null}
    </Table>
  );
}

type DataTableActionsProps<T extends Record<string, unknown>> = {
  data: T;
  actions: DataTableActions<T>;
};

function DataTableActions<T extends Record<string, unknown>>(
  props: DataTableActionsProps<T>,
) {
  const { data, actions } = props;
  const {
    onEditClick,
    onDeleteClick,
    editLoading,
    deleteLoading,
    editDisabled,
    deleteDisabled,
  } = actions;

  return (
    <div style={{ display: "flex", gap: 20 }}>
      {onEditClick && (
        <Button
          key="edit"
          icon="pencil"
          onClick={() => onEditClick(data)}
          disabled={editDisabled}
          loading={editLoading}
        />
      )}
      {onDeleteClick && (
        <Button
          key="delete"
          icon="trash"
          style="danger"
          onClick={() => onDeleteClick(data)}
          disabled={deleteDisabled}
          loading={deleteLoading}
        />
      )}
    </div>
  );
}
