import { literal, nullable, object, string, is, Infer } from "superstruct";
import Exception from "./Exception";

type ErrorResolution = { invite: string; fn: () => unknown };

export default class MessageException extends Exception {
  constructor(
    readonly message: string,
    readonly code: string | null,
    readonly resolution?: ErrorResolution,
  ) {
    super({ cause: null });
  }

  toJson() {
    return {
      type: "MessageException",
      message: this.message,
      code: this.code,
    };
  }

  static isJson(input: unknown) {
    return is(input, MessageExceptionJsonSchema);
  }

  static fromJson(input: MessageExceptionJson) {
    return new MessageException(input.message, input.code);
  }
}

const MessageExceptionJsonSchema = object({
  type: literal("MessageException"),
  message: string(),
  code: nullable(string()),
});

type MessageExceptionJson = Infer<typeof MessageExceptionJsonSchema>;
