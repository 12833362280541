import axios, { AxiosInstance, isAxiosError } from "axios";
import { isNumber } from "lodash";
import { MD5 } from "object-hash";
import WhoAmI from "./WhoAmI";
import {
  BeneficiaryAchievementEdition,
  InstructorAchievementEdition,
} from "@hpo/client/models/Achievement";
import { Analysis } from "@hpo/client/models/Analysis";
import {
  BeneficiaryPaymentDetails,
  BeneficiaryPaymentsByConvention,
} from "@hpo/client/models/BeneficiaryPayment";
import {
  ConventionCreation,
  ConventionDetails,
  ConventionEdition,
  ConventionSummary,
} from "@hpo/client/models/Convention";
import { ExpenseInstructor } from "@hpo/client/models/Expense";
import {
  InstructorPaymentDetails,
  InstructorPaymentSummary,
  InstructorPaymentUpdate,
  InstructorPaymentValidation,
} from "@hpo/client/models/InstructorPayment";
import { InvestigationCreation } from "@hpo/client/models/Investigation";
import { Message } from "@hpo/client/models/Message";
import {
  Organization,
  OrganizationCreation,
} from "@hpo/client/models/Organization";
import { ReceiptUpdate } from "@hpo/client/models/Receipt";
import {
  ProjectInstructor,
  ProjectDraft,
  DraftCreation,
  ProjectSummary,
  ProjectBeneficiary,
} from "@hpo/client/models/Project";
import { UserAccess, UserCreation, UserUpdate } from "@hpo/client/models/User";
import createInjectable from "@hpo/client/utilities/createInjectable";
import MessageException from "@hpo/client/utilities/errors/MessageException";
import { IndicatorPayload } from "@hpo/client/models/Indicator";
import ProgramKey from "@hpo/client/utilities/enums/ProgramKey";
import ExpenseType from "@hpo/client/utilities/enums/ExpenseType";
import {
  ProgramAvailability,
  ProgramAvailabilityPayload,
} from "@hpo/client/models/Program";
import { Tag, TagCreation } from "@hpo/client/models/Tag";
import Exception from "@hpo/client/utilities/errors/Exception";
import {
  OverallStatistics,
  ProjectsPerformance,
  TagsPerformance,
  Yearly,
} from "@hpo/client/models/Yearly";
import Suspender from "@hpo/client/utilities/Suspender";
import messageExceptionCaster from "@hpo/client/message-exception-caster";
import { UploadRequest, UploadSlot } from "@hpo/client/models/Upload";

type SuspensableRequestParams = { path: string };

export default class ServerSdk {
  private instance: AxiosInstance;
  readonly suspender: Suspender<SuspensableRequestParams>;

  constructor(
    baseURL: string,
    private whoAmI: WhoAmI,
  ) {
    this.instance = axios.create({
      baseURL,
      withCredentials: true,
    });
    this.setupCookieReadOnAxiosResponse(this.instance);
    this.setupMessageErrorDetection(this.instance);

    this.suspender = new Suspender(
      async (params: SuspensableRequestParams) => {
        const res = await this.instance.get(params.path);
        return res.data;
      },
      (c) => {
        const me = this.whoAmI.searchUser();
        return MD5({ ...c, me: me });
      },
    );
  }

  private setupCookieReadOnAxiosResponse(instance: AxiosInstance) {
    instance.interceptors.response.use(
      (response) => {
        this.whoAmI.readCookies();
        return response;
      },
      (error) => {
        this.whoAmI.readCookies();
        return Promise.reject(error);
      },
    );
  }

  private setupMessageErrorDetection(instance: AxiosInstance) {
    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (isAxiosError(error) && error.code === "ERR_NETWORK") {
          throw new NetworkError({ cause: error });
        }

        if (
          isAxiosError(error) &&
          error.response &&
          error.response.data &&
          MessageException.isJson(error.response.data)
        ) {
          const messageException = MessageException.fromJson(
            error.response.data,
          );
          throw messageException;
        } else {
          throw error;
        }
      },
    );
  }

  // Login

  async login(email: string, password: string) {
    await this.instance.post<void>("/login", {
      email,
      password,
    });
  }

  async refreshLogin() {
    await this.instance.post<void>("/login/refresh");
  }

  async logout() {
    await this.instance.delete<void>("/login");
  }

  // Projects

  async getProjects() {
    const res = await this.instance.get<Array<ProjectSummary>>("/projects");
    return res.data;
  }

  async getProject(id: string) {
    const res = await this.instance.get<ProjectInstructor>(`/projects/${id}`);
    return res.data;
  }

  async updateProject(
    id: string,
    updates: Partial<Pick<ProjectInstructor, "label" | "periods">>,
  ) {
    const res = await this.instance.patch<ProjectInstructor>(
      `/projects/${id}`,
      updates,
    );
    return res.data;
  }

  async updateExpenseAmountProposed(
    id: string,
    type: ExpenseType,
    proposal: number | null,
  ) {
    const res = await this.instance.patch<ExpenseInstructor>(
      `/expenses/${id}`,
      {
        id,
        type,
        proposal,
      },
    );
    return res.data;
  }

  async createIndicator(
    projectId: string,
    indicator: IndicatorPayload,
    sort: number,
  ) {
    const res = await this.instance.post<string>(
      `/projects/${projectId}/indicators`,
      indicator,
      { params: { sort } },
    );
    return res.data;
  }

  async updateIndicator(
    projectId: string,
    indicatorId: string,
    indicator: IndicatorPayload,
  ) {
    const res = await this.instance.patch<string>(
      `/projects/${projectId}/indicators/${indicatorId}`,
      indicator,
    );
    return res.data;
  }

  async updateIndicatorTags(
    projectId: string,
    indicatorId: string,
    tags: Array<string>,
  ) {
    const res = await this.instance.patch<string>(
      `/projects/${projectId}/indicators/${indicatorId}/tags`,
      { tags },
    );
    return res.data;
  }

  async deleteIndicator(projectId: string, indicatorId: string) {
    const res = await this.instance.delete<void>(
      `/projects/${projectId}/indicators/${indicatorId}`,
    );
    return res.data;
  }

  // Project Draft (beneficiaries)

  async createMyDraft(req: DraftCreation) {
    const res = await this.instance.post<string>("/users/me/drafts", req);
    return res.data;
  }

  getMyDraft(id: string) {
    return this.suspender.getSuspendable<ProjectDraft>({
      path: `/users/me/drafts/${id}`,
    });
  }

  async updateMyDraft(id: string, draft: ProjectDraft) {
    await this.instance.patch<string>(`/users/me/drafts/${id}`, draft);
  }

  // Project

  async createMyProject(draft: ProjectDraft) {
    const res = await this.instance.post<string>(`/users/me/projects`, draft);
    this.suspender.refresh(this.getMyProjects());
    return res.data;
  }

  getMyProjects() {
    return this.suspender.getSuspendable<Array<ProjectSummary>>({
      path: `/users/me/projects`,
    });
  }

  getMyProject(id: string) {
    return this.suspender.getSuspendable<ProjectBeneficiary>({
      path: `/users/me/projects/${id}`,
    });
  }

  async createMyIndicator(
    projectId: string,
    indicator: IndicatorPayload,
    sort: number,
  ) {
    const res = await this.instance.post<string>(
      `/users/me/drafts/${projectId}/indicators`,
      indicator,
      { params: { sort } },
    );
    return res.data;
  }

  async updateMyIndicator(
    projectId: string,
    indicatorId: string,
    indicator: IndicatorPayload,
  ) {
    const res = await this.instance.patch<string>(
      `/users/me/drafts/${projectId}/indicators/${indicatorId}`,
      indicator,
    );
    return res.data;
  }

  async deleteMyIndicator(projectId: string, indicatorId: string) {
    const res = await this.instance.delete<void>(
      `/users/me/drafts/${projectId}/indicators/${indicatorId}`,
    );
    return res.data;
  }

  async updateAttachment(
    project: string,
    fileKey: string,
    uploads: Array<string>,
  ) {
    const res = await this.instance.patch<void>(
      `/users/me/drafts/${project}/attachments/${fileKey}`,
      { uploads },
    );
    return res.data;
  }

  async updateDescription(project: string, id: string, value: string | null) {
    const res = await this.instance.patch<void>(
      `/users/me/drafts/${project}/descriptions/${id}`,
      { value },
    );
    return res.data;
  }

  // Organization

  getOrganizations() {
    return this.suspender.getSuspendable<Array<Organization>>({
      path: "/organizations",
    });
  }

  async createOrganization(payload: OrganizationCreation) {
    const res = await this.instance.post<string>(`/organizations`, payload);
    return res.data;
  }

  async updateOrganization(id: string, payload: OrganizationCreation) {
    await this.instance.patch<void>(`/organizations/${id}`, payload);
  }

  // Attachements

  async uploadAttachment(req: string, fileKey: string, uploads: Array<string>) {
    const res = await this.instance.post<void>(
      `/projects/${req}/attachments/${fileKey}`,
      { uploads },
    );
    return res.data;
  }

  // Analysis

  async saveAnalysis(resource: string, analysis: Analysis) {
    const res = await this.instance.put<string>(
      `/analyses/${resource}`,
      analysis,
    );
    return res.data;
  }

  // Investigation

  async createInvestigation(request: string, invest: InvestigationCreation) {
    await this.instance.post<void>(
      `/projects/${request}/investigation`,
      invest,
    );
  }

  async createProjectReport(project: string) {
    await this.instance.post<void>(`/projects/${project}/report`);
  }

  // Me

  async updateMyPassword(password: string) {
    await this.instance.post<void>(`/me/password`, { password });
  }

  // IAm

  async getUsers() {
    const res = await this.instance.get<Array<UserAccess>>(`/users`);
    return res.data;
  }

  async createUser(payload: UserCreation) {
    const res = await this.instance.post<string>(`/users`, payload);
    return res.data;
  }

  async updateUser(id: string, payload: UserUpdate) {
    await this.instance.patch<void>(`/users/${id}`, payload);
  }

  async updateUserPassword(id: string) {
    await this.instance.post<void>(`/users/${id}/password`);
  }

  async deleteUser(id: string) {
    await this.instance.delete<void>(`/users/${id}`);
  }
  // Uploads

  async uploadFile(file: File, onProgress?: (p: number) => unknown) {
    const formData = new FormData();
    formData.append("file", file);
    const res = await this.instance.post<string>(`/uploads`, formData, {
      onUploadProgress: (e) => {
        if (onProgress && isNumber(e.total) && e.total !== 0)
          onProgress(e.loaded / e.total);
      },
    });
    return res.data;
  }

  async createUploadSlot(req: UploadRequest) {
    const res = await this.instance.post<UploadSlot>(`/upload-slots`, req);
    return res.data;
  }

  // Convention

  async getOrganizationProjects(org: string) {
    const res = await this.instance.get<Array<ProjectSummary>>(
      `/organizations/${org}/projects`,
    );
    return res.data;
  }

  async createConvention(convention: ConventionCreation): Promise<string> {
    const res = await this.instance.post<string>("/conventions", convention);
    this.suspender.refresh(this.getConventions());
    return res.data;
  }

  getConventions() {
    return this.suspender.getSuspendable<Array<ConventionSummary>>({
      path: "/conventions",
    });
  }

  async getConvention(id: string) {
    const res = await this.instance.get<ConventionDetails>(
      `/conventions/${id}`,
    );
    return res.data;
  }

  async updateConvention(id: string, updates: ConventionEdition) {
    const res = await this.instance.patch<ConventionDetails>(
      `/conventions/${id}`,
      updates,
    );
    return res.data;
  }

  async sendPaymentsAvailableMail(convention: string) {
    await this.instance.post(
      `/conventions/${convention}/payments-available-mail`,
    );
  }

  async cancelPaymentReceipts(convention: string, payment: string) {
    await this.instance.delete<void>(
      `/conventions/${convention}/payments/${payment}/receipts`,
    );
  }

  // Achievements

  async updateAchievementIndicatorNotes(
    achievement: string,
    updates: Pick<InstructorAchievementEdition, "indicatorNotes">,
  ) {
    await this.instance.patch<void>(
      `/achievements/${achievement}/indicatorNotes`,
      updates,
    );
  }

  async updateAchievement(
    achievement: string,
    updates: Pick<InstructorAchievementEdition, "grantedAmount">,
  ) {
    await this.instance.patch<void>(`/achievements/${achievement}`, updates);
  }

  // Update receipts

  async updateReceipt(receipt: string, updates: ReceiptUpdate) {
    await this.instance.patch<void>(`/receipts/${receipt}`, updates);
  }

  // Messages

  async getMessages(target: string | true) {
    const res = await this.instance.get<Array<Message>>(
      `/chats/${target === true ? "mine" : target}/messages`,
    );
    return res.data;
  }

  async sendMessage(target: string | true, message: string) {
    await this.instance.post<void>(
      `/chats/${target === true ? "mine" : target}/messages`,
      { content: message },
    );
  }

  // My payments

  async getMyPayments() {
    const res =
      await this.instance.get<BeneficiaryPaymentsByConvention>(
        `/users/me/payments`,
      );
    return res.data;
  }

  async getMyPayment(id: string): Promise<BeneficiaryPaymentDetails> {
    const res = await this.instance.get<BeneficiaryPaymentDetails>(
      `/users/me/payments/${id}`,
    );
    return res.data;
  }

  // Payments

  async getPayments() {
    const res =
      await this.instance.get<Array<InstructorPaymentSummary>>(`/payments`);
    return res.data;
  }

  async getPayment(id: string) {
    const res = await this.instance.get<InstructorPaymentDetails>(
      `/payments/${id}`,
    );
    return res.data;
  }

  async sendPaymentRequest(
    payment: string,
    achievments: Array<BeneficiaryAchievementEdition>,
  ): Promise<void> {
    await this.instance.patch<void>(`/users/me/payments/${payment}`, {
      achievments,
    });
  }

  async updatePayment(id: string, update: InstructorPaymentUpdate) {
    await this.instance.patch<void>(`/payments/${id}`, update);
  }

  async createPaymentExport(id: string) {
    const res = await this.instance.post(`/payments/${id}/export`, undefined, {
      responseType: "blob",
    });
    return res.data;
  }

  async deletePaymentReport(id: string, message: string | null) {
    const res = await this.instance.delete(`/payments/${id}/report`, {
      data: { message },
    });
    return res.data;
  }

  async deletePaymentInstruction(id: string) {
    const res = await this.instance.delete(`/payments/${id}/instruction`);
    return res.data;
  }

  async deletePayment(id: string) {
    const res = await this.instance.delete(`/payments/${id}`);
    return res.data;
  }

  async validatePayment(id: string, valdation: InstructorPaymentValidation) {
    await this.instance.post<void>(`/payments/${id}/validation`, valdation);
  }

  // ProjectTypes

  async getProjectTypes() {
    const output =
      await this.instance.get<Array<ProgramAvailability>>(`/project-types`);
    return output.data;
  }

  async getVisiblePrograms() {
    const output = await this.instance.get<Array<ProgramAvailability>>(
      `/project-types`,
      { params: { visible: "true" } },
    );
    return output.data;
  }

  async updateProjectType(
    key: ProgramKey,
    payload: ProgramAvailabilityPayload,
  ) {
    await this.instance.patch(`/project-types/${key}`, payload);
  }

  // Tags
  async getTags() {
    const res = await this.instance.get<Array<Tag>>(`/tags`);
    return res.data;
  }

  async getTag(id: string) {
    const res = await this.instance.get<Tag>(`/tags/${id}`);
    return res.data;
  }

  async createTag(tag: TagCreation) {
    const res = await this.instance.post<string>(`/tags`, tag);
    return res.data;
  }

  async updateTag(id: string, tag: TagCreation) {
    const res = await this.instance.patch<string>(`/tags/${id}`, tag);
    return res.data;
  }

  // Periods

  async getPeriods() {
    const res = await this.instance.get<Array<string>>("/periods");
    return res.data;
  }

  async getOverallStatistics(periods: Array<string>) {
    const res = await this.instance.get<OverallStatistics>(
      "/overall-statistics",
      { params: { periods } },
    );
    return res.data;
  }

  async getTagsPerformance(periods: Array<string>) {
    const res = await this.instance.get<TagsPerformance>("/tags-performance", {
      params: { periods },
    });
    return res.data;
  }

  async getProjectsPerformance(periods: Array<string>) {
    const res = await this.instance.get<ProjectsPerformance>(
      "/projects-performance",
      { params: { periods } },
    );
    return res.data;
  }

  async getYearlies() {
    const res = await this.instance.get<Array<Yearly>>("/yearly-reports");
    return res.data;
  }

  async buildYealyReport(title: string, periods: Array<string>) {
    const res = await this.instance.post<void>("/yearly-reports", {
      title,
      periods,
    });
    return res.data;
  }
}

export const [ServerSdkProvider, useServerSdk] =
  createInjectable<ServerSdk>("ServerSdk");

export class NetworkError extends Exception {}

messageExceptionCaster.registerClass(
  NetworkError,
  () =>
    new MessageException(
      "La connexion à la plateforme HPO est impossible pour le moment. Veuillez réessayer plus tard.",
      null,
    ),
);
