import { Fragment } from "react";
import { ProjectInstructor } from "@hpo/client/models/Project";
import Spacer from "@hpo/client/utilities/Spacer";
import T from "@hpo/client/components/text/Text";
import { useAssistance } from "@hpo/client/navigators/RootNavigator/services/Assistance";
import {
  ProjectContact,
  ProjectInfos,
  ProjectOrganization,
} from "@hpo/client/navigators/MyProjectsNavigator/MyProjectScreen/ProjectInfos";
import ProjectCommitments from "@hpo/client/navigators/MyProjectsNavigator/MyProjectScreen/ProjectCommitments";

type ProjectTabMainProps = {
  project: ProjectInstructor;
  onReload: () => unknown;
};

export default function ProjectTabMain(props: ProjectTabMainProps) {
  const { project } = props;
  const assistance = useAssistance();
  assistance.useVisibilityDecision(true);
  return (
    <Fragment>
      <ProjectInfos project={project} />
      <Spacer />
      <ProjectOrganization project={project} />
      <Spacer />
      <T style="section">Référent</T>
      <ProjectContact project={project} />
      <Spacer />
      <T style="section">Engagements</T>
      <Spacer />
      <ProjectCommitments project={project} />
    </Fragment>
  );
}
