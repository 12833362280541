import { css } from "@emotion/react";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import React, {
  useEffect,
  PropsWithChildren,
  Fragment,
  RefObject,
} from "react";
import { useAssistance } from "../../services/Assistance";
import HelpZone from "./HelpZone";
import T from "@hpo/client/components/text/Text";

type WithHelpProps = PropsWithChildren<{
  title?: string;
  text?: React.ReactNode;
  color?: string;
  inactive?: boolean;
  mouseoverRef?: RefObject<HTMLElement>;
}>;

const instancesCounterAtom = atom(0);
const hasInstancesAtom = atom((get) => get(instancesCounterAtom) > 0);
const mouseoverOn = atom<RefObject<HTMLElement | null> | null>(null);

export default function WithHelp(props: WithHelpProps) {
  const { inactive, mouseoverRef } = props;
  const setInstances = useSetAtom(instancesCounterAtom);
  const haveInstances = useAtomValue(hasInstancesAtom);
  const areHelpsVisible = useAssistance().useIsVisible();

  const [mouseOvered, setMouseOvered] = useAtom(mouseoverOn);

  useEffect(() => {
    if (inactive) return;
    setInstances((prev) => prev + 1);
    return () => {
      setInstances((prev) => prev - 1);
    };
  }, [inactive]);

  useEffect(() => {
    if (haveInstances) {
      const onScroll = () => {
        document.body.style.setProperty(
          "--with-help-offset",
          `${document.documentElement.scrollTop * -1}px`,
        );
      };
      document.addEventListener("scroll", onScroll);
      return () => document.removeEventListener("scroll", () => onScroll);
    }
  }, [haveInstances]);

  const inScreen = areHelpsVisible && !inactive;

  let visible: boolean = true;
  if (!areHelpsVisible) visible = false;
  else if (inactive) visible = false;
  else if (mouseoverRef && mouseOvered !== mouseoverRef) visible = false;
  else if (!mouseoverRef && mouseOvered !== null) visible = false;

  const containerCss = css({
    position: "fixed",
    right: inScreen ? 0 : "-33vw",
    width: "33vw",
    transform: `translateY(var(--with-help-offset))`,
    opacity: visible ? 1 : 0,
    pointerEvents: visible ? "auto" : "none",
    transition: "right 300ms, opacity 300ms",
  });

  useEffect(() => {
    if (!mouseoverRef) return;
    const el = mouseoverRef.current;
    if (!el) return;
    const onMouseEnter = () => setMouseOvered(mouseoverRef);
    const onMouseLeave = () =>
      setMouseOvered((c) => (c === mouseoverRef ? null : c));
    el.addEventListener("mouseenter", onMouseEnter);
    el.addEventListener("mouseleave", onMouseLeave);
    return () => {
      el.removeEventListener("mouseenter", onMouseEnter);
      el.removeEventListener("mouseleave", onMouseLeave);
    };
  }, [mouseoverRef]);

  return (
    <Fragment>
      <div css={containerCss}>
        <HelpZone color={props.color}>
          {props.title ? <T style="bold">{props.title}</T> : null}
          <T>{props.text}</T>
        </HelpZone>
      </div>
      {props.children}
    </Fragment>
  );
}
