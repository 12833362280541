import { useEffect, useState } from "react";
import { IntervalBasedObserver } from "./IntervalBasedObserver";

const observer = new IntervalBasedObserver(() => window.location.pathname);

export default function useLocation() {
  const [path, setPath] = useState(() => observer.fn());
  useEffect(() => {
    const unsub = observer.onChange(setPath);
    return unsub;
  }, []);
  return path;
}
