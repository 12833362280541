import Destination, { MergeParams } from "./Routing/Destination";

// Accueil
export const homeRoute = new Destination("/");

// Projects
export const projectsRoute = new Destination("/projets");
export const projectRoute = projectsRoute.child<{ id: string }>("/:id");

// Mes projets
export const myProjectsRoute = new Destination("/mes-projets");
export const newDraftRoute = myProjectsRoute.child("/nouveau");
export const myDraftRoute = myProjectsRoute.child<{ draftId: string }>(
  "/:draftId/brouillon",
);
export const myProjectRoute = myProjectsRoute.child<{ projectId: string }>(
  "/:projectId",
);

// Conventions
export const conventionsRoute = new Destination("/conventions");
export const conventionCreationRoute = conventionsRoute.child("/new");
export const conventionRoute = conventionsRoute.child<{ conventionId: string }>(
  "/:conventionId",
);
export const conventionPaymentRoute = conventionRoute.child<{
  paymentId: string;
}>("/paiements/:paymentId");

// Payments
export const paymentsRoute = new Destination("/paiements");

// MyPayments
export const myPaymentsRoute = new Destination("/mes-paiements");
export const myPaymentRoute = myPaymentsRoute.child<{ paymentId: string }>(
  "/:paymentId",
);

export const managementRoute = new Destination("/gestion-plateforme");

export const yearlyReportRoute = new Destination("/rapport-annuel");

export const settingsRoute = new Destination("/parametres");
export const errorRoute = new Destination("/test-erreur");

/** @deprecated : Use route.getUrl() instead */
export function getUrl(path: string, params?: Record<string, string>): string;
/** @deprecated : Use route.getUrl() instead */
export function getUrl<
  Params extends Record<string, string> | void = void,
  ParentParams extends Record<string, string> | void = void,
>(
  route: Destination<Params, ParentParams>,
  params?: MergeParams<Params, ParentParams>,
): string;
export function getUrl(input: string | Destination, params?: any) {
  const route =
    typeof input === "string"
      ? new Destination<Record<string, string>>(input)
      : input;
  return route.getRootUrl(params);
}
