// BusinessRules

import { uniq } from "lodash";
import { is } from "superstruct";
import { ProjectBeneficiary, ProjectDraft } from "../models/Project";
import messageExceptionCaster from "../message-exception-caster";
import ActionType from "./enums/ActionType";
import Exception from "./errors/Exception";
import Programs from "./Programs";
import AnnexType from "./enums/AnnexType";
import ExpenseType from "./enums/ExpenseType";
import MessageException from "./errors/MessageException";
import { phoneSchema } from "./helpers/ProjectHelper";

export function assertProjectIsValid(
  project: ProjectDraft,
  allowIncompleteIndicators = false,
): asserts project is ProjectBeneficiary {
  assertProjectContact(project);
  assertProjectPresentation(project);
  assertCommitments(project);
  assertCompliances(project);
  assertAttachments(project);
  assertDescriptions(project);
  assertIndicators(project, allowIncompleteIndicators);
  assertExpenses(project);
  assertActions(project, ActionType.Communication);
  assertActions(project, ActionType.Transfer);
}

export function assertProjectContact(project: ProjectDraft) {
  if (project.contactPhoneNumber === null) {
    throw new InvalidProjectError(
      "Veuillez renseigner un numéro de téléphone fixe",
    );
  }

  if (project.contactMobilePhoneNumber === null) {
    throw new InvalidProjectError(
      "Veuillez renseigner un numéro de téléphone mobile",
    );
  }
  if (!is(project.contactPhoneNumber, phoneSchema)) {
    throw new InvalidProjectError("Vérifiez le format du numéro de téléphone");
  }

  if (!is(project.contactMobilePhoneNumber, phoneSchema)) {
    throw new InvalidProjectError("Vérifiez le format du numéro de portable");
  }
}

export function assertProjectPresentation(project: ProjectDraft) {
  if (!project.keywords) {
    throw new InvalidProjectError("Veuillez renseigner au moins un mot clé");
  }

  if (project.keywords.length > 5) {
    throw new InvalidProjectError("Veuillez renseigner au maximum 5 mots clés");
  }

  if (project.summary === null) {
    throw new InvalidProjectError("Veuillez renseigner un résumé du projet");
  }
}

export function assertExpenses(project: ProjectDraft) {
  const expenses = project.expenses;

  // At leat one expense
  if (expenses.length === 0) {
    throw new InvalidProjectError("Veuillez renseigner au moins une dépense");
  }

  const forbiddenError: Record<ExpenseType, string> = {
    [ExpenseType.Product]:
      "Les dépenses d'achat de fournitures sont interdites",
    [ExpenseType.Service]: "Les dépenses de prestations sont interdites",
    [ExpenseType.Salary]: "Les dépenses de personnel sont interdites",
  };

  Object.values(ExpenseType).forEach((type) => {
    const canHave = Programs[project.type].expenseTypes.includes(type);
    const typeExpenses = expenses.filter((e) => e.type === type);
    if (!canHave && typeExpenses.length > 0)
      throw new InvalidProjectError(forbiddenError[type]);
  });
}

export function assertIndicators(
  project: ProjectDraft,
  allowIncompleteIndicators = false,
) {
  const wantedIndicators = Programs[project.type].indicatorTypes
    .sort()
    .join(" ");
  const filledIndicators = uniq(project.indicators.map((i) => i.type))
    .sort()
    .join(" ");

  if (wantedIndicators !== filledIndicators) {
    throw new InvalidProjectError(
      "Veuillez renseigner au moins un indicateur par type",
    );
  }

  if (allowIncompleteIndicators === false) {
    project.indicators.forEach((indicator) => {
      if (indicator.goals.length !== project.periods.length) {
        throw new InvalidProjectError(
          "Veuillez renseigner une valeur pour chaque période",
        );
      }
    });
  }
}

export function assertActions(project: ProjectDraft, type: ActionType) {
  const actions = project.actions.filter((action) => action.type === type);
  const mustHaveActions = Programs[project.type].annexes.includes(
    type === "communication" ? AnnexType.A4 : AnnexType.A5,
  );

  if (mustHaveActions && actions.length === 0) {
    throw new InvalidProjectError(
      `Veuillez renseigner au moins une action de ${type}`,
    );
  }

  if (!mustHaveActions && actions.length > 0) {
    throw new InvalidProjectError(
      `Le projet ne doit pas contenir d'actions de ${type}`,
    );
  }
}

export function assertAttachments(project: ProjectDraft) {
  Programs[project.type].attachments.forEach((a) => {
    const attachment = project.attachments.find((att) => att.key === a.key);
    if (attachment === undefined)
      throw new InvalidProjectError("Une pièce jointe est manquante");
    if (attachment.files.length === 0)
      throw new InvalidProjectError(
        "Veuillez fournir toutes les pièce jointes.",
      );
  });
}

export function assertCommitments(project: ProjectDraft) {
  Programs[project.type].commitments.forEach((d) => {
    const commitment = project.commitments.find(
      (desc) => desc.label === d.label,
    );
    if (commitment === undefined)
      throw new InvalidProjectError("Un engagement est manquante");
    if (commitment.acceptedAt === null)
      throw new InvalidProjectError("Veuillez remplir toutes les engagements.");
  });
}

export function assertCompliances(project: ProjectDraft) {
  Programs[project.type].compliances.forEach((d) => {
    const compliance = project.compliances.find(
      (desc) => desc.label === d.label,
    );
    if (compliance === undefined)
      throw new InvalidProjectError("Une éligibilité est manquante");
    if (compliance.acceptedAt === null)
      throw new InvalidProjectError(
        "Veuillez remplir toutes les éligibilités.",
      );
  });
}

export function assertDescriptions(project: ProjectDraft) {
  Programs[project.type].descriptions.forEach((d) => {
    const description = project.descriptions.find(
      (desc) => desc.label === d.label,
    );
    if (description === undefined)
      throw new InvalidProjectError("Une description est manquante");
    if (description.value === null)
      throw new InvalidProjectError(
        "Veuillez remplir toutes les descriptions.",
      );
  });
}

export class InvalidProjectError extends Exception {
  constructor(message: string) {
    super({ message, tags: {}, cause: null });
  }
}

messageExceptionCaster.registerClass(InvalidProjectError, (error) => {
  return new MessageException(error.message, null);
});
