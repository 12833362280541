import { Fragment, useCallback, useEffect, useState } from "react";
import { useChangeEffect } from "@ehynds/use-change-effect";
import { create, is } from "superstruct";
import { isString } from "lodash";
import Spacer from "@hpo/client/utilities/Spacer";
import T from "@hpo/client/components/text/Text";
import TextField from "@hpo/client/utilities/fields/TextField";
import { ProjectDraft } from "@hpo/client/models/Project";
import Columns from "@hpo/client/utilities/Columns";
import TextsField from "@hpo/client/utilities/fields/TextsField";
import {
  phoneCoercer,
  phoneSchema,
} from "@hpo/client/utilities/helpers/ProjectHelper";
import AddressField from "@hpo/client/utilities/fields/AddressField";

type ProjectOrganizationProps = {
  project: ProjectDraft;
};

export function ProjectOrganization(props: ProjectOrganizationProps) {
  const { project } = props;

  return (
    <Fragment>
      <Columns columns={3}>
        <div>
          <T style="section">Structure</T>
          <Spacer />
          <TextField
            required
            id="organizationBusinessName"
            label="Raison sociale"
            value={project.organization.businessName}
            readonly
          />
          <Spacer />
          <TextField
            required
            id="organizationType"
            label="Statut juridique"
            value={project.organization.type}
            readonly
          />
          <Spacer />
          <TextField
            required
            id="organizationLegalId"
            label="Numéro de SIRET"
            value={project.organization.legalId}
            readonly
          />
        </div>
        <div>
          <T style="section">Réprésentant légal</T>
          <Spacer />
          <TextField
            required
            id="organizationLegalRepresentativeName"
            label="Nom du réprésentant légal"
            value={project.organization.legalRepresentativeName}
            readonly
          />
          <Spacer />
          <TextField
            required
            id="organizationLegalRepresentativePosition"
            label="Fonction du réprésentant légal"
            value={project.organization.legalRepresentativePosition}
            readonly
          />
        </div>
        <div>
          <T style="section">Adresse postale</T>
          <Spacer />
          <AddressField
            required
            label="Adresse postale"
            value={props.project.organization.address}
            readonly
          />
        </div>
      </Columns>
    </Fragment>
  );
}

type ProjectContactProps = {
  project: ProjectDraft;
  onChange?: (d: Partial<ProjectDraft>) => unknown;
};

export function ProjectContact(props: ProjectContactProps) {
  const { project, onChange } = props;

  const locked = onChange === undefined;

  const [contactPhoneNumber, setContactPhoneNumber] = useState(
    project.contactPhoneNumber,
  );

  const [validContactPhoneNumber, setValidContactPhoneNumber] = useState<
    string | null | undefined
  >();

  useEffect(() => {
    if (!isString(validContactPhoneNumber)) return;
    setContactPhoneNumber(create(validContactPhoneNumber, phoneCoercer));
  }, [validContactPhoneNumber]);

  const [contactMobilePhoneNumber, setContactMobilePhoneNumber] = useState(
    project.contactMobilePhoneNumber,
  );

  const [validContactMobilePhoneNumber, setValidContactMobilePhoneNumber] =
    useState<string | null | undefined>();

  useEffect(() => {
    if (!isString(validContactMobilePhoneNumber)) return;
    setContactMobilePhoneNumber(
      create(validContactMobilePhoneNumber, phoneCoercer),
    );
  }, [validContactMobilePhoneNumber]);

  useChangeEffect(() => {
    if (!onChange) return;
    onChange({
      contactPhoneNumber: validContactPhoneNumber || null,
      contactMobilePhoneNumber: validContactMobilePhoneNumber || null,
    });
  }, [validContactPhoneNumber, validContactMobilePhoneNumber, onChange]);

  const getPhoneErrorMessage = useCallback((v: string | null) => {
    if (v === null) return "Ce champ est requis";
    if (!is(v, phoneSchema)) return "Ce numéro de téléphone semble invalide";
    return null;
  }, []);

  return (
    <Columns>
      <div>
        <TextField
          required
          id="ownerFirstName"
          label="Personne référente dossier HPO"
          value={`${project.owner.firstName} ${project.owner.lastName}`}
          disabled={!locked}
          readonly={locked}
        />
        <Spacer />
        <TextField
          required
          id="ownerEmail"
          label="Adresse email"
          value={project.owner.email}
          disabled={!locked}
          readonly={locked}
        />
      </div>
      <div>
        <TextField
          required
          id="contactPhoneNumber"
          label="Téléphone fixe"
          value={contactPhoneNumber}
          onChange={setContactPhoneNumber}
          readonly={locked}
          errorMessage={getPhoneErrorMessage}
          onValidate={setValidContactPhoneNumber}
        />
        <Spacer />
        <TextField
          required
          id="contactMobilePhoneNumber"
          label="Téléphone mobile"
          value={contactMobilePhoneNumber}
          onChange={setContactMobilePhoneNumber}
          readonly={locked}
          errorMessage={getPhoneErrorMessage}
          onValidate={setValidContactMobilePhoneNumber}
        />
      </div>
    </Columns>
  );
}

type ProjectInfosProps = {
  project: ProjectDraft;
  onChange?: (d: Partial<ProjectDraft>) => unknown;
};

export function ProjectInfos(props: ProjectInfosProps) {
  const { project, onChange } = props;

  const locked = onChange === undefined;

  const [label, setLabel] = useState<string | null>(project.label);
  const [keywords, setKeywords] = useState<Array<string> | null>(
    project.keywords,
  );
  const [summary, setSummary] = useState<string | null>(project.summary);

  useEffect(() => {
    if (!onChange) return;
    if (label === null) return;
    onChange({ label, summary, keywords });
  }, [label, summary, keywords, onChange]);

  return (
    <div>
      <TextField
        required
        id="label"
        label="Libellé du projet"
        value={label}
        onChange={setLabel}
        textArea
        readonly={locked}
      />
      <Spacer />
      <TextField
        required
        id="summary"
        label="Résumé"
        value={summary}
        onChange={setSummary}
        textArea
        readonly={locked}
      />
      <Spacer />
      <TextsField
        required
        id="keywords"
        label="Mots clés"
        value={keywords}
        onChange={setKeywords}
        readonly={locked}
      />
    </div>
  );
}
