import { css } from "@emotion/react";
import { PropsWithChildren } from "react";
import { rgba } from "polished";
import { textCss } from "@hpo/client/components/text/Text";
import HeadlessFieldsContext from "@hpo/client/utilities/fields/components/HeadlessFieldsContext";
import Theme from "@hpo/client/utilities/Theme/client";

type TableProps = PropsWithChildren<{ frame?: boolean }>;

export default function Table(props: TableProps) {
  const cellRadius = props.frame ? 5 : 3;

  const style = css(textCss({ style: "body" }), {
    minWidth: "100%",
    borderSpacing: 0,
    borderRadius: 6,
    background: rgba("white", 0.2),
    border: props.frame
      ? `1px solid ${rgba(Theme.color, 1)}`
      : `3px solid white`,

    "& > thead:last-child , & > tbody:last-child , & > tfoot:last-child": {
      "& > tr:last-child": {
        "& > th,& > td": {
          borderBottomWidth: 0,
        },
        "& > th:first-child,& > td:first-child": {
          borderBottomLeftRadius: cellRadius,
        },
        "& > th:last-child,& > td:last-child": {
          borderBottomRightRadius: cellRadius,
        },
      },
    },

    "& > thead,& > tbody,& > tfoot": {
      "& > tr": {
        "& > th:last-child, & > td:last-child": {
          borderRightWidth: 0,
        },
        "& > td, & > th": {
          paddingTop: 6,
          paddingBottom: 6,
          paddingLeft: 10,
          paddingRight: 10,
          margin: 0,
          textAlign: "left",
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          borderRightWidth: 1,
          borderRightStyle: "solid",
        },
        "& > td": {
          borderBottomColor: rgba("black", 0.3),
          borderRightColor: rgba("black", 0.3),
        },
        "& > th, & > td.total": {
          background: rgba(Theme.color, 0.1),
          color: Theme.color,
          borderBottomColor: rgba(Theme.color, 1),
          borderRightColor: rgba(Theme.color, 1),
        },
      },
    },

    "& > thead:first-child , & > tbody:first-child ,& >  tfoot:first-child": {
      "& > tr:first-child": {
        "& > th:first-child,& > td:first-child": {
          borderTopLeftRadius: cellRadius,
        },
        "& > th:last-child,& > td:last-child": {
          borderTopRightRadius: cellRadius,
        },
      },
    },

    "& > thead,& >  tfoot": {
      "& > tr": {
        "& > th": {
          textAlign: "center",
        },
      },
    },

    position: "relative",
    ":before": {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      content: '""',
      zIndex: -1,
      backdropFilter: "blur(20px)",
    },
  });

  return (
    <HeadlessFieldsContext.Provider value={true}>
      <table css={style}>{props.children}</table>
    </HeadlessFieldsContext.Provider>
  );
}
