import navigate from "./navigate";

export default function listenToIntervalNavigation() {
  const fn = (event: Event) => {
    if (!event.target) return;
    if (!(event.target instanceof Element)) return;
    const closestAnchor = event.target.closest("a");
    if (!closestAnchor) return;
    const href = closestAnchor.getAttribute("href");
    const internal = closestAnchor.getAttribute("data-internal");
    if (internal !== "true") return;
    if (href === null) return;
    const url = new URL(href, window.location.origin);
    if (url.origin !== window.location.origin) return;
    event.preventDefault();
    event.stopPropagation();
    navigate(href);
  };
  document.addEventListener("click", fn);
  return () => {
    document.removeEventListener("click", fn);
  };
}
