import { useCallback, useEffect, useState } from "react";
import { css } from "@emotion/react";
import FieldProps from "./FieldProps";
import FieldWrapper from "./components/FieldWrapper";
import { useFieldId, useFieldValidity } from "./utilities";
import FieldInput, { FieldTextArea } from "./components/FieldInput";
import Button from "@hpo/client/components/Button";

type TextFieldProps = FieldProps<string | null> & {
  password?: boolean;
  textArea?: boolean;
  maxLength?: number;
};

export default function TextField(props: TextFieldProps) {
  const {
    value,
    password,
    textArea,
    placeholder,
    disabled = false,
    readonly = false,
    required = false,
    maxLength,
    onValidate,
    errorMessage,
    onChange,
  } = props;

  const id = useFieldId(props.id);
  const validity = useFieldValidity(
    value,
    required,
    () => value === "" || value === null || value === undefined,
    (v) => {
      if (v !== null && maxLength !== undefined && v.length > maxLength)
        return `${maxLength} caractères max`;
      if (errorMessage) return errorMessage(v);
      return null;
    },
  );

  const [finalized, setFinalized] = useState(false);

  const setValue = useCallback(
    (v: string | null) => {
      if (!onChange) return;
      if (readonly || disabled) return;
      onChange(v);
    },
    [onChange, readonly, disabled],
  );

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(e.target.value || null);
    },
    [setValue],
  );

  useEffect(() => {
    if (!onValidate) return;
    if (errorMessage && errorMessage(value) !== null) onValidate(undefined);
    else onValidate(value);
  }, [onValidate, errorMessage, value]);

  const [obfuscated, setObfuscated] = useState<boolean>(password || false);

  const wrapperCss = css({
    display: "flex",
  });

  const inputCss = css({
    flex: 1,
  });

  return (
    <FieldWrapper {...props} id={id} validity={validity} finalized={finalized}>
      <div css={wrapperCss}>
        <div css={inputCss}>
          {textArea ? (
            <FieldTextArea
              value={value || ""}
              onChange={onInputChange}
              placeholder={placeholder}
              disabled={disabled}
              readOnly={readonly}
              maxLength={props.maxLength}
              onBlur={() => setFinalized(true)}
            />
          ) : (
            <FieldInput
              type={obfuscated ? "password" : "text"}
              value={value || ""}
              onChange={onInputChange}
              placeholder={placeholder}
              disabled={disabled}
              readOnly={readonly}
              maxLength={props.maxLength}
              onBlur={() => setFinalized(true)}
            />
          )}
        </div>
        {password === true ? (
          <Button
            icon={obfuscated ? "eye" : "eye-off"}
            onClick={() => setObfuscated((c) => !c)}
            style="discreet"
          />
        ) : null}
      </div>
    </FieldWrapper>
  );
}
