import React, {
  Fragment,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import Await, { usePromiseState } from "../Await";
import getErrorMessage from "../errors/getErrorMessage.ts";
import List, { ListItem, ListItemProps } from "../List";
import FieldProps from "./FieldProps";
import FieldWrapper from "./components/FieldWrapper";
import { useFieldId, useFieldValidity } from "./utilities";
import FieldDropdown from "./components/FieldDropdown";
import FieldInput from "./components/FieldInput";
import FieldWithButtons from "./components/FieldWithButtons";
import Button from "@hpo/client/components/Button";

type DropDownFieldProps<D> = FieldProps<D | null> & {
  options: Array<D> | Promise<Array<D>>;
  renderOption: (item: D) => ListItemProps;
  renderNullOption?: () => ListItemProps;
  keyExtractor: (d: D) => string;
};

export default function DropDownField<D>(props: DropDownFieldProps<D>) {
  const {
    value,
    onChange,
    required = false,
    errorMessage,
    renderOption,
    keyExtractor,
    renderNullOption,
    disabled,
    readonly,
  } = props;

  const id = useFieldId(props.id);

  const optionsState = usePromiseState(props.options);

  const isActive = !disabled && !readonly;

  const validity = useFieldValidity(
    value,
    required,
    (v) => v === undefined || v === null,
    (v) => {
      if (optionsState.state === "pending") {
        return "Veuillez patienter...";
      } else if (optionsState.state === "rejected") {
        return getErrorMessage(optionsState.error);
      } else {
        const options = optionsState.value;
        if (v && !options.map((o) => keyExtractor(o)).includes(keyExtractor(v)))
          return "Sélectionnez une autre valeur";
      }
      if (errorMessage) return errorMessage(v);
      return null;
    },
  );

  const onSelect = useCallback(
    (v: D | null) => {
      if (onChange) onChange(v);
      setDropdown(false);
    },
    [onChange],
  );

  const placeholder = useMemo(() => {
    return renderNullOption ? renderNullOption().label : undefined;
  }, [renderNullOption]);

  const [dropdown, setDropdown] = useState<boolean>(false);

  let inputNode: ReactNode;
  if (optionsState.state === "pending") {
    inputNode = <FieldInput value="Chargement en cours..." readOnly />;
  } else if (optionsState.state === "rejected") {
    inputNode = (
      <FieldInput value={getErrorMessage(optionsState.error)} readOnly />
    );
  } else {
    if (isActive) {
      inputNode = (
        <FieldWithButtons
          buttons={
            <Fragment>
              {value !== null && onChange ? (
                <Button
                  icon={{ name: "close" }}
                  style="discreet"
                  onClick={() => onChange(null)}
                />
              ) : null}
              <Button
                icon={{ name: "chevron", rotate: dropdown ? 0 : 180 }}
                style="discreet"
                onClick={() => setDropdown(!dropdown)}
              />
            </Fragment>
          }
        >
          <FieldInput
            value={value !== null ? renderOption(value).label : ""}
            placeholder={placeholder}
            readOnly
            onFocus={() => setDropdown(true)}
            cursor="pointer"
          />
        </FieldWithButtons>
      );
    } else {
      inputNode = (
        <FieldInput
          value={value !== null ? renderOption(value).label : ""}
          placeholder={placeholder}
          readOnly={readonly}
          disabled={disabled}
        />
      );
    }
  }

  const [finalized, setFinalized] = useState(false);

  return (
    <FieldWrapper {...props} id={id} validity={validity} finalized={finalized}>
      {inputNode}
      <FieldDropdown
        visible={dropdown}
        onHide={() => {
          setDropdown(false);
          setFinalized(true);
        }}
      >
        <Await promise={props.options}>
          {(options) => {
            return (
              <List
                data={renderNullOption ? [...options, null] : options}
                renderItem={(i) => {
                  if (i === null) {
                    if (renderNullOption) {
                      return (
                        <ListItem
                          {...renderNullOption()}
                          onClick={() => onSelect(null)}
                        />
                      );
                    }
                  } else {
                    const itemProps = renderOption(i);
                    return (
                      <ListItem {...itemProps} onClick={() => onSelect(i)} />
                    );
                  }
                }}
              />
            );
          }}
        </Await>
      </FieldDropdown>
    </FieldWrapper>
  );
}
