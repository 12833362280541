import { useCallback, useState } from "react";
import { useChangeEffect } from "@ehynds/use-change-effect";
import Spacer from "@hpo/client/utilities/Spacer";
import TextField from "@hpo/client/utilities/fields/TextField";
import { ProjectDraft } from "@hpo/client/models/Project";
import Intersperse from "@hpo/client/components/Intersperse";

type ProjectDescriptionsProps = {
  project: ProjectDraft;
  onChange?: (d: Partial<ProjectDraft>) => unknown;
};

export default function ProjectDescriptions(props: ProjectDescriptionsProps) {
  const { project, onChange } = props;

  const locked = onChange === undefined;

  const [descriptions, setDescriptions] = useState(project.descriptions);

  const setDescriptionValue = useCallback(
    (id: string, value: string | null) => {
      setDescriptions((current) => {
        return current.map((c) => (c.id === id ? { ...c, value } : c));
      });
    },
    [],
  );

  useChangeEffect(() => {
    if (!onChange) return;
    onChange({ descriptions });
  }, [descriptions, onChange]);

  return (
    <Intersperse between={() => <Spacer />}>
      {project.descriptions.map((d) => {
        return (
          <TextField
            key={d.id}
            textArea
            label={d.label}
            value={d.value}
            onChange={(value) => setDescriptionValue(d.id, value)}
            required
            readonly={locked}
          />
        );
      })}
    </Intersperse>
  );
}
