import { uniqBy } from "lodash";
import { Fragment, useCallback, useState } from "react";
import WithHelp from "../../RootNavigator/assistance/Help/WithHelp";
import { useServerSdk } from "../../RootNavigator/services/ServerSdk";
import SummaryStep from "./SummaryStep";
import AchievementStep from "./AchievementStep";
import PaymentReview from "./PaymentReview";
import ContentLayout from "@hpo/client/components/layout/ContentLayout";
import T from "@hpo/client/components/text/Text";
import { BeneficiaryAchievementEdition } from "@hpo/client/models/Achievement";
import {
  BeneficiaryPaymentDetails,
  getPaymentLabel,
} from "@hpo/client/models/BeneficiaryPayment";
import {
  MissingReceipts,
  TooManyReceipts,
  assertProjectHasAllReceipts,
} from "@hpo/client/utilities/Receipts";
import Spacer from "@hpo/client/utilities/Spacer";
import Stepper from "@hpo/client/utilities/Stepper2";
import MessageException from "@hpo/client/utilities/errors/MessageException";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import ErrorToast from "@hpo/client/utilities/ErrorToast";

export type PaymentFormProps = {
  payment: BeneficiaryPaymentDetails;
  onEnded: () => unknown;
};

export default function PaymentForm(props: PaymentFormProps) {
  const { payment, onEnded } = props;
  const server = useServerSdk();

  const [requests, setRequests] = useState<
    Array<BeneficiaryAchievementEdition>
  >([]);

  const onSubmitAchievement = useCallback(
    (request: BeneficiaryAchievementEdition) => {
      setRequests((current) => uniqBy([request, ...current], (r) => r.id));
    },
    [],
  );

  const [onEnd, ending] = useSubmitCallback(async () => {
    for (const achievement of payment.achievements) {
      const request = requests.find((r) => r.id === achievement.id);
      if (!request)
        throw new MessageException(
          "Vous ne semblez pas avoir fourni les informations requises pour l'ensemble des projets",
          null,
        );
      try {
        assertProjectHasAllReceipts(
          payment.fragment,
          achievement.funding.project.type,
          request.receipts.map((r) => r.key),
        );
      } catch (err) {
        if (err instanceof MissingReceipts)
          throw new MessageException(
            "Des fichiers semblent manquer. Vérifiez que vious avez correction sélectionné des fichiers pour chacun des champs demandés.",
            null,
          );
        else if (err instanceof TooManyReceipts) {
          throw new MessageException("Une erreur est survenue", null);
        } else {
          throw err;
        }
      }
    }
    await server.sendPaymentRequest(payment.id, requests);
    await onEnded();
  }, [payment, requests, onEnded]);

  return (
    <ContentLayout title={getPaymentLabel(payment)}>
      <Spacer />
      <ErrorToast error={ending.error} />
      <Stepper
        onEnd={onEnd}
        intro={
          <Fragment>
            <WithHelp
              title="Conventions multi-projets"
              text={
                <Fragment>
                  <Spacer />
                  <T>
                    Pour les bénéficiaires ayant signé une convention avec
                    plusieurs projets, les demandes de paiement devront être
                    réalisées projet par projet, de façon analytique.
                  </T>
                  <Spacer />
                  <T>
                    Par contre, le paiement de l’acompte par le Département ne
                    se fera qu’en une seule fois pour tous les projets.
                  </T>
                </Fragment>
              }
            />
            <T>
              Afin de d'obtenir le versement de votre subvention, procédez à
              l'ensemble des étapes suivantes :
              <Spacer />
            </T>
          </Fragment>
        }
      >
        <SummaryStep {...props} />
        {payment.achievements.map((a) => (
          <AchievementStep
            payment={payment}
            achievment={a}
            key={a.id}
            onSubmit={onSubmitAchievement}
          />
        ))}
        <PaymentReview payment={payment} requests={requests} />
      </Stepper>
    </ContentLayout>
  );
}
